export const handleErrors = (response) => {
    if (response.status !== 404 && response.status !== 200) {
        return response.json()
            .then(data => {
                throw new Error(data.Message);
            });
    }
    return response;
};

export const catchErrors = (error) => {
    console.log(error);
};