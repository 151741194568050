import React from 'react';
import { withRouter } from 'react-router-dom';


class SaveForLaterLink extends React.Component {


    render() {
        return (
            <a href="/" className='dupacoBlue' onClick={this.handleOnClick}>Save & Continue Later&nbsp;<i className="mdi mdi-chevron-right-circle-outline"></i></a>

        );
    }

    handleOnClick = (e) => {
        e.preventDefault();
        this.props.history.push('/SaveForLater')
    }

};

export default withRouter(SaveForLaterLink);