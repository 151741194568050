import * as Yup from 'yup';

const NameValidation = () => {
    Yup.addMethod(Yup.string, 'nameValidate', function(functionOnFailure) {
        return this.test(
            'nameValidate', 
            functionOnFailure,
            (value) => {
                if (value === "") {
                    return true;
                }
                const letterPattern = /[^A-za-z\-'. ]/g;
                return !letterPattern.test(value);
            }
        );
    })
}

export default NameValidation;