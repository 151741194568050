import React, { Component } from 'react';
import { Row, Col, Form, Input } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import Dropdown from '../../Shared/DropDown';
import NavigationButtons from '../../Shared/NavigationButtons';
import { RelationValidation } from '../../../StateModels/RelationState';
import { LeadText } from '../../Shared/LeadText';
export class Relation extends Component {

    state = {
        firstNameError: '',
        lastNameError: '',
        memberNumberError: '',
        dobError: '',
        relationError: ''
    };

    relations = [
        { value: '1', text: 'Child' },
        { value: '2', text: 'Foster child' },
        { value: '3', text: 'Grandchild' },
        { value: '4', text: 'Grandparent' },
        { value: '5', text: 'In-law' },
        { value: '6', text: 'Parent' },
        { value: '7', text: 'Sibling' },
        { value: '8', text: 'Spouse' },
        { value: '9', text: 'Stepchild' },
        { value: '10', text: 'Stepparent' },
    ];

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <LeadText text="Let's make sure you're eligible for membership" subText="Please tell us a little bit about your family member." ShowSaveForLater={false} />
                
                <Form>
                    <fieldset disabled={this.props.readOnly}>
                        <Row form>
                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='firstName' innerLabelText='First name' errorMessage={this.state.firstNameError} errorMessageId={"eligibilityRelationFirstNameRequiredId"}>
                                    <Input type='text' name='firstName' id='firstName' value={this.props.model.firstName} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                                </FormFieldWrap>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='lastName' innerLabelText='Last name' errorMessage={this.state.lastNameError} errorMessageId={"eligibilityRelationLastNameRequiredId"}>
                                    <Input type='text' name='lastName' id='lastName' value={this.props.model.lastName} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                                </FormFieldWrap>
                            </Col>
                        </Row>
                        <Row form>
                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='relation' innerLabelText='Relation' errorMessage={this.state.relationError} errorMessageId={"eligibilityRelationTypeRequiredId"} >
                                    <Dropdown options={this.relations} type='select' name='relation' id='relation' value={this.props.model.relation} onBlur={this.onBlur.bind(this)}
                                        onChange={this.onChangeHandler} />
                                </FormFieldWrap>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                Relationship not listed? That means your family member falls outside our immediate family definition. Please click <i>Back</i> and see if a different eligibility statement applies to you.
                        </Col>
                        </Row>
                    </fieldset>
                    <br />
                    <NavigationButtons setRouteTransition={this.props.setRouteTransition} onNext={this.onNext.bind(this)} ShowSaveForLater={false} />
                </Form>
            </>
        );
    }

    // This function is handed an event object as it is called because of an event handler (usually onChange)
    // We then pass the name and value of the target being updated to the stateHandler from App.js
    // THIS IS DONE DIFFERENTLY FOR ANY DATE PICKERS
    // Search for the 'dob' date picker on this page to see what I mean
    onChangeHandler = (e) => {
        this.props.stateHandler(e.target.name, e.target.value);
    }


    // Don't worry about this for now.
    // If we want inline validation we can add that later
    onBlur = (e) => {
        //const fieldName = e.target.name;
        // // validate, then update the server, then redirect
        // // catch the validation at the bottom if an error occurs
        // RelativeSingleValidation(this, fieldName)
        //     .validate(e.target.value)
        //     .then(() => {
        //         this.setState(previousState => {
        //             previousState[fieldName + 'Error'] = '';
        //             return previousState;
        //         })
        //     }).catch((validationErrors) => {

        //     });
    }

    // This function is handed the history api push method from the NavigationButtons component,
    // which is used to switch to another page's specific id.
    // THIS FUNCTION IS REQUIRED IF THE PAGE HAS VALIDATION OR WEIRD ROUTING
    onNext = async () => {
        this.resetErrors();

        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        const results = await RelationValidation(this).validate(this.props.model, {
            recursive: true,
            abortEarly: false
        }).then(() => {
            this.props.setRouteTransition("moveLeft");
            return true;
        })
            .catch((validationErrors) => {
                window.scrollTo(0, 0);
                return false;
            });

        if (results) {
            return '/Products';
        }
        return null;
    }

    resetErrors = () => {
        this.setState({
            firstNameError: '',
            lastNameError: '',
            memberNumberError: '',
            dobError: '',
            relationError: ''
        });
    }
}
