import React from 'react';
import { Footer } from '../Shared/Footer';
import { LeadText } from '../Shared/LeadText';
import { Row, Col } from 'reactstrap';

const Complete = () => {
    
    return (
        <div className="transition-group">
            <div className="route-section">

                <>
                    <LeadText subText='Welcome back!' />
                    <Row>
                        <Col xs={12}>
                            <p> It looks like you recently started a Dupaco Membership Application, but didn’t finish it. Let’s fix that. Just verify that it’s really you, and we’ll take you right back to where you left off.</p>
                        </Col>
                        <Col xs={{ size: 12, order: 1 }} sm={{ size: 4, order: 2, offset: 8 }} >
                            <a href="./Resume" className="shine-green-btn btn btn-secondary btn-block">Continue</a>
                        </Col>
                    </Row>
                </>
                <Footer />
            </div>
        </div >
    );
}

export default Complete;