import React from 'react';
import { Card, CardBody, Label, Input } from 'reactstrap';

const CardButton = ({
    handleOnClick = undefined,
    cardCss = "",
    cardBodyCss = "",
    name = '', 
    value = '',
    id = '',
    ...props
}) => {

    
    return (
        <Label id={id} className='d-block'>
            <Card className={cardCss} {...props}>
                <CardBody className={cardBodyCss}>
                    {props.children}
                </CardBody>
            </Card>
            <Input type="radio" className="d-none" id={id} name={name} value={value} onClick={handleOnClick} />
        </Label>
    );
};


export default CardButton;