import React, { useEffect, useState } from 'react';
import { LeadText } from '../../Shared/LeadText';
import NavigationButtons from '../../Shared/NavigationButtons';
import { CreateAccountAgreements } from '../../../utilities/FetchUtilites'
import { Spinner, Row, Col, Button } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap'
import SignerCard from './SignerCard';
import AlertMessage from '../../Shared/Alert';
import queryString from 'query-string'

const AccountAgreements = (props) => {

    const [pageStatus, setPageStatus] = useState('initial');
    const [accountAgreementsError, setAccountAgreementsError] = useState('');

    const mapDocumentCreationResultToMasterState = (documentCreationResult) => {
        props.stateHandler("envelopeID", documentCreationResult.envelopeId);
        var primarySignerCreationResult = documentCreationResult.signerResults.find((x) => x.isPrimary === true);
        props.stateHandler("primarySignerURL", primarySignerCreationResult.signUrl);
        props.stateHandler("primaryDocusignClientID", primarySignerCreationResult.docusignClientId);
        props.stateHandler('primarySigningMethod', primarySignerCreationResult.signerType);
        if (props.model.isJointApplication.isJointApplication === "true") {
            var jointSignerCreationResult = documentCreationResult.signerResults.find((x) => x.isPrimary === false);
            props.stateHandler("jointSignerURL", jointSignerCreationResult.signUrl);
            props.stateHandler("jointDocusignClientID", jointSignerCreationResult.docusignClientId);
            props.stateHandler('jointSigningMethod', jointSignerCreationResult.signerType);
        }
    }

    const createAccountAgreements = async () => {
        clearOldAgreementsInfoFromMaster();
        setPageStatus('awaitingCreation');
        CreateAccountAgreements(props.model)
            .then(mapDocumentCreationResultToMasterState)
            .then(() => setPageStatus("agreementsReadyForSigning"))
            .catch(() => { setPageStatus("badStatusDetected"); clearOldAgreementsInfoFromMaster(); });
    }

    const handleOnNextClick = () => {
        if (pageStatus !== 'previouslyCompleted') {
            setAccountAgreementsError('')
            if (props.accountAgreementModel.primarySignerStatus !== "signing_complete") {
                setAccountAgreementsError('Please sign your Account Agreements');
                return null;
            }

            if (props.model.isJointApplication.isJointApplication === "true" && props.accountAgreementModel.jointSignerStatus !== "signing_complete") {
                setAccountAgreementsError(`To continue, your joint account owner needs to sign their Account Agreement(s), too. 
                    If they aren’t available right now, you can save your application and continue later. 
                    Please note, if you’ve already signed your Account Agreement(s), you’ll need to sign again at that time.`);
                return null;
            }
        }
        props.stateHandler('accountAgreementsCompleted', true)

        //super segs skip funding
        if (props.model.leadSourceId !== '' && props.model.leadSourceId !== '1') { 
            const coeReferralIds = new Set(['114', '121', '110', '116', '105', '117', '115', '100', '118', '119', '113', '109', '108', '111', '112', '107', '101', '102', '103', '104', '106', '139', '140', '142', '148', '149', '150', '151', '4111', '4112']); 
                        // These are the Lookup Id's, which is associated to the Lead Source. It's not the same Id that is used when they first view the application via the unique URL of ?refer=000, these are hardcoded Id's of referrals that should be allowed to skip the eligiblity and funding steps, all others cannot // COE TODO
            if (coeReferralIds.has(props.model.leadSourceId)) {
                return '/complete'
            }
        }
        return "/Fund";
    }

    useEffect(() => {

        if(props.model.leadId === 0){
            return;
        }

        
        if (props.agreementsCompleted === true) {
            setPageStatus('previouslyCompleted');
            return;
        }
        
        const queryStringValues = queryString.parse(props.location.search)

        
        //This if is true if its the user's first time thru. Else the query string would have a clientUserId
        if (queryStringValues.ClientUserId === undefined) {
            createAccountAgreements();
        } else {
            setPageStatus('agreementsReadyForSigning');
            if (props.accountAgreementModel.primaryDocusignClientID === queryStringValues.ClientUserId) {
                props.stateHandler("primarySignerStatus", queryStringValues.event);
            }
            else if (props.accountAgreementModel.jointDocusignClientID === queryStringValues.ClientUserId) {
                props.stateHandler("jointSignerStatus", queryStringValues.event);
            }
            else {
                setPageStatus("badStatusDetected")
            }
        }
    }, []);

    useEffect(() => {
        if (props.accountAgreementModel.primarySignerStatus !== undefined && props.accountAgreementModel.primarySignerStatus !== "signing_complete" && props.accountAgreementModel.primarySignerStatus !== "") {
            setPageStatus("badStatusDetected")
        }
        if (props.accountAgreementModel.jointSignerStatus !== undefined && props.accountAgreementModel.jointSignerStatus !== "signing_complete" && props.accountAgreementModel.jointSignerStatus !== "") {
            setPageStatus("badStatusDetected")
        }
    }, [props.accountAgreementModel.primarySignerStatus, props.accountAgreementModel.jointSignerStatus]);



    const handleCreateNewAgreementsClick = () => {
        createAccountAgreements();
    }

    const clearOldAgreementsInfoFromMaster = () => {
        props.stateHandler("envelopeID", "");
        props.stateHandler("primarySignerURL", "");
        props.stateHandler("primaryDocusignClientID", "");
        props.stateHandler("primarySignerStatus", "");
        props.stateHandler("jointSignerURL", "");
        props.stateHandler("jointDocusignClientID", "");
        props.stateHandler("jointSignerStatus", "");
    }

    return (
        <>
            <LeadText text="Account setup (5 of 5)" subText="Account Agreements" ShowSaveForLater={true} />
            <Row>
                <Col>
                    {pageStatus !== 'previouslyCompleted' &&
                        <p>
                            Now we need you to read and sign your Account Agreement &mdash; the document that explains all the terms and conditions of your new account. Please take a close look and provide your electronic signature in the indicated places.
                    </p>
                    }
                    {pageStatus === 'previouslyCompleted' &&
                        <p>
                            You have already signed your agreements!
                    </p>
                    }
                </Col>
            </Row>

            <FormFieldWrap errorMessage={accountAgreementsError}>
                {pageStatus === 'awaitingCreation' &&
                    <>
                        <Row>
                            <Col className="text-center">
                                <h4>Please don't close this browser tab</h4>
                                <p>We're generating your agreement. It could take a couple minutes.</p>
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col className="text-center">
                                <Spinner size="lg" />
                                <br />
                            </Col>
                        </Row>
                    </>
                }
                {pageStatus === 'agreementsReadyForSigning' &&
                    <>

                        <Row className="mb-3 d-flex">

                            {/* {primary signer card} */}
                            <Col sm={6} className={"d-flex flex-grow text-center"}>
                                <SignerCard recipientName={props.model.primary.firstName + " " + props.model.primary.lastName}
                                    status={props.accountAgreementModel.primarySignerStatus}
                                    signUrl={props.accountAgreementModel.primarySignerURL}
                                    clientId={props.accountAgreementModel.primaryDocusignClientID}
                                    envelopeId={props.accountAgreementModel.envelopeID} 
                                />
                            </Col>
                            {/* {joint signer card} */}
                            {props.model.isJointApplication.isJointApplication === "true" &&
                                <Col sm={6} className={"d-flex flex-grow text-center mt-2 mt-sm-0"}>
                                    <SignerCard recipientName={props.model.jointContact.firstName + " " + props.model.jointContact.lastName}
                                        status={props.accountAgreementModel.jointSignerStatus}
                                        signUrl={props.accountAgreementModel.jointSignerURL}
                                        clientId={props.accountAgreementModel.jointDocusignClientID}
                                        envelopeId={props.accountAgreementModel.envelopeID} 
                                    />
                                </Col>
                            }
                        </Row>

                    </>
                }

                {pageStatus === 'badStatusDetected' &&
                    <>
                        <Row form>
                            <Col xs={12} sm={12}>
                                <AlertMessage alertColor=''
                                    alertHeading='Error'
                                    alertMessage={<p>{"Sorry, we're having trouble processing your Account Agreement. Please click below to generate and sign a new one."}</p>}
                                    showButton={false}
                                />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col sm={4}>
                                <Button className='shine-green-btn btn btn-secondary btn-block' onClick={handleCreateNewAgreementsClick} >Generate New Agreements</Button>
                            </Col>
                        </Row>
                    </>
                }
            </FormFieldWrap>
            <NavigationButtons onNext={handleOnNextClick} setRouteTransition={props.setRouteTransition}
                ShowSaveForLater={true} showCancel={true} showBack={false} />
        </>
    )
}

export default AccountAgreements;