export const isCurrencyOrBlank = (value) => {
    const currencyRegex = /^\d*\.?(?:\d{1,2})?$/;
    if (value === '' || currencyRegex.test(value)) {
        return true;
    }
    return false;
}

export const isMircoDepositAmount = (value) => {
    const currencyRegex = /^0.(?:\d{1,2})?$/;
    if (currencyRegex.test(value)) {
        return true;
    }
    return false;
}

export const isLessThanOrEqualToMaxLength = (value, maxLength) => {
    if (value.length <= maxLength) {
        return true;
    }
    return false
}

export const isBlankOrDigits = (value) => {
    const digitsOnlyRegex = /^[0-9]*$/;
    if (value === '' || digitsOnlyRegex.test(value)) {
        return true;
    }

    return false
}

export const maxLength = (value, maxLength) => {
    
    const trimmedValue = value.trim();
    if(trimmedValue === '' || trimmedValue.length <= maxLength)
    {
        return trimmedValue;
    }

    return trimmedValue.substring(0, maxLength);
}

