const AccountAgreementsState = {
    accountAgreementsCompleted: false,
    envelopeID :"" ,
    envelopeStatus:"" ,
    primarySigningMethod:"" ,
    jointSigningMethod:"" ,
    primarySignerURL :"" ,
    primaryDocusignClientID :"" ,
    primarySignerStatus :"" ,
    jointDocusignClientID :"" ,
    jointSignerStatus :"" ,
    jointSignerURL :"" 
};

export default AccountAgreementsState;