import React, { useState, useReducer } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { SubHeader } from '../Shared/SubHeader';
import { Header } from '../Shared/Header';
import InputMask from 'react-input-mask';
import FormFieldWrap from '../Shared/FormFieldWrap';
import { ForogtAppNumberValidation } from '../../StateModels/ResumeValidation';
import { GetAppNumber } from '../../utilities/FetchUtilites';
import { Footer } from '../Shared/Footer';

const ForgotAppNumber = (props) => {
    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            ssn: '',  
            dob: ''
        }
    );

    const [emailSent, setEmailSent] = useState(null);

    const [userErrors, setUserErrors] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            emailError: '',
            ssnError: '',
            appNumberError: ''
        }
    );

    const handleChange = e => {
        let { name, value, type } = e.target;
        if (type === 'checkbox') {
            value = e.target.checked;
        }

        setUserInput({ [name]: value });
    };

    const handleClick = e => {
        setUserErrors({
            ssnError: '',
            dobError: ''
        });

        ForogtAppNumberValidation(setUserErrors).validate(userInput)
            .then(() => {
                return GetAppNumber(userInput);
            })
            .then(data => {
                setEmailSent(true);
            })
            .catch((err) => {
                setEmailSent(false);
            });
    };

    return (
        <div>
            <SubHeader>Let's finish opening your account(s)</SubHeader>
            <Header>Lost or forgot your application ID number?</Header>
            {emailSent === false &&
                (
                    <Row>
                        <Col>
                            <strong>Hmmm...something isn’t quite right.</strong>
                            <p>The information you entered doesn’t match our files. Please double-check and try entering again. If the problem persists, give us a call &mdash; we’re always happy to help!</p>

                        </Col>
                    </Row>
                )
            }
            {emailSent ?
                <Row>
                    <Col>
                        <strong>Your application ID number is on its way.</strong>
                        <p>Please check your inbox. We've sent your application ID number and a new link to your in-progress application. Follow the link and enter your information, and we'll take you right back to where you left off.</p>

                    </Col>
                </Row>
                :
                <Row>
                    <Col>
                        <p>No sweat. Please verify your identity and we'll get you back on track.</p>


                        <Row form>
                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='ssn' innerLabelText='Social Security number' errorMessage={userErrors.ssnError}>
                                    <InputMask mask="999-99-9999" maskChar=' ' value={userInput.ssn} onChange={handleChange} name='ssn' id='ssn'>
                                        {(inputProps) => <Input type='text' {...inputProps} />}
                                    </InputMask>

                                </FormFieldWrap>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='dob' innerLabelText='Date of birth' errorMessage={userErrors.dobError}>
                                    {
                                        <InputMask mask="99/99/9999" maskChar='_'
                                            value={userInput.dob}
                                            onChange={handleChange}
                                            name='dob'
                                            id='dob'
                                        >
                                            {(inputProps) => <Input type='tel' {...inputProps} />}
                                        </InputMask>
                                    }
                                </FormFieldWrap>
                            </Col>
                        </Row>
                        <br />
                        <Row className="mb-3">
                            <Col xs={{ size: 12 }} sm={{ size: 4, offset: 8 }}>
                                <Button type='button' block onClick={handleClick} className="shine-green-btn">Continue</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            }


            <Footer />
        </div>
    );
};

export default ForgotAppNumber;