import React from 'react';
import { Input } from 'reactstrap';

const Dropdown = ({
    id,
    value,
    handleOnChange = undefined,
    handleOnBlur = undefined,
    options = [],
    defaultText = '-- Select --',
    ...props
}) => {
    const defaultValue = (
        <option value="" key="">{defaultText}</option>
    );

    return (
        <Input type="select" id={id} value={value} onChange={handleOnChange} onBlur={handleOnBlur} {...props}>
            {defaultValue}
            {options.map((option, index) => {
                return <option key={option.value} value={option.value}>{option.text ? option.text : option.value}</option>;
            })}
        </Input>

    );
}

export default Dropdown; 