import { object, array, string } from 'yup';
import moment from 'moment';
import NoQuotes from '../utilities/NoQuotes';

const BeneficiariesState = {
    beneficiaries: []
};

export const getEmptyBeneficiariesState = () =>{
    return {beneficiaries: []};
}

export const BeneficiariesValidation = (context) => {
    NoQuotes();

    const mainSchema = object().shape({
        beneficiaries:
            array()
                .of(
                    object().shape({
                        firstName: string()
                                    .noQuotes((err) => {
                                        const index = parseInt(err.path.split("[")[1].split("]")[0], 10);
                                        context.setState(prevState => {
                                            prevState.errors[index].firstNameError = 'No quotes allowed';
                                            return prevState;
                                        });
                                    })
                                    .required((err) => {
                                        const index = parseInt(err.path.split("[")[1].split("]")[0], 10);
                                        context.setState(prevState => {
                                            prevState.errors[index].firstNameError = 'Required';
                                            return prevState;
                                        });
                                    }),
                        lastName: string()
                                    .noQuotes((err) => {
                                        const index = parseInt(err.path.split("[")[1].split("]")[0], 10);
                                        context.setState(prevState => {
                                            prevState.errors[index].lastNameError = 'No quotes allowed';
                                            return prevState;
                                        });
                                    })
                                    .required((err) => {
                                        const index = parseInt(err.path.split("[")[1].split("]")[0], 10);
                                        context.setState(prevState => {
                                            prevState.errors[index].lastNameError = 'Required';
                                            return prevState;
                                        });
                                    }),
                        dob: string()
                                .test('futureDate', (err) => {
                                    const index = parseInt(err.path.split("[")[1].split("]")[0], 10);
                                    context.setState(prevState => {
                                        prevState.errors[index].dobError = 'Cannot be a future date';
                                        return prevState;
                                    })
                                },
                                    function cantBeFutureDate(value) {
                                        let birthDate = new moment(value, 'MM/DD/YYYY', true);
                                        let currentDate = moment().startOf('day');
                                        return (currentDate >= birthDate);
                                    })
                                .test('dob', (err) => {
                                    const index = parseInt(err.path.split("[")[1].split("]")[0], 10);
                                    context.setState(prevState => {
                                        prevState.errors[index].dobError = 'Must enter a valid date';
                                        return prevState;
                                    })
                                },
                                    function dateValidation(value) {
                                        let momentDate = new moment(value, 'MM/DD/YYYY', true);
                                        let isValidDate = momentDate.isValid();
                                        return isValidDate
                                    })
                                .ensure()
                                .required((err) => {
                                    const index = parseInt(err.path.split("[")[1].split("]")[0], 10);
                                    context.setState(prevState => {
                                        prevState.errors[index].dobError = 'Required';
                                        return prevState;
                                    });
                                })
                    })
                )
    });
    return mainSchema;
};

export default BeneficiariesState;