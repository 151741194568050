import React, { useState } from 'react';
import { LeadText } from '../../Shared/LeadText';
import NavigationButtons from '../../Shared/NavigationButtons';
import { Row, Col, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import { CreateACHTransferRequest, PlaidBalanceCheck } from '../../../utilities/FetchUtilites';
import { withRouter } from 'react-router-dom';


const Amounts = ({ setRouteTransition, selectedProductsArray, handleProductFundingChange, AfterACHVerification, model, history }) => {
    const [errorArray, setErrorArray] = useState([]);
    const [verificationError, setVerificationError] = useState('');
    let totalFundingAmount = 0;

    const calculateTotalFundingAmount = () => {
        totalFundingAmount = 0;
        for (let i = 0; i < selectedProductsArray.length; i++) {
            const fundingProduct = selectedProductsArray[i];
            if (fundingProduct.productID === "8") {
                continue;
            }
            totalFundingAmount = totalFundingAmount + ((parseFloat(fundingProduct.fundingAmount, 10)) || 0);
        }

        return totalFundingAmount.toFixed(2);
    }

    let cancelButtonAction = null;
    if (model.fundingMethod.method == 'ACH') {
        cancelButtonAction = () => {
            return 'Method';
        };
    }

    const validateForm = async => {
        setErrorArray([]);
        let errorFound = false;
        for (let i = 0; i < selectedProductsArray.length; i++) {

            if (selectedProductsArray[i].fundingAmount === '') {
                errorFound = true;
                setErrorArray((prevErrorArray) => {
                    prevErrorArray[i] = "Required";
                    return [...prevErrorArray]
                })
            } else {
                if (!isNaN(selectedProductsArray[i].fundingAmount)) {
                    if (parseInt(selectedProductsArray[i].fundingAmount, 10) < selectedProductsArray[i].productMinimum) {
                        errorFound = true;
                        setErrorArray((prevErrorArray) => {
                            prevErrorArray[i] = "Sorry, this account requires an opening deposit of $" + selectedProductsArray[i].productMinimum + " or more. Please update your amount.";
                            return [...prevErrorArray]
                        })
                    }
                } else {
                    errorFound = true
                    setErrorArray((prevErrorArray) => {
                        prevErrorArray[i] = "Must be a number";
                        return [...prevErrorArray]
                    })
                }
            }
            if (model.fundingMethod.method === 'CreditCard') {
                if (totalFundingAmount > 650) {
                    errorFound = true
                    setErrorArray((prevErrorArray) => {
                        prevErrorArray[i] = "Amount can't be greater than $650.00";
                        return [...prevErrorArray]
                    })
                }
            }
            if (model.fundingMethod.method === 'ACH') {
                if (totalFundingAmount > 2500) {
                    errorFound = true
                    setErrorArray((prevErrorArray) => {
                        prevErrorArray[i] = "Amount can't be greater than $2,500.00";
                        return [...prevErrorArray]
                    })
                }
            }

        }

        return errorFound
    }


    const handleNextClick = async () => {
        var errorFound = validateForm();
        setVerificationError('');
        if (!errorFound) {
            if (model.fundingMethod.method != 'ACH') {
                return model.fundingMethod.method;
            }
            var result = await PlaidBalanceCheck(model)
                .then((data) => {
                    if (data.plaidBalanceSuccessful) {
                        return true;
                    } else {
                        setVerificationError(data.plaidBalanceResponse);
                        return false;
                    }
                })
                .catch((data) => {
                    setVerificationError(data.plaidBalanceResponse);
                    return false;
                });
            if (result) {
                return await CreateACHTransferRequest(model)
                    .then((data) => {
                        if (data.isSuccessful) {
                            return '/Complete';
                        }
                        return null;
                    })
                    .catch((errors) => {
                        return null;
                    });
            }
        }
        return null;
    }

    return (
        <>
            <LeadText text="Let’s make your opening deposit(s)" subText="How much money would you like to start with?" ShowSaveForLater={false} />
            {selectedProductsArray.map((product, index) => {
                return (
                    <Row key={index}>
                        <Col key={index} xs={6}>
                            <FormFieldWrap innerLabelText={product.productName} helpText={(product.productMinimum === 0) ? 'No minimum to open' : '$' + new Intl.NumberFormat().format(product.productMinimum) + ' minimum to open'} errorMessage={errorArray[index]}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                    <Input type="text" value={product.fundingAmount} onChange={(e) => {

                                        const re = /^\d*\.?(?:\d{1,2})?$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            handleProductFundingChange(index, e.target.value)
                                        }
                                    }} />
                                </InputGroup>
                            </FormFieldWrap>
                        </Col>
                    </Row>)
            })}
            <Row>
                <Col xs={6} >
                    {model.leadSourceId === "4113" && 
                    <>
                    <p className='mb-1'>
                    <strong>Amount due today: </strong>${calculateTotalFundingAmount()}
                    </p>
                    <p className='small'>Please note: We use different funding methods for the Achieve Money Market opening deposit. After your membership application is completed, we will reach out to help set up funding for this account. </p>
                    </>
                    }

                    {model.leadSourceId !== "4113" && 
                    <>
                        <strong>Your opening deposit total:</strong> ${calculateTotalFundingAmount()}
                    </>
                    }
                    
                </Col>
            </Row>
            {(model.fundingMethod.method == 'ACH') &&
                <Row>
                    <Col xs={12} >
                        {/* This code mimics the disclosure text written within the MicroDepositRepository/InsertExternalDisclosureAgreement - TODO - MAKE CHANGES IN BOTH AREAS */}
                        By clicking Finish, I authorize Dupaco to debit my account for ${calculateTotalFundingAmount()} with a single payment on {new Date().toLocaleDateString()} from {model.fundingMethod.accountNumber} at {model.fundingMethod.institutionName} ({model.fundingMethod.routingNumber}). If I choose to revoke consent for this payment, I must contact Dupaco at 800-373-7600. The withdrawal from your external account may occur before or after this date, depending on your other financial institution's processing procedures. If funds are not available in your external account, the credit to your Dupaco account will be reversed and you could incur a fee.
                    </Col>
                </Row>
            }
            <Row>
                <Col xs={12}>
                    {verificationError !== '' &&
                        <FormFieldWrap id='VerificationErrorHolder' innerLabelText=''>
                            <p>
                                <span className='validation_message'>{verificationError}</span>
                            </p>
                        </FormFieldWrap>
                    }
                </Col>
            </Row>
            <br />

            <NavigationButtons onNext={handleNextClick} setRouteTransition={setRouteTransition}
                ShowSaveForLater={false} showCancel={false} showBack={true} onBack={cancelButtonAction} />
        </>
    );
}

export default withRouter(Amounts);