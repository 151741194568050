import React from 'react';
import { Footer } from '../Shared/Footer';
import { LeadText } from '../Shared/LeadText';


const Restricted = ({ model }) => {

    return (


        <div>
            <LeadText subText="Sorry, you can't continue your application right now." />
            <p>Unfortunately, you've exceeded the allowed application attempts. For your security, you won't be able to try again for 72-hours.</p>
            <Footer />
        </div>
    )
}

export default Restricted;
