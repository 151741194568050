import { object, array, string } from 'yup';

const ProductState = {
    products: [{
        productID: '0',
        productName: 'Share Savings',
        productType: 'SAV',
        productClassCode: '100',
        productMinimum: 25,
        bsaQuestion: '4',
        regC: '',
        fundingAmount: 25
    }]
};

export const getEmptyProduct = () => {
    return {
        products: [{
            productID: '0',
            productName: 'Share Savings',
            productType: 'SAV',
            productClassCode: '100',
            productMinimum: 25,
            bsaQuestion: '4',
            regC: '',
            fundingAmount: 25
        }]
    };
}

export const ProductValidation = (context) => array()
    .of(
        object().shape({
            productID: string().required(),
            regC: string().when('productID', {
                is: id => id === '1' || id === '2',
                then: schema => schema.required((err) => {
                    const index = parseInt(err.path.split("[")[1].split("]")[0], 10);
                    context.setState(prevState => {
                        prevState.errors[index].regCError = 'Required';
                        return prevState;
                    });
                })
            }),
            moneyCard: string().when('productID', {
                is: id => id === '1' || id === '2',
                then: schema => schema.required((err) => {
                    const index = parseInt(err.path.split("[")[1].split("]")[0], 10);
                    context.setState(prevState => {
                        prevState.errors[index].moneyCardError = 'Required';
                        return prevState;
                    });
                })
            })
        })
    );

export default ProductState;