import React from 'react';
import {Row, Col} from 'reactstrap';

export const SubHeader = (props) => {
    return (
        <Row>
            <Col>
                <p className="mb-0">{props.children}</p>
            </Col>
        </Row>
    );
};