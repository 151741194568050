import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import NavigationButtons from '../../Shared/NavigationButtons';
import { IsJointApplicationValidation } from '../../../StateModels/IsJointApplicationState';
import { LeadText } from '../../Shared/LeadText';
import RadioButtonList from '../../Shared/RadioButtonList'

export class IsJointApplication extends Component {
    static displayName = "Joint";

    state = {
        isJointApplicationError: ''
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { model } = this.props;
        return (
            <>
                <LeadText text="Let's make sure we have everyone" subText="Would you like to add a joint account owner? " ShowSaveForLater={false} />

                <Row>
                    <Col xs={12} sm={12}>
                        <p>Choose <i>Yes</i> if you'd like to grant someone else &mdash;
     such as a spouse, partner or family member &mdash;
 equal ownership of account funds and management privileges.</p>
                        <FormFieldWrap id='isJointApplication' innerLabelText='' errorMessage={this.state.isJointApplicationError}  >

                            <RadioButtonList id='isJointApplication' listWrapperDivClassName='flex-column flex-sm-row'
                                options={[{
                                    id: "hasJoint",
                                    checked: model.isJointApplication === 'true',
                                    labelText: <>Yes, I want to add a joint account owner. <strong>I confirm they're here to complete their part of the application.</strong></>,
                                    handleOnChange: this.onChangeHandler,
                                    value: 'true'
                                },
                                {
                                    id: "noJoint",
                                    checked: model.isJointApplication === 'false',
                                    labelText: 'No, this is an individual account. If I want or need to add a joint account owner later, I\'ll contact Dupaco.',
                                    handleOnChange: this.onChangeHandler,
                                    value: 'false'
                                }]} />
                        </FormFieldWrap>
                    </Col>
                </Row>
                <NavigationButtons isJointApplication={model.isJointApplication === 'true'} setRouteTransition={this.props.setRouteTransition} onNext={this.onNext.bind(this)} />
            </>
        );
    }

    // This function is handed an event object as it is called because of an event handler (usually onChange)
    // We then pass the name and value of the target being updated to the stateHandler from App.js
    // THIS IS DONE DIFFERENTLY FOR ANY DATE PICKERS
    // Search for the 'dob' date picker on this page to see what I mean
    onChangeHandler = (e) => {
        const nameOfField = e.target.name;
        let valueOfField = e.target.value;
        const typeOfField = e.target.type;
        if (typeOfField === 'checkbox') {
            valueOfField = e.target.checked;
        }
        this.props.stateHandler(nameOfField, valueOfField);
    }

    onNext = async () => {
        this.resetErrors();

        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        const results = await IsJointApplicationValidation(this).validate(this.props.model, {
            recursive: true,
            abortEarly: false
        }).then(() => {

            return true;
        })
            .catch((validationErrors) => {
                window.scrollTo(0, 0);
                return false;
            });

        if (results) {
            if (this.props.isJointApplication === 'false') {
                return '/Setup';
            } else {
                return 'JointContact';
            }
        }
        return null;
    }

    resetErrors = () => {
        this.setState({
            isJointApplicationError: ''
        });
    }

    // Don't worry about this for now.
    // If we want inline validation we can add that later
    onBlur = (e) => {

    }
}