import { object, string, reach } from 'yup';

const RelationState = {
    firstName: '',
    lastName: '',
    relation: ''
};

export const RelationValidation = (context) => {

    return object().shape({
        firstName: string().required(() => context.setState({firstNameError: 'Required' })),
        lastName: string().required(() => context.setState({lastNameError: 'Required' })),
        relation: string().required(() => context.setState({relationError: 'Required' }))
    });
};

export const RelationSingleValidation = (context, nameOfField) => {
    const validationObject = RelationValidation(context);
    return reach(validationObject, nameOfField);
};

export default RelationState;