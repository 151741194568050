import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import InputMask from 'react-input-mask';
import Media from 'react-media';

export class Beneficiary extends Component {

    state = {
        firstNameError: '',
        lastNameError: '',
        dobError: ''
    };

    componentDidMount() {
        // if (!this.props.fromReview) {
        //     window.scrollTo(0, 0);
        // }
    }

    render() {
        const { model, index, onRemoveBeneficiary, onChangeBeneficiary, error } = this.props;
        return (
            <>
                <Row form>
                    <Col xs={12} sm={3}>
                        <FormFieldWrap id='firstName' innerLabelText='First name' errorMessage={error.firstNameError}>
                            <Input type='text' name='firstName' id='firstName' value={model.firstName} onChange={(e) => { onChangeBeneficiary(e, index, '') }} onBlur={this.onBlur.bind(this)} />
                        </FormFieldWrap>
                    </Col>
                    <Col xs={12} sm={1}>
                        <FormFieldWrap id='mi' innerLabelText='MI' isRequired={false}>
                            <Input type='text' name='mi' id='mi' value={model.mi} onChange={(e) => { onChangeBeneficiary(e, index, '') }} onBlur={this.onBlur.bind(this)} />
                        </FormFieldWrap>
                    </Col>
                    <Col xs={12} sm={4}>
                        <FormFieldWrap id='lastName' innerLabelText='Last name' errorMessage={error.lastNameError}>
                            <Input type='text' name='lastName' id='lastName' value={model.lastName} onChange={(e) => { onChangeBeneficiary(e, index, '') }} onBlur={this.onBlur.bind(this)} />
                        </FormFieldWrap>
                    </Col>

                    <Col xs={12} sm={3}>
                        <FormFieldWrap id='dob' innerLabelText='Date of birth' errorMessage={error.dobError}>
                            {
                                <InputMask mask="99/99/9999" maskChar='_'
                                    value={model.dob}
                                    onChange={(e) => { onChangeBeneficiary(e, index, 'dob') }}
                                    onBlur={this.onBlur.bind(this)}
                                    name='dob'
                                    id='dob'
                                >
                                    {(inputProps) => <Input type='tel' {...inputProps} />}
                                </InputMask>
                            }
                        </FormFieldWrap>
                    </Col>
                    <Col xs={12} sm={1}>
                        <Media query={{ maxWidth: 575 }}>
                            {matches =>
                                matches ? (
                                    <div>
                                        <button className='btn btn-link' onClick={() => { onRemoveBeneficiary(index) }}><i className=" mdi mdi-close-circle" style={{ fontSize: '26px', marginLeft: '-15px' }}></i>
                                            <label className='dupacoBlue' style={{ fontSize: '18px', marginLeft: '5px', verticalAlign: '5.25%' }}>Remove beneficiary</label>
                                        </button>
                                    </div>
                                ) :
                                    (
                                        <div>
                                            <label></label>
                                            <button className='btn btn-link' onClick={() => { onRemoveBeneficiary(index) }}><i className=" mdi mdi-close-circle" style={{ fontSize: '26px' }}></i></button>
                                        </div>
                                    )
                            }
                        </Media>
                    </Col>
                </Row>
            </>
        );
    }

    // This function is handed an event object as it is called because of an event handler (usually onChange)
    // We then pass the name and value of the target being updated to the stateHandler from App.js
    // THIS IS DONE DIFFERENTLY FOR ANY DATE PICKERS
    // Search for the 'dob' date picker on this page to see what I mean
    onChangeHandler = (e) => {
        this.props.stateHandler(e.target.name, e.target.value);
    }

    // Don't worry about this for now.
    // If we want inline validation we can add that later
    onBlur = (e) => {
        //const fieldName = e.target.name;
        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        // RelativeSingleValidation(this, fieldName)
        //     .validate(e.target.value)
        //     .then(() => {
        //         this.setState(previousState => {
        //             previousState[fieldName + 'Error'] = '';
        //             return previousState;
        //         })
        //     }).catch((validationErrors) => {

        //     });
    }

    // This function is handed the history api push method from the NavigationButtons component,
    // which is used to switch to another page's specific id.
    // THIS FUNCTION IS REQUIRED IF THE PAGE HAS VALIDATION OR WEIRD ROUTING
    onNext = (push) => {
        this.resetErrors();

        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        // BeneficiaryValidation(this).validate(this.props.model, {
        //     recursive: true,
        //     abortEarly: false
        // }).then(() => {
        //     push();
        // // })
        //     .catch((validationErrors) => {
        //         window.scrollTo(0, 0);
        //     });
    }

    resetErrors = () => {
        this.setState({
            firstNameError: '',
            lastNameError: '',
            dobError: ''
        });
    }
}
