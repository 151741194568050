import React, { Component } from 'react';
import { LeadText } from '../../Shared/LeadText';
import NavigationButtons from '../../Shared/NavigationButtons';
import { Row, Col, Input, Button } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import { CheckPromoCode } from '../../../utilities/FetchUtilites';
import NoLeadIdRedirect from '../../../components/Shared/NoLeadIdRedirect';

export class PromoCode extends Component {

    state = {
        promoCodeError: '',
        promoCodeHelpText: '',
        promoCodeStatus: ''
    };

    componentDidMount() {
        window.scrollTo(0, 0);

    }

    render() {

        return (
            <>
                <NoLeadIdRedirect />
                <>
                    <LeadText text="Account Setup (1 of 4)" subText="Do you have a Giveback code?" ShowSaveForLater={true} />
                    <p>If you recently received a Dupaco account opening Giveback code (via mail, email or from a Dupaco representative),
                    please enter it below. If you don’t have a Giveback code, please click <i>Next</i>.
                </p>
                    <fieldset disabled={this.props.readOnly}>
                        <Row form>
                            <Col xs={12} sm={6}>
                                <FormFieldWrap isRequired={false} innerLabelText='' errorMessage={this.state.promoCodeError} helpText={this.props.model.promoCodeDetails} helpTextClassName='small-green-bold'>
                                    <Input type='text' name='promoCode' value={this.props.model.promoCode} id='promoCode' onChange={(this.onChangeHandler)} />
                                    <span className="subfooter">Note: Limit 1 Giveback code</span><br />
                                </FormFieldWrap>
                            </Col>
                            <Col xs={12} sm={4} md={3}>
                                <FormFieldWrap isRequired={false} innerLabelText='' >
                                    <Button type='button' className='shine-green-btn btn btn-secondary btn-block' name='button' id='button' onClick={this.checkIfPromoCodeIsValid}>Apply</Button>
                                </FormFieldWrap>
                            </Col>
                        </Row>
                    </fieldset>
                    <NavigationButtons onNext={this.onNext}
                        setRouteTransition={this.props.setRouteTransition}
                        ShowSaveForLater={true} showCancel={false} showBack={true}
                    />

                </>
            </>
        );
    }



    // This function is handed an event object as it is called because of an event handler (usually onChange)
    // We then pass the name and value of the target being updated to the stateHandler from App.js
    // THIS IS DONE DIFFERENTLY FOR ANY DATE PICKERS
    // Search for the 'dob' date picker on this page to see what I mean
    onChangeHandler = (e) => {
        this.props.stateHandler(e.target.name, e.target.value);
    }

    checkIfPromoCodeIsValid = () => {

        this.resetErrors();
        if (this.props.model.promoCode === '') {
            return Promise.resolve(true);
        }
        return CheckPromoCode(this.props.masterState).then(IsPromoCodeValidResponse => {
            this.props.setConnectivityError(false);


            if (IsPromoCodeValidResponse.errorMessage) {
                this.props.setConnectivityError(true);
                return Promise.resolve(false);
            }


            this.setState({ promoCodeError: '' })

            this.props.stateHandler('promoCodeDetails', "");
            if (IsPromoCodeValidResponse.codeIsValid) {

                this.props.stateHandler('promoCodeDetails', IsPromoCodeValidResponse.isValidReturnMessage);
            }
            else {
                this.setState({ promoCodeError: IsPromoCodeValidResponse.isValidReturnMessage })
            }
            return Promise.resolve(IsPromoCodeValidResponse.codeIsValid);

        })
            .catch((err) => {
                return Promise.resolve(false);
            });;

    }


    // This function is handed the history api push method from the NavigationButtons component,
    // which is used to switch to another page's specific id.
    // THIS FUNCTION IS REQUIRED IF THE PAGE HAS VALIDATION OR WEIRD ROUTING
    onNext = async () => {
        this.resetErrors();

        if (this.props.model.promoCode.trim() === '') {
            this.props.stateHandler('promoCodeDetails', "");
            return 'Review';
        } else {
            return this.checkIfPromoCodeIsValid().then((promoCodeIsValid) => {
                if (promoCodeIsValid) {
                    return 'Review';
                }
                return null
            }).catch((error) => { return null });
        }
    }

    resetErrors = () => {
        this.props.stateHandler('promoCodeStatus', '');
        this.props.stateHandler('promoCodeDetails', "");
        this.setState({
            promoCodeError: '',
            promoCodeHelpText: '',
            promoCodeStatus: ''

        });
    }

}

export default PromoCode;

