import { LinkPlaidAccount, PlaidError } from "../../../../utilities/FetchUtilites";

// The usePlaidLink hook manages Plaid Link creation
// It does not return a destroy function;
// instead, on unmount it automatically destroys the Link instance
export default {
  onSuccess: (public_token, metadata) => {
    LinkPlaidAccount();
  },
  onExit: (err, metadata) => {
    PlaidError();
  },
  onEvent: (eventName, metadata) => { },
  token: '',
  //required for OAuth; if not using OAuth, set to null or omit:
  //receivedRedirectUri: window.location.href,
};