import React from 'react';
import { Row, Col } from 'reactstrap';

const WizardBar = ({
    steps = [],
}) => {

    var pathname = window.location.pathname.toLowerCase();

    let stepIndex = -1;
    if (pathname.includes("/eligibility") || pathname === '/') {
        stepIndex = 0;
    }
    else if (pathname.includes("/products")) {
        stepIndex = 1;
    }
    else if (pathname.includes("/member")) {
        stepIndex = 2;
    }
    else if (pathname.includes("/setup")) {
        stepIndex = 3;
    }
    else if (pathname.includes("/fund")) {
        stepIndex = 4;
    }
    else if (pathname.includes("/complete")) {
        stepIndex = 5;
    }

    //no progress bar if -1
    if (stepIndex === -1) {
        return null;
    }


    return (
        <Row style={{ background: '#fff' }}>
            <Col>
                <ol className="d-flex justify-content-center align-content-stretch bs-wizard">
                    {steps.map((step, index) => {
                        let status = 'todo';
                        if (stepIndex === index) {
                            status = 'doing';
                        }
                        else if (stepIndex > index) {
                            status = 'done';
                        }
                        if (!step.visible) {
                            return null;
                        }
                        return (
                            <React.Fragment key={index}>
                                <li className={'d-flex flex-column flex-grow-1 align-items-center ' + status}>
                                    <div className='bar'>
                                        <div className='bar-overlay'></div>
                                    </div>
                                    <i className='fa fa-circle'></i>
                                    <div className='bs-wizard-text d-none d-md-block'>{step.label}</div>
                                </li>
                            </React.Fragment>
                        );
                    })}
                </ol>
            </Col>
        </Row>
    );
};

export default WizardBar;