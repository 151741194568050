import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Relation } from './Relation';
import { Choose } from './Choose';
import { Member } from './Member';
import { Work } from './Work';
import { Live } from './Live';
import { NoneApply } from './NoneApply';
import '../../Shared/StepTransition.css';
import { Footer } from '../../Shared/Footer';
import NoLeadIdRedirect from '../../../components/Shared/NoLeadIdRedirect';

export default class Eligibility extends Component {

    dynamicChildFactory = classNames => child =>
        React.cloneElement(child, {
            classNames
        });

    render() {
        const { match, handleState, model } = this.props;
        return (
            <>
                <NoLeadIdRedirect />
                <Switch>
                    <Route exact path={`${match.path}`}>
                        {({ match }) => (
                            <Redirect to={`${match.path}/Choose`} />
                        )}
                    </Route>
                    <Route exact path={`${match.path}/Choose`}>
                        {({ match }) => (
                            <>
                                <Choose model={model.choose} readOnly={model.appState.readonly} path={match.path}
                                    stateHandler={(field, value) => handleState('choose', field, value)} currentRoute={match.path.replace('/', '')} setRouteTransition={this.props.setRouteTransition} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/Member`}>
                        {() => (
                            <>
                                <Member model={model.member} readOnly={model.appState.readonly}
                                    stateHandler={(field, value) => handleState('member', field, value)} completeStep={this.onCompleted} setRouteTransition={this.props.setRouteTransition} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/Live`}>
                        {() => (
                            <>
                                <Live model={model.live} readOnly={model.appState.readonly}
                                    stateHandler={(field, value) => handleState('live', field, value)} completeStep={this.onCompleted} setRouteTransition={this.props.setRouteTransition} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/Work`}>
                        {() => (
                            <>
                                <Work model={model.work} readOnly={model.appState.readonly}
                                    stateHandler={(field, value) => handleState('work', field, value)} completeStep={this.onCompleted} setRouteTransition={this.props.setRouteTransition} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route path={`${match.path}/Relation`}>
                        {() => (
                            <>
                                <Relation model={model.relative} readOnly={model.appState.readonly}
                                    stateHandler={(field, value) => handleState('relative', field, value)} completeStep={this.onCompleted} setRouteTransition={this.props.setRouteTransition} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route path={`${match.path}/NoneApply`}>
                        {() => (
                            <>
                                <NoneApply completeStep={this.onCompleted} setRouteTransition={this.props.setRouteTransition} />
                                <Footer />
                            </>
                        )}
                    </Route>
                </Switch>
            </>
        )
    }

};