import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, ModalBody, Spinner } from 'reactstrap';
import SaveForLaterLink from './SaveForLaterLink';
import { withRouter } from 'react-router-dom';
import { Upsert, ReviewUpsert } from '../../utilities/FetchUtilites';
import MasterStateContext from '../../Context/masterStateContext';

const NavigationButtons = ({
    onNext,
    showBack = true,
    showNext = true,
    showCancel = false,
    ShowSaveForLater = true,
    showDupacoDotCom = false,
    showShineLogin = false,
    showStartApplication = false,
    setRouteTransition,
    location,
    history,
    isJointApplication,
    nextButtonText = 'Next',
    backButtonText = 'Back',
    onBack = null,
    children
}) => {
    const [isLoading, setLoading] = useState(false);
    const { masterState, handleIDChange, handleCurrentPageChange, setConnectivityError, handleMasterState, writeMasterStateToSession } = useContext(MasterStateContext);
    if (showNext && !onNext) {
        throw new Error('onNext must be defined if showNext is true or left blank');
    }

    const [showModal, setModal] = useState(false);

    const onNextButtonClick = async () => {
        setLoading(true);
        const nextPage = await onNext();
        if (nextPage === null) {
            setLoading(false);
            return;
        }
        let currentPage = location.pathname.substring(1).split("/");

        switch (currentPage[1]) {

            case 'Live': setRouteTransition("moveLeft"); break;
            case 'Work': setRouteTransition("moveLeft"); break;
            case 'Relation': setRouteTransition("moveLeft"); break;
            case 'NoneApply': setRouteTransition("moveLeft"); break;
            case 'Member': setRouteTransition("moveLeft"); break;
            case 'JointIdentification': setRouteTransition("moveLeft"); break;
            case 'IsJointApplication': if (isJointApplication) {
                setRouteTransition("moveUp")
            } else {
                setRouteTransition("moveLeft")
            }; break;
            default: setRouteTransition("moveUp"); break;
        }

        if (currentPage[0] === 'Products') {
            setRouteTransition("moveLeft");
        }

        setConnectivityError(false);

        // set new page to show
        if (!nextPage.match(/SaveForLater|Resume|ForgotAppNumber|Cancel/) !== null) {
            let newPage = '/';
            if (!nextPage.includes('/')) {
                newPage += (currentPage[0] + '/' + nextPage);
            }
            else {
                newPage = nextPage;
            }
            handleCurrentPageChange(newPage, location)
        }


        if (nextPage.match(/ExistingMember|ExistingApplication|FormerMemberBadStatus/) !== null) {
            setLoading(false);
            history.push(nextPage);
            return;
        }


        let currentPageName = location.pathname.substring(1);
        if (currentPageName.toLowerCase().includes('review')) {
            ReviewUpsert(masterState).then(stripedMasterStateResponse => {
                handleMasterState(stripedMasterStateResponse.value);
                writeMasterStateToSession();
                history.push(nextPage);

            }).catch(err => {
                handleCurrentPageChange(currentPageName, location)
                setConnectivityError(true);
                setLoading(false);
            });
        } else {
            Upsert(masterState).then(res => {
                handleIDChange(res.leadId, res.jointLeadId, res.id);
                setLoading(false);

                if (nextPage.includes('Complete')) {
                    history.replace(nextPage);
                }
                else {
                    history.push(nextPage);
                }
            }).catch(err => {
                handleCurrentPageChange(currentPageName, location)
                setConnectivityError(true);
                setLoading(false);
            });
        }

    }

    const onBackButtonClick = (() => {
        if (onBack) {            
            let newPage = onBack();
            handleCurrentPageChange(newPage, location);
            history.push(newPage);
            return;
        }

        let currentPage = location.pathname.substring(1).split("/");

        if (currentPage[0] === 'Products') {
            setRouteTransition("moveRight");
        }

        switch (currentPage[1]) {
            case 'Products': setRouteTransition("moveRight"); break;
            case 'PrimaryIntro': setRouteTransition("moveRight"); break;
            case 'PromoCode': setRouteTransition("moveRight"); break;
            default: setRouteTransition("moveDown"); break;
        }

        history.go(-1);
    })

    const toggleCancelModel = (e) => {
        e.preventDefault();
        setModal(!showModal);
    };

    return (
        <>
            <Row className="mb-3 justify-content-between">

                {(showCancel) && (
                    <Col xs={{ size: 12, order: 3 }} sm={{ size: 4, order: 1 }} className="mt-2 mt-sm-0">
                        <Button type='button' id='btnCancel' block onClick={toggleCancelModel} className="shine-orange-outline-btn">Cancel</Button>
                    </Col>
                )}
                {(showBack) && ((masterState.appState.backButtonPage !== '/Resume') || onBack) && (
                    <Col xs={{ size: 12, order: 2 }} sm={{ size: 4, order: 1 }} className="mt-2 mt-sm-0">
                        <Button type='button' id='btnBack' block onClick={() => onBackButtonClick()} className="shine-orange-outline-btn">{backButtonText}</Button>
                    </Col>
                )}
                {showNext && (
                    <Col xs={{ size: 12, order: 1 }} sm={{ size: 4 }}>
                        <Button type='button' id='btnNext' block onClick={isLoading ? undefined : () => onNextButtonClick(history)} className="shine-green-btn">{nextButtonText} {isLoading && <Spinner size="sm" />}</Button>
                    </Col>
                )}

                {showDupacoDotCom && (
                    <Col xs={{ size: 12, order: 1 }} sm={{ size: 4 }}>

                        <button id='UsernameField' name='UsernameField' onClick={() => { window.location.href = "http://www.dupaco.com" }} className="btn btn-block shine-green-btn">Return to dupaco.com</button>
                    </Col>
                )}
                {showShineLogin && (
                    <Col xs={{ size: 12, order: 1 }} sm={{ size: 4 }}>
                        <button id='ShineLogin' name='ShineLogin' onClick={() => { window.location.href = "https://shine.dupaco.com/User" }} className="btn btn-block shine-green-btn">Sign into Shine</button>
                    </Col>
                )}
                {showStartApplication && (
                    <Col xs={{ size: 12, order: 1 }} sm={{ size: 4, order: 2, offset: 4 }}>
                        <button id='StartApplication' name='StartApplication' onClick={() => { window.location.href = "/" }} className="btn btn-block shine-green-btn">Start New Application</button>
                    </Col>
                )}
                {ShowSaveForLater &&
                    <Col xs={{ size: 12, order: 3 }} className='d-block d-sm-none text-center mt-2'>
                        <SaveForLaterLink />
                    </Col>}
                {children &&
                    children
                }
            </Row>
            <Modal isOpen={showModal} toggle={toggleCancelModel}>
                <ModalBody><strong>Are you sure you want to cancel?</strong><p>Your application is almost finished &mdash; and we're really looking forward to welcoming you as our newest member-owner!</p>

                    <Row>
                        <Col xs={{ size: 12, order: 2 }} sm={{ size: 6, order: 1 }} className="mt-2 mt-sm-0">
                            <Link to='/Cancel' id='btnModelCancel' className='shine-orange-outline-btn btn btn-secondary btn-block'>Yes, I want to cancel.</Link>
                        </Col>
                        <Col xs={{ size: 12, order: 1 }} sm={{ size: 6, order: 2 }}>
                            <Link to='' id='btnCloseCancelModal' className='shine-green-btn btn btn-secondary btn-block' onClick={toggleCancelModel}>No, let's keep going.</Link>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
}

export default withRouter(NavigationButtons);