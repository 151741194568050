import React from 'react';
import { Row, Col } from 'reactstrap';
import DupacoFooter from './DupacoFooter';



export const Footer = () => {
return (
        <>
            <Row>
                <Col>
                    <h3 className="dupacoOrangeSubFooter">Have questions? Need assistance?</h3>
                <p className='subfooter'>Contact the Dupaco Member Service team at <a href='mailto:service@dupaco.com'>service@dupaco.com</a> or <a href='tel:800-373-7600'>800-373-7600</a> ext.206.</p>
                </Col>
            </Row>
            <br />
            <DupacoFooter />
        </>
    );
};