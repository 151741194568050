import React from 'react';
import { Row, Col, Card, CardTitle, CardText, Button } from 'reactstrap';
import {GetAccountAgreementSignerURL} from '../../../utilities/FetchUtilites'



const SignerCard = (props) => {

    const getSignerUrl = () =>{
        GetAccountAgreementSignerURL(props.envelopeId, props.clientId)
        .then((data) => {window.location = data.url;} )
    }
    
    
        
    return (
        <>
            <Card body className={"d-flex flex-grow"}>
                <Row>
                    <Col xs={12}>
                        <CardTitle>{props.recipientName}</CardTitle>
                    </Col>
                     <Col xs={12} className="mb-3">
                        <CardText>{props.status !== 'signing_complete' ?'Your agreement is ready to sign!' : 'Completed!'}</CardText>
                    </Col>
                    {props.status !== 'signing_complete' &&
                        <Col xs={12}>
                            <Button color="primary" onClick={getSignerUrl}>Read and sign</Button>
                        </Col>
                    }
                    
                    {props.status === 'signing_complete' &&
                        
                            <Col className="text-center mb-2">
                                <i className="mdi mdi-checkbox-marked md-48" style={{ fontSize: '48px', color: 'green' }}></i>
                            </Col>
                    }
                </Row>
            </Card>
        </>
    )
}



export default SignerCard;



