import React, { Component } from 'react';
import { Form, Row, Col, Button } from 'reactstrap';
import { ProductValidation } from '../../../StateModels/ProductState';
import NavigationButtons from '../../Shared/NavigationButtons';
import { LeadText } from '../../Shared/LeadText';
import Product from './Product';

export class ProductList extends Component {
    static displayName = "Products";

    state = { errors: this.getErrorArray(this.props.selectedProductsArray.length) };

    getErrorArray(numOfProducts) {
        let errorArray = []
        for (let i = 0; i < numOfProducts; i++) {
            errorArray.push({
                bsaQuestionError: ''
            })
        }

        return errorArray;
    }



    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <LeadText text="Let's find you the right accounts" subText="Which accounts would you like to open?" ShowSaveForLater={false} />
                <p>Select from our most popular accounts. Open just one or as many as you’d like! </p>
                <Form>
                    {this.props.availableProducts.map((product, index) => (
                        <Product
                            key={product.productID}
                            product={product}
                            selectedProductsArray={this.props.selectedProductsArray}
                            index={index}
                            onProductSelection={this.handleChangeProduct.bind(this, index)}
                            onBsaSelection={this.updateBsa.bind(this, index)}
                            onRegCChange={this.onRegCChange.bind(this, index)}
                            onMoneyCardChange={this.onMoneyCardChange.bind(this, index)}
                            errors={this.state.errors}
                            stateHandler={this.props.stateHandler}
                            model={this.props.model}
                        />
                    ))}

                    {/* <NavigationButtons onNext={this.onNext.bind(this)} setRouteTransition={this.props.setRouteTransition} ShowSaveForLater={false} /> */}



                    {!this.props.fromReview &&
                        <NavigationButtons onNext={this.onNext.bind(this)} setRouteTransition={this.props.setRouteTransition} ShowSaveForLater={false} />
                    }

                    {this.props.fromReview &&
                        <Row>
                            <Col xs={{ size: 12 }} sm={{ size: 4, offset: 8 }}>
                                <Button type='button' block className='shine-green-btn' setRouteTransition={this.props.setRouteTransition} onClick={this.onNext.bind(this)}>Save</Button>
                            </Col>
                        </Row>
                    }
                    <br />
                </Form>
            </>
        );
    }


    onNext = async () => {
        this.resetErrors();
        const results = await ProductValidation(this).validate(this.props.selectedProductsArray, {
            recursive: true,
            abortEarly: false
        })
            .then(() => {
                return true;
            })
            .catch((validationErrors) => {
                window.scrollTo(0, 0);
                return false;
            });

        if (results) {
            if (this.props.fromReview) {
                this.props.toggleReviewModal();
            } else {
                return '/Member';
            }
        }
        return null;
    }

    resetErrors = () => {
        this.setState({
            bsaQuestion: ''
        });
    }

    updateProducts(index, e) {
        const productID = e.target.name;
        const selected = e.target.checked;

        this.props.handleProductSelection(index, productID, selected);
    }

    updateBsa = (indexToChange, e) => {
        let productBeingChanged = this.props.availableProducts[indexToChange];
        // loop through model to find the correct product
        let productToChange = this.props.selectedProductsArray.find(product => product.productID === productBeingChanged.productID);

        productToChange[e.target.name] = e.target.value;

        this.props.stateHandler('products', 'products', this.props.selectedProductsArray);
    }

    onRegCChange = (indexToChange, e) => {
        let productBeingChanged = this.props.availableProducts[indexToChange];
        // loop through model to find the correct product
        let productToChange = this.props.selectedProductsArray.find(product => product.productID === productBeingChanged.productID);
        productToChange['regC'] = e.target.value;
        this.props.stateHandler('products', 'products', this.props.selectedProductsArray);
    }
    onMoneyCardChange = (indexToChange, e) => {
        let productBeingChanged = this.props.availableProducts[indexToChange];
        // loop through model to find the correct product
        let productToChange = this.props.selectedProductsArray.find(product => product.productID === productBeingChanged.productID);
        productToChange['moneyCard'] = e.target.value;
        this.props.stateHandler('products', 'products', this.props.selectedProductsArray);
    }




    handleChangeProduct = (index, e) => {
        const checked = e.target.checked;

        if (checked) {
            this.addProduct(index);
        } else {
            this.removeProduct(index);
        }
    }



    addProduct = (index) => {
        const productToAdd = this.props.availableProducts[index];
        let products = this.props.selectedProductsArray;
        products.push({
            productID: productToAdd.productID,
            productName: productToAdd.productName,
            productType: productToAdd.productType,
            productClassCode: productToAdd.productClassCode,
            productMinimum: productToAdd.productMinimum,
            bsaQuestion: '',
            regC: '',
            fundingAmount: productToAdd.productMinimum
        });

        // add error state to object
        this.setState(prevState => {
            prevState.errors.push({
                bsaQuestionError: ''
            });
            return prevState;
        });
        this.props.stateHandler('products', 'products', products);
    }

    removeProduct = (index) => {
        let products = this.props.selectedProductsArray;
        const productToRemove = this.props.availableProducts[index];

        products = products.filter((product, filterIndex) => {
            if (product.productID === '0') {
                return true;
            }
            return product.productID !== productToRemove.productID;
        });

        // remove error state from object
        this.setState(prevState => {
            prevState.errors = prevState.errors.filter((err, filterIndex) => {
                return index !== filterIndex;
            });
            return prevState;
        })
        this.props.stateHandler('products', 'products', products);
    }
}


