import React, { useState, useEffect } from 'react';
import { LeadText } from '../../Shared/LeadText';
import NavigationButtons from '../../Shared/NavigationButtons';
import { Row, Col, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import { isMircoDepositAmount } from '../../../utilities/StringUtilities';
import { VerifyMicroDepositsRequest, CreateACHTransferRequest } from '../../../utilities/FetchUtilites';
import { VerifyMicroDepositsValidation } from '../../../StateModels/MicroDepositsState';
import moment from 'moment';

const VerifyMicroDeposits = (props) => {

    const [microDepoist1Error, setMicroDepoist1Error] = useState('');
    const [microDepoist2Error, setMicroDepoist2Error] = useState('');
    const [verificationError, setVerificationError] = useState('');
    const [verificationSubTextError, setVerificationSubTextError] = useState('');
    const [pageStatus, setPageStatus] = useState('Idle');
    const [achTotalAmount, setAchTotalAmount] = useState();


    useEffect(() => {
        let totalFundingAmount = 0;
        for (let i = 0; i < props.model.products.products.length; i++) {
            totalFundingAmount = totalFundingAmount + ((parseFloat(props.model.products.products[i].fundingAmount, 10)) || 0);
        }

        setAchTotalAmount(totalFundingAmount.toFixed(2));
    }, [])

    useEffect(() => {
        if (props.model.microDepositInfo.microDeposit1Amount === undefined || props.model.microDepositInfo.microDeposit1Amount === '') {
            props.onChangeHandler('microDeposit1Amount', '0.')
            props.onChangeHandler('microDeposit2Amount', '0.')
        }
    }, []);

    const handleNextClick = async () => {

        if (props.model.microDepositInfo.MicroDepositConfirmed) {
            return "ACHAmounts"
        }
        setMicroDepoist1Error('');
        setMicroDepoist2Error('');
        setVerificationError('');

        let formIsValid = await VerifyMicroDepositsValidation(setMicroDepoist1Error, setMicroDepoist2Error).validate(props.model.microDepositInfo, {
            recursive: true,
            abortEarly: false
        }).then(() => { return true; })
          .catch((validationErrors) => {
                window.scrollTo(0, 0);
                return false;
        });

        if (!formIsValid) {
            return null;
        }

        //dont allow multiple requests from next click
        if (pageStatus === "verification in process") {
            return null;
        }

        setPageStatus("verification in process");
        var result = await VerifyMicroDepositsRequest(props.model)
            .then((data) => {
                setPageStatus("Idle");
                if (data.value.microDepositSuccessful) {
                    props.onChangeHandler('MicroDepositConfirmed', true);
                    return true;
                } else {
                    setVerificationError(data.value.microDepositResponse);
                    setVerificationSubTextError(data.value.microDepositResponseSubText);
                    return false;
                }
            })
            .catch((data) => {
                setPageStatus("Idle");
                return false
            });
        if (result) {
            return await createACHTransferRequest();
        }
        return null;
    }

    const createACHTransferRequest = async () => {
        return await CreateACHTransferRequest(props.model)
            .then((data) => {
                if (data.isSuccessful) {
                    return '/complete'
                }
                setVerificationError('Hmmm...something isn�t quite right.');
                setVerificationSubTextError('Please double-check your deposit amounts and enter them again.');
                return null
            })
            .catch((errors) => {
                setVerificationError('Hmmm...something isn�t quite right.');
                setVerificationSubTextError('Please double-check your deposit amounts and enter them again.');
                return null;
            })
    }


    return (
        <>
            <LeadText text="Let's confirm your deposits" subText="Fund Account " ShowSaveForLater={true} />

            <p>We recently made two small deposits into your account. Now we need to confirm that you received them. </p>
            <p><strong>Please enter both deposit amounts: </strong></p>

            <fieldset disabled={props.model.microDepositInfo.MicroDepositConfirmed}>
                <Row>

                    <Col xs={12} sm={6}>
                        <FormFieldWrap id='microDeposit1Amount' innerLabelText='Deposit 1' errorMessage={microDepoist1Error}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                <Input type='text'
                                    id='microDeposit1Amount'
                                    value={props.model.microDepositInfo.microDeposit1Amount}
                                    onChange={(e) => { if (isMircoDepositAmount(e.target.value)) { props.onChangeHandler(e.target.id, e.target.value) } }}
                                />
                            </InputGroup>
                        </FormFieldWrap>
                    </Col>
                    <Col xs={12} sm={6}>
                        <FormFieldWrap id='microDeposit2Amount' innerLabelText='Deposit 2' errorMessage={microDepoist2Error}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                <Input type='text'
                                    id='microDeposit2Amount'
                                    value={props.model.microDepositInfo.microDeposit2Amount}
                                    onChange={(e) => { if (isMircoDepositAmount(e.target.value)) { props.onChangeHandler(e.target.id, e.target.value) } }}
                                />
                            </InputGroup>
                        </FormFieldWrap>


                    </Col>
                    <Col xs={12}>
                        {verificationError !== '' &&
                            <FormFieldWrap id='VerificationErrorHolder' innerLabelText=''>
                                <p>
                                    <span className='validation_message'>{verificationError}</span>
                                    <br />
                                    <span style={{ 'fontSize': '0.813em' }} >{verificationSubTextError}</span>
                                </p>
                            </FormFieldWrap>
                        }
                    </Col>
                    <Col  className={'my-2'} xs={12}>
                         <p><strong>By clicking next I authorize Dupaco to debit my account for 
                             ${achTotalAmount} with a single payment on {moment().format("L")} from {props.model.microDepositInfo.achExternalAccountNumber} at {props.model.microDepositInfo.achExternalAccountFinancialInstitutionName} ({props.model.microDepositInfo.achExternalRoutingNumber}). 
                             If I choose to revoke this payment I must contact Dupaco at 800-373-7600.</strong></p>
                    </Col>
                </Row>
            </fieldset>
            <NavigationButtons onNext={handleNextClick} ShowSaveForLater={true} showCancel={true} showBack={false} setRouteTransition={props.setRouteTransition} />
        </>
    );
}

export default VerifyMicroDeposits;