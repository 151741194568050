import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { LeadText } from '../../Shared/LeadText';
import { CheckIDV, CreateMember } from '../../../utilities/FetchUtilites'
import { Spinner, Row, Col } from 'reactstrap';
import MasterStateContext from '../../../Context/masterStateContext';

const Processing = (props) => {
    const [idvValue, setIdvValue] = useState({
        primaryResult: props.model.appState.primaryResult || null,
        jointResult: props.model.appState.jointResult || null
    });
    const [rimNo, setrimNo] = useState({
        primaryRim: props.model.primary.rimNumber || null,
        jointRim: props.model.jointContact.rimNumber || null
    });
    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');
    const { handleRimUpdate, masterState, handleIDVUpdate } = useContext(MasterStateContext);

    useEffect(() => {
        // on page mount
        if (idvValue.primaryResult !== null) {
            //send them back to where they cam from they came from 
            try {
                props.history.goForward();
              }//if above fails send to resume. 
              catch(err) {
                props.history.push("/Resume");
              }
            

        }

        // run idv 
        window.TrustevV2.Init(props.model.idvKey, (sessionId) => {
            CheckIDV(props.model, sessionId)
                .then(res => {

                    handleIDVUpdate(res.primaryCase, res.primaryResult, res.primaryComments,
                        res.jointCase, res.jointResult, res.jointComments);
                })
                .catch(err => {
                    handleIDVUpdate(
                        '',
                        3,
                        [err.message],
                        '',
                        masterState.isJointApplication.isJointApplication === 'true' ? 3 : null,
                        masterState.isJointApplication.isJointApplication === 'true' ? [err.message] : null
                    );
                    // props.setRouteTransition("moveLeft");
                    // setRedirectUrl('/Pending')
                    // setTimeout(() => setRedirect(true), 5000);
                })
                .finally(() => {
                    setTimeout(() => {
                        const results = {
                            primaryResult: props.model.appState.primaryResult,
                            jointResult: props.model.appState.jointResult
                        };
                        setIdvValue(results);
                    }, 1200);
                    props.setRouteTransition("moveLeft");
                    setRedirectUrl('/Pending')
                    setTimeout(() => setRedirect(true), 5000);
                });
        });
    }, []);

    useEffect(() => {
        if (idvValue.primaryResult === null) {
            return;
        }
        if (rimNo.primaryRim !== null) {
            props.setRouteTransition("moveLeft");
            setRedirectUrl('AccountAgreements')
            setTimeout(() => setRedirect(true), 5000);
            return;
        }

        // if idv is low score redirect to pending
        if (idvValue.primaryResult === 3 || (idvValue.jointResult !== null && idvValue.jointResult === 3)) {
            props.setRouteTransition("moveLeft");
            setRedirectUrl('/Pending');
            setTimeout(() => setRedirect(true), 1500);
            return;
        }

        // create accounts
        // CreateMember(props.model)
        //     .then(res => {
        //         // check for accounts
        //         // if any redirect to pending page
        //         // else go to disclosures
        //         handleRimUpdate(res.primaryRim, res.jointRim, res.accounts, res.errors);
        //         if (res.errors.length > 0) {
        //             props.setRouteTransition("moveLeft");
        //             setRedirectUrl('/Pending')
        //         }
        //         else {
        //             setRedirectUrl('AccountAgreements')
        //             setrimNo({ primaryRim: res.primaryRim, jointRim: res.jointRim || null });
        //         }
        //         setTimeout(() => setRedirect(true), 5000);

        //     })
        //     .catch(err => {
        //         props.setRouteTransition("moveLeft");
        //         setRedirectUrl('/Pending')
        //         setTimeout(() => setRedirect(true), 1500);
        //     });
    }, [idvValue]);

    if (redirect) {
        return <Redirect to={redirectUrl} push={false} />
    }

    return (
        <>
            <LeadText text="Account setup (5 of 5)" subText="Identity Verification" ShowSaveForLater={false} />
            <p>Please sit tight. We need to run a quick check on your personal information. This process helps us protect you from identity theft. It'll only take a few seconds.
                </p>
            <Row>
                <Col>
                    {idvValue.primaryResult === null ?
                        // run idv
                        <Row>
                            <Col>
                                <div className='d-flex justify-content-between'>
                                    <p>Verifying your Identity...</p>
                                    <Spinner size="sm" style={{ marginRight: '5px' }} />
                                </div>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col>
                                <div className='d-flex justify-content-between'>
                                    <p>Identity Verification Finished</p>
                                    <i className='mdi h4 mdi mdi-check-circle-outline dugreen'></i>
                                </div>
                            </Col>
                        </Row>
                    }
                    {/* {(idvValue.primaryResult !== null && (idvValue.primaryResult < 3 || (idvValue.jointResult !== null && idvValue.jointResult < 3))) && rimNo.primaryRim === null && (
                        <Row>
                            <Col>
                                <div className='d-flex justify-content-between'>
                                    <p>Accounts being Created</p>
                                    <Spinner size="sm" style={{ marginRight: '5px' }} />
                                </div>
                            </Col>
                        </Row>
                    )}
                    {idvValue.primaryResult !== null && (idvValue.primaryResult < 3 || (idvValue.jointResult !== null && idvValue.jointResult < 3)) && rimNo.primaryRim !== null && (
                        <Row>
                            <Col>
                                <div className='d-flex justify-content-between'>
                                    <p>Accounts Created!</p>
                                    <i className='mdi h4 mdi mdi-check-circle-outline dugreen'></i>
                                </div>
                            </Col>
                        </Row>
                    )} */}
                </Col>
            </Row>
        </>
    )
}

export default Processing;