import React, { Component } from 'react';
import { Row, Col, Form, Input, Button } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import NavigationButtons from '../../Shared/NavigationButtons';
import { PrimaryEmployementValidation } from '../../../StateModels/PrimaryEmploymentState';
import { LeadText } from '../../Shared/LeadText';
import RadioButtonList from '../../Shared/RadioButtonList'
import occupations from '../../../utilities/Occupations';
import Dropdown from '../../Shared/DropDown';
import InputMask from 'react-input-mask';

export class PrimaryEmployementIncome extends Component {

    state = {
        employmentStatusError: '',
        occupationError: '',
        employerNameError: '',
        employerZipcodeError: '',
        militaryBranchError: '',
        militaryLocationError: '',
        referredByEmployerError: ''
    };

    componentDidMount() {
        if (!this.props.fromReview) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        const { model } = this.props;
        return (
            <>
                <LeadText text={!this.props.fromReview ? "About you (3 of 5)" : ""} subText="Employment" ShowSaveForLater={!this.props.fromReview ? true : false} />
                <Form>
                    <fieldset disabled={this.props.readOnly}>
                        <Row form>

                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='employmentStatus' innerLabelText='Employment status' errorMessage={this.state.employmentStatusError} >
                                    <Input type='select' name='employmentStatus' id='employmentStatus' onChange={this.onChangeHandler} value={model.employmentStatus} >
                                        <option value=''>-- Select --</option>
                                        <option value='1'>Employed</option>
                                        <option value='2'>Active Military</option>
                                        <option value='3'>Retired</option>
                                        <option value='4'>Self-Employed</option>
                                        <option value='5'>Unemployed</option>
                                    </Input>
                                </FormFieldWrap>
                            </Col>
                            {(model.employmentStatus === '1' || model.employmentStatus === '3' || model.employmentStatus === '4' || model.employmentStatus === '5') &&
                                <Col xs={12} sm={6}>
                                    <FormFieldWrap id='occupation' innerLabelText={model.employmentStatus === '3' || model.employmentStatus === '5' ? 'Previous occupation' : 'Occupation'} errorMessage={this.state.occupationError}>
                                        <Dropdown id='occupation' name='occupation' value={model.occupation} handleOnChange={this.onChangeHandler} handleOnBlur={this.onBlur.bind(this)}
                                            options={occupations.sort()} />
                                    </FormFieldWrap>
                                </Col>

                            }

                            {(model.employmentStatus === '1' || model.employmentStatus === '4') &&
                                <>
                                    <Col xs={12} sm={6}>
                                        <FormFieldWrap id='employerName' innerLabelText={model.employmentStatus === '4' ? "Your business' name" : "Employer name"} errorMessage={this.state.employerNameError}>
                                            <Input type='text' maxLength='38' name='employerName' id='employerName' value={model.employerName} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                                        </FormFieldWrap>
                                    </Col>

                                    <Col xs={12} sm={6}>
                                        <FormFieldWrap id='employerZipcode' innerLabelText={model.employmentStatus === '4' ? "Your business' ZIP code" : "Employer's ZIP code"}
                                            errorMessage={this.state.employerZipcodeError}>
                                            <InputMask mask="99999" maskChar=' '
                                                value={model.employerZipcode}
                                                onChange={this.onChangeHandler}
                                                onBlur={this.onBlur.bind(this)}
                                                name='employerZipcode'
                                                id='employerZipcode'
                                            >
                                                {(inputProps) => <Input type='tel' {...inputProps} />}
                                            </InputMask>
                                        </FormFieldWrap>
                                    </Col>
                                </>
                            }

                            {model.employmentStatus === '2' &&
                                <>
                                    <Col xs={12} sm={6}>
                                        <FormFieldWrap id='militaryBranch' innerLabelText='Branch' errorMessage={this.state.militaryBranchError}>
                                            <Input type='select' name='militaryBranch' id='militaryBranch' onChange={this.onChangeHandler} value={model.militaryBranch} >
                                                <option value=''>-- Select --</option>
                                                <option value='1'>Air Force & Air Force Reserve</option>
                                                <option value='2'>Air National Guard</option>
                                                <option value='3'>Army & Army Reserve</option>
                                                <option value='4'>Army National Guard</option>
                                                <option value='5'>Coast Guard & Coast Guard Reserve</option>
                                                <option value='6'>Marine Corps & Marine Corps Reserve</option>
                                                <option value='7'>Navy & Navy Reserve</option>
                                            </Input>
                                        </FormFieldWrap>
                                    </Col>

                                    <Col xs={12} sm={6}>
                                        <FormFieldWrap id='militaryLocation' innerLabelText='Base/Location' errorMessage={this.state.militaryLocationError}>
                                            <Input type='text' name='militaryLocation' id='militaryLocation' value={model.militaryLocation} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                                        </FormFieldWrap>
                                    </Col>
                                </>
                            }
                        </Row>

                        {(model.employmentStatus === '1') &&
                            <>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <FormFieldWrap id='referredByEmployer' innerLabelText='Were you referred to Dupaco by your employer?' errorMessage={this.state.referredByEmployerError}  >
                                            <RadioButtonList id='referredByEmployer'
                                                options={[{
                                                    id: "employerReferred",
                                                    checked: model.referredByEmployer === 'true',
                                                    labelText: 'Yes',
                                                    handleOnChange: this.onChangeHandler,
                                                    value: 'true'
                                                },
                                                {
                                                    id: "notEmployerFeferred",
                                                    checked: model.referredByEmployer === 'false',
                                                    labelText: 'No',
                                                    handleOnChange: this.onChangeHandler,
                                                    value: 'false'
                                                }]} />
                                        </FormFieldWrap>
                                    </Col>
                                </Row>
                            </>
                        }

                    </fieldset>
                    <br />
                    {!this.props.fromReview &&
                        <NavigationButtons onNext={this.onNext.bind(this)} setRouteTransition={this.props.setRouteTransition} />
                    }

                    {this.props.fromReview &&
                        <Row>
                            <Col xs={{ size: 12 }} sm={{ size: 4, offset: 8 }}>
                                <Button type='button' block className='shine-green-btn' setRouteTransition={this.props.setRouteTransition} onClick={this.onNext.bind(this)}>Save</Button>
                            </Col>
                        </Row>
                    }

                </Form>
            </>
        );
    }

    // This function is handed an event object as it is called because of an event handler (usually onChange)
    // We then pass the name and value of the target being updated to the stateHandler from App.js
    // THIS IS DONE DIFFERENTLY FOR ANY DATE PICKERS
    // Search for the 'dob' date picker on this page to see what I mean
    onChangeHandler = (e) => {
        this.props.stateHandler(e.target.name, e.target.value);
    }


    // Don't worry about this for now.
    // If we want inline validation we can add that later
    onBlur = (e) => {
        //const fieldName = e.target.name;
        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        // RelativeSingleValidation(this, fieldName)
        //     .validate(e.target.value)
        //     .then(() => {
        //         this.setState(previousState => {
        //             previousState[fieldName + 'Error'] = '';
        //             return previousState;
        //         })
        //     }).catch((validationErrors) => {

        //     });
    }

    // This function is handed the history api push method from the NavigationButtons component,
    // which is used to switch to another page's specific id.
    // THIS FUNCTION IS REQUIRED IF THE PAGE HAS VALIDATION OR WEIRD ROUTING
    onNext = async () => {
        this.resetErrors();

        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        const results = await PrimaryEmployementValidation(this).validate(this.props.model, {
            recursive: true,
            abortEarly: false
        }).then(() => {
            return true;
        })
            .catch((validationErrors) => {
                window.scrollTo(0, 0);
                return false;
            });
        if (results) {
            if (this.props.fromReview) {
                this.props.toggleReviewModal();
            } else {
                return 'PrimaryIdentification';
            }
        }
        return null;

    }

    resetErrors = () => {
        this.setState({
            employmentStatusError: '',
            occupationError: '',
            employerNameError: '',
            employerZipcdoeError: '',
            militaryBranchError: '',
            militaryLocationError: '',
            referredByEmployerError: ''
        });
    }
}

