import * as Yup from 'yup';

const NoQuotes = () => {
    Yup.addMethod(Yup.string, 'noQuotes', function(functionOnFailure) {
        return this.test(
            'noQuotes', 
            functionOnFailure,
            (value) => {
                if (value === "") {
                    return true;
                }
                const letterPattern = /\"/g;
                return !letterPattern.test(value);
            }
        );
    })
}

export default NoQuotes;