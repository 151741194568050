import RelativeState from './RelationState';
import LiveState from './LiveState';
import ChooseState from './ChooseState';
import WorkState from './WorkState';
import ProductState from './ProductState';
import PrimaryEmploymentState from './PrimaryEmploymentState';
import PersonIdentificationState from '../StateModels/PersonIdentificationState';
import GetSteps from '../utilities/Steps';
import InternalAppState from '../StateModels/InternalAppState';
import IsJointApplicationState from '../StateModels/IsJointApplicationState';
import AddressState from '../StateModels/AddressState';
import PersonInfoState from '../StateModels/PersonInfoState';
import BeneficiariesState from '../StateModels/BeneficiariesState';
import PromoCodeState from '../StateModels/PromoCodeState';
import AccountAgreementsState from'../StateModels/AccountAgreementsState';
import microDepositsState from '../StateModels/MicroDepositsState';

const BuildMasterState = () => {


    let sessionMasterState = JSON.parse(sessionStorage.getItem('newMember'));

    if(sessionMasterState)
    {
        return sessionMasterState;
    }

    return GetEmptyMasterState(); 
    
};

export const GetEmptyMasterState = () => {
    return    {
        id: '',
        history: [],
        steps: [...GetSteps()],
        choose: { ...ChooseState },
        relative: { ...RelativeState },
        live: { ...LiveState },
        work: { ...WorkState },
        products: { ...ProductState },
        primary: { ...PersonInfoState },
        primaryAddress: { ...AddressState },
        primaryEmployment: { ...PrimaryEmploymentState },
        isJointApplication: { ...IsJointApplicationState },
        jointContact: { ...PersonInfoState },
        jointAddress: { ...AddressState },
        appState: { ...InternalAppState },
        primaryIdentification: { ...PersonIdentificationState },
        jointIdentification: { ...PersonIdentificationState },
        beneficiaries: { ...BeneficiariesState },
        promocode: { ...PromoCodeState },
        accountAgreements : {...AccountAgreementsState},
        microDepositInfo : {...microDepositsState},
        fundingMethod: { method: '' },
        fundInBranch: { branch: ''},
        leadSourceId : '1'
    };
}

export default BuildMasterState;

