import React, { Component } from 'react';
import { LeadText } from '../../Shared/LeadText';
import NavigationButtons from '../../Shared/NavigationButtons';
import { Row, Col, Input, Form } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import { FundViaCreditCard } from '../../../utilities/FetchUtilites';
import { CreditCardValidation } from '../../../StateModels/CreditCardState';
import AlertMessage from '../../Shared/Alert';
import InputMask from 'react-input-mask';

export class CreditCard extends Component {

    state = {
        cardNumberError: '',
        cardholderNameError: '',
        cardCVVError: '',
        cardExpirationDateError: '',
        cardZipCodeError: '',
        creditCardProcessingError: ''
    }
    handleNextClick = async () => {

        //clear errors
        this.setState(() => {
            return {
                cardNumberError: '',
                cardholderNameError: '',
                cardCVVError: '',
                cardExpirationDateError: '',
                cardZipCodeError: '',
                creditCardProcessingError: ''
            }
        })

        var form = document.querySelector('#form');

        const requestBody = {
            frontEndModel: { ...this.props.model },
            ...Object.fromEntries(new FormData(form))
        };

        if (this.props.model.appState.ccResponse === '0') {
            return null;
        }

        const validationResult = await CreditCardValidation(this).validate(requestBody, {
            recursive: true,
            abortEarly: false
        }).then(() => {
            return true;
        })
            .catch((validationErrors) => {
                window.scrollTo(0, 0);
                return false;
            });

        if (validationResult === false) {
            return null;
        }

        var result = await FundViaCreditCard(requestBody)
            .then((data) => {
                //data.result === 0 means it worked
                this.props.handleResponse(data.result);
                if (data.result  === '0') {

                    return true;
                } else {
                    this.setState((prevState) => {
                        prevState.creditCardProcessingError = "Please correct and re-try your credit card information.";
                        return prevState;
                    });
                    return false
                }
            })
            .catch((data) => {
                this.setState((prevState) => {
                    prevState.creditCardProcessingError = "Please correct and re-try your credit card information.";
                    return prevState;
                });
                return false
            });

        if (result === true) {
            return "/complete";
        }

        return null;

    }

    render() {
        return (
            <>
                <Form id="form">
                    <LeadText text="Let's make opening deposit(s)" subText="Please provide your card details" ShowSaveForLater={false} />
                    {this.state.creditCardProcessingError &&
                        <Row form>
                            <Col xs={12} sm={12}>
                                <AlertMessage alertColor=''
                                    alertHeading='Error'
                                    alertMessage={<p>{this.state.creditCardProcessingError}</p>}
                                    showButton={false}
                                />
                            </Col>
                        </Row>
                    }
                    <Row form>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap id='ACHExternalAccountAccountOwnerName' helpText={("Must match yours or your joint account owner’s")} innerLabelText='Cardholder Name' errorMessage={this.state.ACHExternalAccountAccountOwnerNameError}>
                                {this.props.model.isJointApplication.isJointApplication === 'true' &&
                                    <>
                                        <br />
                                        <input type="radio" name="cardHolderName" id="cardHolderName" checked="checked" value={`${this.props.model.primary.firstName} ${this.props.model.primary.lastName}`} /><span>{`${this.props.model.primary.firstName} ${this.props.model.primary.lastName}`}</span><br />
                                        <input type="radio" name="cardHolderName" id="cardHolderName" value={`${this.props.model.jointContact.firstName} ${this.props.model.jointContact.lastName}`} /><span>{`${this.props.model.jointContact.firstName} ${this.props.model.jointContact.lastName}`}</span><br />
                                    </>
                                }
                                {this.props.model.isJointApplication.isJointApplication !== 'true' &&
                                    <>
                                        <h4>{`${this.props.model.primary.firstName} ${this.props.model.primary.lastName}`}</h4>
                                        <input type='hidden' name="cardHolderName" id="cardHolderName" value={`${this.props.model.primary.firstName} ${this.props.model.primary.lastName}`} />
                                    </>
                                }
                            </FormFieldWrap>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap id='cardNumber' innerLabelText='Card Number' errorMessage={this.state.cardNumberError}>
                                <Input type='tel' maxLength="16" name='cardNumber' id='cardNumber' />
                            </FormFieldWrap>
                        </Col>
                    </Row>
                    <Row form>
                        <Col xs={12} sm={4}>
                            <FormFieldWrap id='cardExpirationDate' innerLabelText='Expiration Date' errorMessage={this.state.cardExpirationDateError}>
                                <InputMask mask="99/99" name='cardExpirationDate' id='cardExpirationDate' maskChar='_'>
                                    {(inputProps) => <Input type='text' {...inputProps} />}
                                </InputMask>
                            </FormFieldWrap>
                        </Col>
                        <Col xs={6} sm={4}>
                            <FormFieldWrap id='cardZipCode' innerLabelText='Billing Zip Code' errorMessage={this.state.cardZipCodeError}>
                                <Input name='cardZipCode' type='tel' maxLength="5" id='cardZipCode' value={this.props.model.cardZipCode} onChange={this.onChangeHandler} />
                            </FormFieldWrap>
                        </Col>
                        <Col xs={6} sm={4}>
                            <FormFieldWrap id='cardCVV' innerLabelText='Card CVV' errorMessage={this.state.cardCVVError}>
                                <Input name='cardCVV' type='tel' maxLength="4" id='cardCVV' value={this.props.model.cardCVV} onChange={this.onChangeHandler} />
                            </FormFieldWrap>
                        </Col>
                    </Row>
                    <Row form>
                        <Col xs={12} sm={12}>
                            <p><i>If you make your initial deposit using a credit card, you may incur a cash advance fee that Dupaco can’t prevent or refund. Refer to your Credit Card Agreement to learn what fees could be applied.</i></p>
                        </Col>
                    </Row>
                    <Row form>
                        <Col>&nbsp;</Col>
                    </Row>

                    <NavigationButtons onNext={this.handleNextClick} setRouteTransition={this.props.setRouteTransition} ShowSaveForLater={false} showCancel={false} showBack={true} />
                </Form>
            </>
        );
    }
}

export default CreditCard;

