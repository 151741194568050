import { object, string } from 'yup';

const ChooseState = {
    selectedOption:""
};

export const ChooseValidation = (context) => {
    return object().shape({
        selectedOption: string().required(() => context.setState({selectedOptionError: 'Please choose a statement' }))
    });
};

export default ChooseState;