import React from 'react';
import Dropdown from '../Shared/DropDown';
import allStates from '../../utilities/States.json';

export const States = (props) => {
    const values = allStates.items.map((value, index) => {
        return {
            value: value.abbreviation,
            text: value.name
        };
    });
    return (
        <>
            {/* <Dropdown options={values} value={props.value} name={props.name} id={props.id} handleOnChange={props.handleOnChange} handleOnBlur={props.handleOnBlur} /> */}

            {/* {(!props.fromReview || !props.isPrimaryMember) &&
                <Dropdown disabled={false} options={values} value={props.value} name={props.name} id={props.id} handleOnChange={props.handleOnChange} handleOnBlur={props.handleOnBlur} />
            }

            {(props.fromReview && props.isPrimaryMember) &&
                <Dropdown disabled={true} options={values} value={props.value} name={props.name} id={props.id} handleOnChange={props.handleOnChange} handleOnBlur={props.handleOnBlur} />
            } */}
            <Dropdown disabled={props.fromReview && props.isPrimaryMember} options={values} value={props.value} name={props.name} id={props.id} handleOnChange={props.handleOnChange} handleOnBlur={props.handleOnBlur} />

        </>
    );
};
export default States; 
