import React, { Component } from 'react';
import { Row, Col, Form, Input, Button } from 'reactstrap';
import FormFieldWrap from '../Shared/FormFieldWrap';
import NavigationButtons from '../Shared/NavigationButtons';
import { PersonIdentificationValidation } from '../../StateModels/PersonIdentificationState'
import { LeadText } from '../Shared/LeadText';
import States from '../Shared/States';
import InputMask from 'react-input-mask';
// import { IdFileUpload } from './IdFileUpload'

export class PersonIdentification extends Component {

    state = {
        driverLicenseError: '',
        identificationTypeError: '',
        identificationNumberError: '',
        issueDateError: '',
        issueStateError: '',
        expirationDateError: '',
        securityWordError: '',
    };


    render() {
        const { model } = this.props;

        return (
            <>
                {!this.props.fromReview &&
                    <LeadText text={this.props.isPrimaryMember ? "About you (4 of 5)" : "About your joint account owner (3 of 3)"}
                        subText="Identification" ShowSaveForLater={true}
                    />
                }
                {this.props.fromReview &&
                    <LeadText text={""} subText="Identification" ShowSaveForLater={false} />
                }
                <Form>
                    <Row>
                        <Col xs={12}>
                            <p>We need some information from your U.S. driver's license, passport, or state-issued or military ID &mdash; it’ll be used to help us further verify your identity. If you don’t have access to this information right now, you can always save your application and continue it later.
                        </p>
                        </Col>
                    </Row>

                    <fieldset disabled={this.props.readOnly}>
                        <Row form>
                            <Col xs={12} sm={6}>

                                <FormFieldWrap id='identificationType' innerLabelText='Photo ID type' errorMessage={this.state.identificationTypeError}>
                                    <Input type='select' name='identificationType' id='identificationType' onChange={this.onChangeHandler} value={model.identificationType} >
                                        <option value=''>-- Select --</option>
                                        <option value='2'>U.S. Driver's License</option>
                                        <option value='3'>U.S. State-Issued ID</option>
                                        <option value='5'>U.S. Military Photo ID</option>
                                        <option value='4'>Passport with Photo</option>
                                    </Input>
                                </FormFieldWrap>
                            </Col>
                            <Col xs={12} sm={6} className={model.identificationType !== '' ? '' : 'd-none'}>
                                <FormFieldWrap id='identificationNumber' innerLabelText={this.getIdentifcationNumberLabel()} errorMessage={this.state.identificationNumberError} >
                                    <Input type='Text' name='identificationNumber' id='identificationNumber' onChange={this.onChangeHandler} value={model.identificationNumber}></Input>
                                </FormFieldWrap>
                            </Col>
                        </Row>

                        {(model.identificationType === '2' || model.identificationType === '3') &&
                            <Row form>
                                <Col xs={12} sm={6}>
                                    <FormFieldWrap id='issueDate' innerLabelText='Issue Date' errorMessage={this.state.issueDateError}>
                                        {
                                            <InputMask mask="99/99/9999" maskChar='_'
                                                value={model.issueDate}
                                                onChange={this.onChangeHandler}
                                                name='issueDate'
                                                id='issueDate'
                                            >
                                                {(inputProps) => <Input type='tel' {...inputProps} />}
                                            </InputMask>
                                        }
                                    </FormFieldWrap>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <FormFieldWrap id='issueState' innerLabelText='Issue state' errorMessage={this.state.issueStateError}>
                                        <States id='issueState' name='issueState' value={this.props.model.issueState} handleOnChange={this.onChangeHandler}
                                        />
                                    </FormFieldWrap>
                                </Col>
                            </Row>
                        }
                        <Row form className={model.identificationType !== '' ? '' : 'd-none'}>
                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='expirationDate' innerLabelText={'Expiration date'} errorMessage={this.state.expirationDateError} >
                                    {
                                        <InputMask mask="99/99/9999" maskChar='_'
                                            value={model.expirationDate}
                                            onChange={this.onChangeHandler}
                                            name='expirationDate'
                                            id='expirationDate'
                                        >
                                            {(inputProps) => <Input type='tel' {...inputProps} />}
                                        </InputMask>
                                    }
                                </FormFieldWrap>
                            </Col>
                        </Row>
                        {/* <Row form>
                            <Col xs={12}>
                                <strong>Want to upload an image of this ID? (optional)</strong>
                                <p>Providing an image of your ID will make it easier for us to verify your identity. Plus it'll help us quickly detect fraudulent attempts to access your account in the future.  </p>
                                <FormFieldWrap id='identificationCard' innerLabelText={''} isRequired={false}>
                                    <div className="">
                                        <Row form>
                                            <Col xs={12} sm={6} className="mb-3 mb-0-sm">
                                                <IdFileUpload description={"[Front of ID Card]"} isUploaded={model.frontOfIdUploaded} stateHandler={this.props.stateHandler} frontOfId={true} isPrimaryMember={this.props.isPrimaryMember} leadId={this.props.leadId } />
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <IdFileUpload description={"[Back of ID Card]"}  isUploaded={model.backOfIdUploaded} stateHandler={this.props.stateHandler} frontOfId={false} isPrimaryMember={this.props.isPrimaryMember} leadId={this.props.leadId } />
                                            </Col>
                                        </Row>
                                       
                                    </div>
                                </FormFieldWrap>
                            </Col>
                        </Row> */}
                        <Row form>
                            <Col xs={12}>
                                <strong>Create your verbal identification password<span className='validation_message'>&nbsp;*</span></strong>
                                <p>We’ll ask for this verbal password anytime you conduct account-related activities in person or over the phone — it’s an additional way of verifying that it’s really you. Enter a word or phrase that’s easy to remember, but hard for others to guess.</p>
                            </Col>
                        </Row>
                        <Row form>
                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='securityWord' innerLabelText='' errorMessage={this.state.securityWordError} >
                                    <Input type='Text' maxLength="40" name='securityWord' id='securityWord' onChange={this.onChangeHandler} value={model.securityWord}></Input>
                                    <span className="subfooter">Limit 40 characters</span>&nbsp;
                                </FormFieldWrap>
                            </Col>
                        </Row>
                    </fieldset>

                    {!this.props.fromReview &&
                        <NavigationButtons setRouteTransition={this.props.setRouteTransition} onNext={this.onNext.bind(this)} />
                    }

                    {this.props.fromReview &&
                        <Row>
                            <Col xs={{ size: 12 }} sm={{ size: 4, offset: 8 }}>
                                <Button type='button' block className='shine-green-btn' setRouteTransition={this.props.setRouteTransition} onClick={this.onNext.bind(this)}>Save</Button>
                            </Col>
                        </Row>
                    }
                </Form>

            </>
        );
    }


    // This function is handed an event object as it is called because of an event handler (usually onChange)
    // We then pass the name and value of the target being updated to the stateHandler from App.js
    // THIS IS DONE DIFFERENTLY FOR ANY DATE PICKERS
    // Search for the 'dob' date picker on this page to see what I mean
    onChangeHandler = (e) => {
        this.props.stateHandler(e.target.name, e.target.value);
    }

    // This function is handed the history api push method from the NavigationButtons component,
    // which is used to switch to another page's specific id.
    // THIS FUNCTION IS REQUIRED IF THE PAGE HAS VALIDATION OR WEIRD ROUTING
    onNext = async () => {
        this.resetErrors();

        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        const results = await PersonIdentificationValidation(this).validate(this.props.model, {
            recursive: true,
            abortEarly: false
        }).then(() => {
            return true;
        })
            .catch((validationErrors) => {
                window.scrollTo(0, 0);
                return false;
            });

        if (results) {
            if (this.props.fromReview) {
                this.props.toggleReviewModal();
                return;
            } else {
                if (!this.props.isPrimaryMember) {
                    return '/Setup';
                }
                return 'Beneficiaries';
            }
        }
        return null;
    }

    resetErrors = () => {
        this.setState({
            //citizenError: '',
            driverLicenseError: '',
            //countryError: '',
            identificationTypeError: '',
            identificationNumberError: '',
            issueDateError: '',
            issueStateError: '',
            expirationDateError: '',
            securityWordError: ''
        });
    }

    getIdentifcationNumberLabel = () => {
        if (this.props.model.identificationType === '2') {
            return "License number"
        }
        if (this.props.model.identificationType === '3') {
            return "State ID number"
        }
        if (this.props.model.identificationType === '4') {
            return "Passport number"
        }
        if (this.props.model.identificationType === '5') {
            return "Military ID number"
        }
        if (this.props.model.identificationType === '') {
            return "Number"
        }
    }

}
