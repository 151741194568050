import React, { useEffect } from 'react';
import { Upsert, SendCancelEmailAndUpsert } from '../../utilities/FetchUtilites';
import { LeadText } from '../Shared/LeadText';
import { Footer } from '../Shared/Footer';

const Cancel = (props) => {

    let cuffy = props.model.leadId;

    useEffect(() => {
        if (props.model.history.includes('/Setup/Disclosures')) {
            
            Upsert(props.model);
            SendCancelEmailAndUpsert(props.model)
            props.resetState();
        } else {
            props.resetState();
        }
    }, [])
    return (
        <div className="transition-group">
            <div className="route-section">
                <LeadText text="In case you want to continue this later" subText="Your application has been saved!" ShowSaveForLater={false} />
                        <div>
                            <p>We hate to see you go. In case you change your mind, we saved all the information you entered and sent a secure link to your in-progress application to the email address you provided. Here’s what you need to know:</p>
                            <ul className="ml-4">
                                <li><strong>Your Application ID number is {cuffy}</strong><br />
                                If you decide to continue your application, you’ll need this number. So be sure to save it for future reference.
                                </li>
                                <li><strong>Continuing your application</strong><br />
                                Just click the link we sent you, then enter your Application ID number and identity verification information. Doing so will take you back to where you left off.
                                </li>
                                <li><strong>For your security </strong><br />
                                The link to your in-progress application will expire after 10 days. If you choose not to continue or complete it before then, the information you’ve entered will be lost. 
                                </li>
                                </ul>
                            <p>Thanks again for your interest in joining Dupaco — we’re looking forward to welcoming you as our newest member-owner!  </p>
                        </div>
                <Footer />
        </div>
        </div>
    );
                        }
export default Cancel;

