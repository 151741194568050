import React from 'react';
import { LeadText } from '../Shared/LeadText'
import NavigationButtons from '../Shared/NavigationButtons';
import { Row, Col } from 'reactstrap';

const isMaintenanceMode = false;

const GettingStarted = ({ setRouteTransition, model }) => {
    var isCOE = false;
    if (model.leadSourceId !== '' && model.leadSourceId !== '1') { //leadSourceId = '1' is default lead source IE named Website Download in CRMnext
        const coeReferralIds = new Set(['100', '101', '102', '103', '104', '500', '501', '502', '503', '504', '505', '506', '507', '508', '509', '510', '511', '512', '513', '514', '515', '533', '106', '535', '541', '542', '543', '107', '544', '545']); // hardcoded Source ID's (called Codes in CRMNEXT) NOT Lookup IDs of referrals that should be allowed to skip the eligiblity and funding steps, all others cannot // COE TODO
        isCOE = coeReferralIds.has(model.leadSourceId);
    }

    const handleOnNextClick = async () => {
        if (isCOE) return 'Products';
        return 'Eligibility/Choose';
    }

    const liStyle = {
        'marginBottom': '1rem'
    };

    return (
        <>

            {isMaintenanceMode ?
                <>
                    <LeadText text="" subText="Sorry, we're temporarily unavailable." ShowSaveForLater={false} />
                    <Row>
                        <Col>
                            <p>It's not you, it's us. We're doing a little routine maintenance, so our online membership application is temporarily unavailable.</p>

                            <p>We should be up and running again shortly. Can we talk you into stopping back? If you don't want to wait, please give us a call at 800-373-7600, ext. 0. A Dupaco representative will help you fill out an application over the phone.</p>

                            <p>Sorry again for the inconvenience. We hope to hear from you soon!</p>
                        </Col>
                    </Row>
                </>
                :
                <>
                    <LeadText text="Before we begin" subText="Thanks for your interest in joining Dupaco!" ShowSaveForLater={false} />
                    <Row>
                        <Col>
                            <p> Applying for membership online is a two-part process. First, you'll choose the account(s) you want to open and tell us about you.
                                Then we'll do a little work on our end and get back to you within one business day.
                                 We'll give you instructions for finishing your application at that time.&nbsp;
                                 <br/>
                            
                    </p>
                    <h5 className='duorange mt-3'>Here's what you'll need:</h5>
                            <ul style={{ 'paddingInlineStart': '2.5rem' }}>
                                <li style={liStyle}><b>Your personal information</b>
                                    <br />
                            SSN, primary address, phone, email, etc.
                        </li>
                                <li style={liStyle}>
                                    <b>A valid ID</b>
                                    <br />
                            U.S. driver's license, passport, military or state-issued ID
                        </li>
                                {(!isCOE) &&
                                    <li>
                                        <b>A way to fund your account(s)</b>
                                        <br />
                            Credit/debit card, outside account, mailed check or in-person
                        </li>
                                }
                            </ul>
                            <p>
                                Thanks again for your interest in Dupaco. We're looking forward to welcoming you as our newest member-owner!
                    </p>
                        </Col>
                    </Row>
                    <NavigationButtons onNext={handleOnNextClick} setRouteTransition={setRouteTransition}
                        ShowSaveForLater={false} showCancel={false} showBack={false} nextButtonText="Let's get started" />
                    <br />
                    <br />
                    <div style={{ 'border': '1px solid #ccc', 'padding': '0.25em' }}>
                        <p style={{ 'lineHeight': '1em', 'marginBottom': '0px' }}>
                            <small>
                                <i>
                                    The information we collect from you is necessary to process or evaluate your request.
                                    If you wish to know what portion of that information has the potential to be shared with our partners,
                                    and your rights to limit that sharing, please refer to our
                        <a rel="noopener noreferrer" href='https://www.dupaco.com/about/disclosures/privacy-policy/' target="_blank"> Privacy Policy page </a>
                            or contact us at 800-373-7600, Ext. 206.
                        <br />
                                    <br />
                            If you are a California resident, you can call the same phone number or proceed to our
                        <a rel="noopener noreferrer" href='https://www.dupaco.com/about/disclosures/california-consumer-privacy-act/#disclosure-and-sale-of-personal-information' target="_blank"> California Consumer Privacy Act page </a>
                            to learn your State-mandated rights.
                        </i>
                            </small>
                        </p>
                    </div>
                </>
            }
        </>
    );
}

export default GettingStarted;