import React, { Component } from 'react';
import { SaveForLaterUpsert } from '../../utilities/FetchUtilites';
import { withRouter } from 'react-router-dom';
import AlertMessage from '../Shared/Alert';


class TimerStuff extends Component {

    state = {
        showError: false
    }


    reorientWindow = () => {
        window.scrollTo(0, 0)
    }

    clearBanner = () => {
        this.setState({
            showAlertMessage25Mins: false
        });
        this.returnedTimer25Mins = setTimeout(() => {
            this.reorientWindow(); this.setState({
                showAlertMessage25Mins: true,

            });
         }, 1500000);
        this.setRemainderOfTimer();
    }

    setRemainderOfTimer = () => {
        clearTimeout(this.returnedTimer30MinsSaved);
        this.returnedTimer30MinsSaved = setTimeout(() => {
            if (this.props.model.history.includes('/Member/PrimaryAddress')) {
                SaveForLaterUpsert(this.props.model).then((data) => {
                    var appId = data.value.appId;
                    this.setState({
                        showAlertMessage25Mins: false,
                        showAlertMessage30Mins: false
                    })
                    this.props.resetState();
                    this.props.handleState("appState", 'timedOutAppId', appId);
                    this.props.push('/TimedOut');
                }).catch((err) => {
                    console.log(err);
                    this.props.resetState();
                })
            } else {
                this.setState({
                    showAlertMessage25Mins: false,
                    showAlertMessage30Mins: false
                })
                this.props.resetState();
                this.props.push('/TimedOut');
            }

         }, 1800000);
    }


    componentDidMount() {
        this.setState({
            showAlertMessage25Mins: false,
            showAlertMessage30Mins: false
        })
        document.addEventListener('change', (e) => {
            if (e.target.tagName.toLowerCase() === 'input' || e.target.tagName.toLowerCase() === 'select') {
                clearTimeout(this.returnedTimer25Mins);
                this.clearBanner();
            }
        });

        document.addEventListener('click', (e) => {
            if (e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'a') {
                clearTimeout(this.returnedTimer25Mins);
                this.clearBanner();
            }
        });

        if ((window.location.pathname.includes('TimedOut') || window.location.pathname.includes('Resume'))) {
            clearTimeout(this.returnedTimer25Mins);
            clearTimeout(this.returnedTimer30MinsSaved);
            this.setState({
                showAlertMessage25Mins: false,
                showAlertMessage30Mins: false
            })

        }
        else {
            clearTimeout(this.returnedTimer25Mins);
            clearTimeout(this.returnedTimer30MinsSaved);

            this.setState({
                showAlertMessage25Mins: false
            })
            this.returnedTimer25Mins = setTimeout(() => {
                this.reorientWindow(); this.setState({
                    showAlertMessage25Mins: true,

                });
             }, 1500000);

            this.setRemainderOfTimer();
        }

    }

    
    render() {
        return (
            <>
                {/* Flash the 5 minute warning: */}
                {this.state.showAlertMessage25Mins &&
                    <AlertMessage alertColor=''
                        alertHeading='Are you still there?'
                        alertMessage={<p>Due to inactivity{this.props.model.primary.firstName !== '' ? (' ' + this.props.model.primary.firstName) : ''}
                                        , your session will expire in five minutes. Click below to let us know you’re still there!</p>}
                        alertButtonVerbiage='Extend my session'
                        handleOnClick={this.clearBanner} alertHyperlink={"$"}
                    />
                }
            </>
        );
    }
}

export default withRouter(TimerStuff);