import moment from 'moment';
import { object, string } from 'yup';

const PersonIdentificationState = {
    //citizen: '',
    //country: '',
    identificationType: '',
    identificationNumber: '',
    expirationDate: '',
    issueDate: '',
    issueState: '',
    securityWord: '',
    frontImage: '',
    backImage: '',
    frontOfIdUploaded: "notUploaded",
    backOfIdUploaded: "notUploaded"
};

export const PersonIdentificationValidation = (context) => {
    let mainSchema = object().shape({
        //citizen: string().required(() => context.setState({citizenError: 'Required' })),
        identificationType: string().required(() => context.setState({ identificationTypeError: 'Required' })),
        securityWord: string().required(() => context.setState({ securityWordError: 'Required' })),
    });

    let idNumberExpireDateSchema = object().shape({
        identificationNumber: string().required(() => context.setState({ identificationNumberError: 'Required' })),
        expirationDate: string()
            .test("pastDate", () => context.setState({ expirationDateError: 'Expiration date cannot be a past date' }),
                function cantBePastDate(value) {
                    let expirationDate = new moment(value, 'MM/DD/YYYY', true);
                    let currentDate = moment().startOf('day');
                    return (currentDate <= expirationDate);
                })
            .test('expirationdate', () => context.setState({ expirationDateError: 'Must enter a valid date' }),
                function dateValidation(value) {
                    let momentDate = new moment(value, 'MM/DD/YYYY', true);
                    let isValidDate = momentDate.isValid();
                    return isValidDate;
                })
            .test('max10Years', () => context.setState({ expirationDateError: 'Expiration date cannot be older than 10 years from the current date' }),
                function max10YearsValidation(value) {
                    let expirationDate = new moment(value, 'MM/DD/YYYY', true);
                    let tenYearsAgo = moment().subtract(10, 'years').startOf('day');
                    let tenYearsFromNow = moment().add(10, 'years').endOf('day');
                    return expirationDate.isBetween(tenYearsAgo, tenYearsFromNow, null, '[]');
                })
            .required(() => context.setState({ expirationDateError: 'Required' })),
    });

    let driversLicenseStateIDSchema = object().shape({
        issueDate: string()
            .test('after2000IssueDate', () => context.setState({ issueDateError: 'Must enter a valid date after the year 2000' }),
                function dateValidation(value) {
                    let january1st2000 = new moment("01/01/2000", 'MM/DD/YYYY', true);
                    let momentDate = new moment(value, 'MM/DD/YYYY', true);
                    let isValidDate = momentDate.isValid();
                    return isValidDate && momentDate > january1st2000;
                })
            .test("futureDate", () => context.setState({ issueDateError: 'Issue date cannot be a future date' }),
                function cantBeFutureDate(value) {
                    let issueDate = new moment(value, 'MM/DD/YYYY', true);
                    let currentDate = moment().startOf('day');

                    return (currentDate >= issueDate);
                })
            .test('issuedate', () => context.setState({ issueDateError: 'Must enter a valid date' }),
                function dateValidation(value) {
                    let momentDate = new moment(value, 'MM/DD/YYYY', true);
                    let isValidDate = momentDate.isValid();
                    return isValidDate;
                })
            .required(() => context.setState({ issueDateError: 'Required' })),


        issueState: string().required(() => context.setState({ issueStateError: 'Required' })),
    })

    // let countryOfOriginSchema = object().shape({
    //     country : string().required(() => context.setState({countryError: 'Required' })),
    // })

    // if(context.props.model.citizen === '0')
    // {
    //     mainSchema = mainSchema.concat(countryOfOriginSchema);  
    // }

    if (context.props.model.identificationType !== '') {
        mainSchema = mainSchema.concat(idNumberExpireDateSchema);
    }

    if (context.props.model.identificationType === '2' || context.props.model.identificationType === '3') {
        mainSchema = mainSchema.concat(driversLicenseStateIDSchema);
    }

    return mainSchema;
};


export default PersonIdentificationState;
