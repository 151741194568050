import React, {useEffect} from 'react';
import {Row, Col} from 'reactstrap';

export const Header = (props) => {
    useEffect(() => window.scrollTo(0,0), []);
    
    return (
        <Row>
            <Col>
                <h1 className="header1">
                    {props.children}
                </h1>
            </Col>
        </Row>
    );
};