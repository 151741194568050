import { object, string } from 'yup';
import moment from 'moment';

export const CreditCardValidation = (context) => {
    let mainSchema = object().shape({
        cardNumber: string()
                        .min(15, () => context.setState({ cardNumberError: 'Must be at least 15 digits long' }))
                        .required(() => context.setState({ cardNumberError: 'Required' })),
        cardExpirationDate: string()
                                .test(
                                    'checkDate',
                                    () => context.setState({ cardExpirationDateError: 'Invalid date' }),
                                    function checkDate(value) {
                                        var momentCardExpirationDate = moment(value, 'MM/YY', true);
                                        return momentCardExpirationDate.isValid();
                                }).test(
                                    'validDate',
                                    () => context.setState({ cardExpirationDateError: 'Cannot be a past date' }),
                                    function validDate(value) {


                                        var momentCardExpirationDate = moment(value, 'MM/YY', true);
                                        var today = new Date();
                                        var mm = today.getMonth() + 1;
                                        if (mm < 10) {
                                            mm = '0' + mm;
                                        }
                                        var year = today.getFullYear().toString();
                                        var yy = year.substring(2);
                                        var currentDate = mm + '/' + yy;
                                        var momentCurrentDate = moment(currentDate, 'MM/YY', true);
                                        return !momentCardExpirationDate.isBefore(momentCurrentDate);
                                    })
                                .required(() => context.setState({ cardExpirationDateError: 'Required' })),
        cardZipCode: string()
                        .length(5, () => context.setState({ cardZipCodeError: 'Please enter a valid Zip Code' }))
                        .required(() => context.setState({ cardZipCodeError: 'Required' })),
        cardCVV: string()
                    .min(3, () => context.setState({ cardCVVError: 'Must be at least 3 digits long' }))
                    .required(() => context.setState({ cardCVVError: 'Required' }))
    });
    return mainSchema;
}

