import { object, string } from 'yup';

const WorkState = {
    selectedCounty:""
};

export const WorkValidation = (context) => {
    return object().shape({
        selectedCounty: string().required(() => context.setState({selectedCountyError: 'Required' }))
    });
};

export default WorkState;