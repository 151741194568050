import React from 'react';
import { LeadText } from '../../Shared/LeadText';
import NavigationButtons from '../../Shared/NavigationButtons';
import AlertMessage from '../../Shared/Alert';
import { Row, Col, Input } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import RadioButtonList from '../../Shared/RadioButtonList';
import { CreateMicroDepositsValidation } from '../../../StateModels/MicroDepositsState'
import { CreateMicroDepositsRequest } from '../../../utilities/FetchUtilites'
import { isBlankOrDigits, isLessThanOrEqualToMaxLength } from '../../../utilities/StringUtilities'
export class CreateMicroDeposits extends React.Component {

    state = {
        achExternalRoutingNumberError: '',
        achExternalAccountNumberError: '',
        achExternalAccountTypeError: '',
        processing: false,
        created: false,
        achExternalAccountAccountOwnerNameError: '',
        achExternalAccountFinancialInstitutionNameError: '',
        achExternalAccountMatchingError: false,
        achExternalAccountErrorMessage: '',
    }

    componentDidMount() {
        if (this.props.model.microDepositInfo.achExternalAccountStatus === '' && this.props.model.isJointApplication.isJointApplication !== 'true') {
            this.props.onChangeHandler('achExternalAccountAccountOwnerName', `${this.props.model.primary.firstName} ${this.props.model.primary.lastName}`);
        }
    }


    resetErrors = () => {
        this.setState({
            achExternalRoutingNumberError: '',
            achExternalAccountNumberError: '',
            achExternalAccountTypeError: '',
            achExternalAccountAccountOwnerNameError: '',
            achExternalAccountFinancialInstitutionNameError: '',
            achExternalAccountError: false,
            achExternalAccountErrorMessage: '',
        });
    }

    onNext = async () => {
        this.resetErrors();
        //don't allow user to create lots of micro deposit
        if (this.state.processing) {
            return null;
        }
        //set processing to prevent extra ach requests being created
        this.setState({
            processing: true
        });
        //dont make a new record if already verified
        if (this.props.model.microDepositInfo.MicroDepositConfirmed || this.state.created || this.props.model.microDepositInfo.achExternalAccountStatus !== '') {
            return "Buffer";
        }
        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        const results = await CreateMicroDepositsValidation(this).validate(this.props.model.microDepositInfo, {
            recursive: true,
            abortEarly: false
        }).then(() => {
            return CreateMicroDepositsRequest(this.props.model)
                .then((data) => {
                    if (data.value.isSuccessful) {
                        this.props.onChangeHandler('achExternalAccountStatus', data.value.message);
                        this.setState({
                            created: true
                        });
                    } else {

                        this.setState({
                            achExternalAccountError: true,
                            achExternalAccountErrorMessage: data.value.message === 'The routing number entered is invalid. Please contact your financial institution for the correct number.' ? data.value.message : <>It’s not you, it’s us. We’re having trouble with our application system right now, but we’d love for you to come back after we get it fixed. Please try again in about 24 hours or contact Dupaco for assistance.<br></br><br></br>Sorry again for the inconvenience — we hope to hear from you soon!</>
                        });
                    }

                    return data.value.isSuccessful;
                })
                .catch(err => {
                    let errorMessage = '';
                    if (err.message.toUpperCase().includes('ACH Information matches'.toUpperCase())) {
                        errorMessage = 'The account information you have entered is invalid.';
                    }
                    else {
                        errorMessage = <>It’s not you, it’s us. We’re having trouble with our application system right now, but we’d love for you to come back after we get it fixed. Please try again in about 24 hours or contact Dupaco for assistance.<br></br><br></br>Sorry again for the inconvenience — we hope to hear from you soon!</>;
                    }
                    this.setState({
                        achExternalAccountError: true,
                        achExternalAccountErrorMessage: errorMessage
                    });
                    throw err;
                });

        }).catch((validationErrors) => {
            window.scrollTo(0, 0);
            return false;
        });

        this.setState({
            processing: false
        });

        if (results) {
            return "Buffer";
        }


        return null;
    }


    render() {
        return (
            <>
                <LeadText text="Let's make your opening deposit(s)" subText="Please tell us about the account you're transferring money from." ShowSaveForLater={false} />
                {this.state.achExternalAccountError &&
                    <Row form>
                        <Col xs={12} sm={12}>
                            <AlertMessage alertColor=''
                                alertHeading='Error'
                                alertMessage={<p>{this.state.achExternalAccountErrorMessage}</p>}
                                showButton={false}
                            />
                        </Col>
                    </Row>
                }
                <fieldset disabled={this.props.model.microDepositInfo.achExternalAccountStatus !== ''}>
                    <Row>

                        <Col xs={12} sm={6}>

                            <FormFieldWrap id='achExternalAccountAccountOwnerName' innerLabelText='Name as it appears on the account' helpText={("Must match yours or your joint account owner’s")} errorMessage={this.state.achExternalAccountAccountOwnerNameError}>
                                {this.props.model.isJointApplication.isJointApplication === 'true' &&
                                    <RadioButtonList id='achExternalAccountAccountOwnerName'
                                        verticallyDisplayTwoOptionsArray={false}
                                        options={[{
                                            id: "achExternalAccountAccountOwnerNamePrimary",
                                            checked: this.props.model.microDepositInfo.achExternalAccountAccountOwnerName === `${this.props.model.primary.firstName} ${this.props.model.primary.lastName}`,
                                            labelText: `${this.props.model.primary.firstName} ${this.props.model.primary.lastName}`,
                                            handleOnChange: (e) => {
                                                this.props.onChangeHandler('achExternalAccountAccountOwnerName', `${this.props.model.primary.firstName} ${this.props.model.primary.lastName}`);
                                            },
                                            value: `${this.props.model.primary.firstName} ${this.props.model.primary.lastName}`
                                        },
                                        {

                                            id: "achExternalAccountAccountOwnerNameJoint",
                                            checked: this.props.model.microDepositInfo.achExternalAccountAccountOwnerName === `${this.props.model.jointContact.firstName} ${this.props.model.jointContact.lastName}`,
                                            labelText: `${this.props.model.jointContact.firstName} ${this.props.model.jointContact.lastName}`,
                                            handleOnChange: (e) => {
                                                this.props.onChangeHandler('achExternalAccountAccountOwnerName', `${this.props.model.jointContact.firstName} ${this.props.model.jointContact.lastName}`);
                                            },
                                            value: `${this.props.model.jointContact.firstName} ${this.props.model.jointContact.lastName}`
                                        }]} />

                                }
                                {this.props.model.isJointApplication.isJointApplication !== 'true' &&
                                    <h4>{`${this.props.model.primary.firstName} ${this.props.model.primary.lastName}`}</h4>
                                }
                            </FormFieldWrap>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap id='achExternalAccountFinancialInstitutionName' innerLabelText='Financial Institution Name' errorMessage={this.state.achExternalAccountFinancialInstitutionNameError}>
                                <Input type='text'
                                    id='achExternalAccountFinancialInstitutionName'
                                    value={this.props.model.microDepositInfo.achExternalAccountFinancialInstitutionName}
                                    onChange={(e) => this.props.onChangeHandler(e.target.id, e.target.value)} />
                            </FormFieldWrap>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap id='achExternalRoutingNumber' innerLabelText='Routing Number' errorMessage={this.state.achExternalRoutingNumberError}>
                                <Input
                                    id='achExternalRoutingNumber'
                                    value={this.props.model.microDepositInfo.achExternalRoutingNumber}
                                    onChange={(e) => {
                                        if (isLessThanOrEqualToMaxLength(e.target.value, 9) && isBlankOrDigits(e.target.value))
                                            this.props.onChangeHandler(e.target.id, e.target.value)
                                    }}
                                    type='tel' />
                            </FormFieldWrap>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap id='achExternalAccountNumber' innerLabelText='Account Number' errorMessage={this.state.achExternalAccountNumberError}>
                                <Input
                                    id='achExternalAccountNumber'
                                    value={this.props.model.microDepositInfo.achExternalAccountNumber}
                                    onChange={(e) => {
                                        if (isLessThanOrEqualToMaxLength(e.target.value, 14) && isBlankOrDigits(e.target.value))
                                            this.props.onChangeHandler(e.target.id, e.target.value)
                                    }}
                                    type='tel' />
                            </FormFieldWrap>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap id='achExternalAccountType' innerLabelText='Account Type' errorMessage={this.state.achExternalAccountTypeError}>
                                <RadioButtonList id='achExternalAccountTypesList'
                                    options={[{
                                        id: "achExternalAccountTypeSaving",
                                        checked: this.props.model.microDepositInfo.achExternalAccountType === 'Savings',
                                        labelText: 'Savings',
                                        handleOnChange: (e) => {
                                            this.props.onChangeHandler('achExternalAccountType', 'Savings');
                                        },
                                        value: 'Savings'
                                    },
                                    {
                                        id: "achExternalAccountTypeChecking",
                                        checked: this.props.model.microDepositInfo.achExternalAccountType === 'Checking',
                                        labelText: 'Checking',
                                        handleOnChange: (e) => {
                                            this.props.onChangeHandler('achExternalAccountType', 'Checking');
                                        },
                                        value: 'Checking'
                                    }]} />
                            </FormFieldWrap>
                        </Col>

                    </Row>
                </fieldset>
                <NavigationButtons onNext={this.onNext.bind(this)} setRouteTransition={this.props.setRouteTransition}
                    ShowSaveForLater={false} showCancel={false} showBack={true} />
            </>
        );
    }
}

export default CreateMicroDeposits
