import moment from 'moment';
import { object, string, reach, ref } from 'yup';
import NameValidation from '../utilities/NameValidation';
import NoQuotes from '../utilities/NoQuotes';

const PersonInfoState = {
    firstName: '',
    mi: '',
    lastName: '',
    dob: '',
    country: '',
    countryIndex: 0,
    ssn: '',
    primaryEmail: '',
    primaryEmailConfirmation: '',
    primaryPhone: '',
    alternatePhone: '',
    extension: '',
    primaryPhoneIsMobile: '',
    backupWithholding: '',
    citizen: '',
    disableSSN: false,
    suffix: ''
};

export const PersonValidation = (context, otherApplicantEmail, otherApplicantSSN) => {
    //let eighteenYearsAgo = moment().startOf('day').subtract(18, 'years');
    NameValidation();
    NoQuotes();

    let mainSchema = object().shape({
        firstName: string()
            .noQuotes(() => context.setState({ firstNameError: 'No quotes allowed' }))
            .required(() => context.setState({ firstNameError: 'Required' })),
        mi: string()
            .nameValidate(() => context.setState({ miError: 'No special characters allowed' }))
            .max(1, () => context.setState({ miError: 'Max length 1' })),
        lastName: string()
            .noQuotes(() => context.setState({ lastNameError: 'No quotes allowed' }))
            .required(() => context.setState({ lastNameError: 'Required' })),
        suffix: string(),
        dob: string()
            .test("mustBe18", () => context.setState({ dobError: 'You must be at least 18 years old' }),
                function mustBeOldEnough(value) {
                    let momentDate = new moment(value, 'MM/DD/YYYY', true);

                    return momentDate < moment().startOf('day').subtract(18, 'years');
                })
            .test('dob', () => context.setState({ dobError: 'Must enter a valid date' }),
                function dateValidation(value) {
                    let momentDate = new moment(value, 'MM/DD/YYYY', true);
                    let isValidDate = momentDate.isValid();
                    return isValidDate;
                })
            .test("mustlessThan125", () => context.setState({ dobError: 'Must enter a valid date' }),
                function mustBeOldEnough(value) {
                    let momentDate = new moment(value, 'MM/DD/YYYY', true);

                    return momentDate > moment().startOf('day').subtract(124, 'years');
                })
            .required(() => context.setState({ dobError: 'Required' })),
        citizen: string()
            .required(() => context.setState({ citizenError: 'Required' })),
        ssn: string()
            .test('otherApplicantSSNDoesntMatch', () => context.setState({ ssnError: "Oops! Your social matches the other applicants. They cannot be the same." }), function ssnDoesntMatchValidation(value) {
                if (value.toUpperCase() === otherApplicantSSN.toUpperCase()) {
                    return false;
                }
                return true;
            })
            .test('primaryPhone', () => context.setState({ ssnError: "Oops! The SSN you entered is not valid. Please verify and correct." }),
                function phoneValidation(value) {
                    var ssnPattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
                    return ssnPattern.test(value);
                })
            .required(() => context.setState({ ssnError: 'Required' })),
        primaryPhoneIsMobile: string()
            .required(() => context.setState({ primaryPhoneIsMobileError: 'Required' })),

        primaryEmail: string()
            .test('otherApplicantEmailDoesntMatch', () => context.setState({ emailError: "Oops! Your email matches the other applicants. They cannot be the same." }), function emailDoesntMatchValidation(value) {
                if (value.toUpperCase() === otherApplicantEmail.toUpperCase()) {
                    return false;
                }
                return true;
            })
            .email(() => context.setState({ emailError: 'Oops! The email you entered is not valid. Please verify and correct.' }))
            .required(() => context.setState({ emailError: 'Required' })),
        primaryEmailConfirmation: string()
            .oneOf([ref('primaryEmail'), null], () => context.setState({ emailConfirmationError: "Email addresses don't match." }))
            .required(() => context.setState({ emailConfirmationError: "Required" })),
        primaryPhone: string()
            .test('primaryPhone', () => context.setState({ primaryPhoneError: "Oops! The phone number you entered is not valid. Please verify and correct." }),
                function phoneValidation(value) {
                    if(value != undefined && value != null && value.replace(/ /g, '').length != 13){
                        return false
                    }
                    var matched = value.match(/([\s]+)/g); /*Regex matches spaces and the mask has 1 space (999)Space999-9999 if properly filled*/
                    if (matched === null || matched === undefined) {
                        return false
                    }
                    return matched.length === 1
                })
            .required(() => context.setState({ primaryPhoneError: 'Required' }))
    });

    let backupWithholdingPartialSchema = object().shape({
        backupWithholding: string().required(() => context.setState({ backupWithholdingError: 'Required' }))
    })

    if (context.props.isPrimaryMember) {
        mainSchema = mainSchema.concat(backupWithholdingPartialSchema);
    }

    if (context.props.model.citizen === 'false') {
        mainSchema = mainSchema.concat(object().shape({
            country: string()
                .required(() => context.setState({ countryError: 'Required' }))
        }));
    }


    let alternatePhonePartialSchema = object().shape({
        alternatePhone: string()
            .test('alternatePhone', () => context.setState({ alternatePhoneError: "Oops! The phone number you entered is not valid. Please verify and correct." }), function phoneValidationWork(value) {
                var matched = value.match(/([\s]+)/g); /*Regex matches spaces and the mask has 1 space (999)Space999-9999 if properly filled*/
                if (matched === null || matched === undefined) {
                    return false
                }

                return matched.length === 1
            })
    })

    if (context.props.model.alternatePhone !== '') {
        mainSchema = mainSchema.concat(alternatePhonePartialSchema);
    }

    return mainSchema;
};

export const PersonalSingleValidation = (context, nameOfField) => {
    const validationObject = PersonValidation(context);
    return reach(validationObject, nameOfField);
};

export default PersonInfoState;
