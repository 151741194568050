import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { ProductList } from './ProductList';
import { GetProducts } from '../../../utilities/FetchUtilites';
import { Footer } from '../../Shared/Footer';

class Products extends Component {
    state = {
        availableProducts: []
    }



    componentDidMount() {
        window.scrollTo(0, 0);

        const leadSourceId = this.props.model.leadSourceId;

        GetProducts(this.props.model.leadSourceId)
            .then((data) => {
                
                this.setState(preivousState => {
                    preivousState.availableProducts = data.products;
                    if (leadSourceId === '108') {
                        const productToAdd = data.products.find(product => product.productID === '8');
                        let products = this.props.model.products.products;
                        if (this.props.model.products.products.length === 1) {
                            products.push({
                                productID: productToAdd.productID,
                                productName: productToAdd.productName,
                                productType: productToAdd.productType,
                                productClassCode: productToAdd.productClassCode,
                                productMinimum: productToAdd.productMinimum,
                                bsaQuestion: '',
                                regC: '',
                                fundingAmount: productToAdd.productMinimum
                            });
                            
                            this.props.handleState('products', 'products', products);
                        }
                    }
                    return preivousState;
                });
                sessionStorage.setItem('dupacoProducts', JSON.stringify(data));
            });
    }

    dynamicChildFactory = classNames => child =>
        React.cloneElement(child, {
            classNames
        });

    render() {
        const { model, match } = this.props;
        // Even though this is the only step, the Wizard is required for the navigation buttons to work/render
        return (
            <Switch>
                <Route exact path={`${match.path}`}>
                    {() => (
                        <>
                            {this.state.availableProducts.length > 0 && (
                                <>
                                    <ProductList availableProducts={this.state.availableProducts} selectedProductsArray={model.products.products} fromReview={false}
                                        stateHandler={this.props.handleState} setRouteTransition={this.props.setRouteTransition} model={model} />
                                    <Footer />
                                </>
                            )
                            }
                        </>
                    )}
                </Route>
            </Switch>
        );
    }


}

export default withRouter(Products);