import React from 'react';
import { Footer } from '../Shared/Footer';
import { LeadText } from '../Shared/LeadText';
import NavigationButtons from '../Shared/NavigationButtons';

const Complete = ({ model, setRouteTransition }) => {

    return (
        <div className="transition-group">
            <div className="route-section">

                <>
                    <LeadText subText="Sorry, you're not eligible to join Dupaco right now." />
                    <p>
                        According to the information you entered, some restrictions have been placed on your membership eligibility. You won't be able to join until they've been removed.
                        
                        Please visit any Dupaco branch, or give us a call at 800-373-7600, ext. 206. We'll be happy to provide you more details.
                    </p>


                    <strong>Or maybe we're wrong? </strong><br />
                    <p>Well, let's fix that. Please click <i>Back</i> and make sure you correctly entered your name, DOB, and SSN. </p>                                
                            

                    <NavigationButtons ShowSaveForLater={false} showDupacoDotCom={false} showNext={false} showCancel={false} showBack={true} showShineLogin={false} setRouteTransition={setRouteTransition} />
                </>
                <Footer />
            </div>
        </div >
    );
}

export default Complete;