import React from 'react';
import { LeadText } from '../../Shared/LeadText';
import NavigationButtons from '../../Shared/NavigationButtons';
import { Row, Col, } from 'reactstrap';

const MailCheck = ({ leadId, setRouteTransition  }) => {

    const handleNextClick = () => {
        return '/complete'
    }
    return (
        <>
            <LeadText text="Let's make your opening deposit(s)" subText="Here's how you can fund your account with a mailed check. " ShowSaveForLater={false} />

            <Row>
                <Col xs={12} sm={12} className='mb-3'>
                    Please make your check payable to Dupaco Community Credit Union and include application number <strong> {leadId} </strong> in the memo. Then mail it to:
                </Col>
                <Col xs={12} sm={12} className='mb-2'>
                    <address>
                        Dupaco Community Credit Union<br />
                        Online Membership Application<br />
                        P.O. Box 179<br />
                        Dubuque, IA 52004-179
                    </address>
                </Col>
                <Col xs={12}  className='mb-2'>
                    <p><i>Please note: we cannot officially open your account(s) until we receive your opening deposit(s). We look forward to receiving your check soon!</i></p>
                </Col>
            </Row>

            <NavigationButtons onNext={handleNextClick} ShowSaveForLater={false} showCancel={false} showBack={true}  setRouteTransition={setRouteTransition} />
        </>
    );
}

export default MailCheck;