import React from 'react';
import { Label, FormGroup } from 'reactstrap';

const FormFieldWrap = ({
    id,
    labelClassName = '',
    innerLabelText = id,
    helpText = '',
    helpTextClassName = 'form-text text-muted',
    errorMessage = "",
    errorMessageClassName = 'validation_message',
    children,
    isRequired = true,
    isRequiredCssClass = 'validation_message',
    className = '',
    innerLabelTextHyperLink = undefined,
    helpTextHyperLink = undefined,
    helpTextHyperLinkClassName = 'form-text text-muted ',
    errorMessageId = '',
    ...props
}) => {
    return (
        <FormGroup className={(errorMessage !== '' ? 'invalid' : '') + ' ' + className} {...props}>
            {innerLabelText &&
                <Label className={labelClassName} htmlFor={id}>{innerLabelText}
                    {isRequired && <span className={isRequiredCssClass}>&nbsp;*</span>}
                    {innerLabelTextHyperLink}

                </Label>
            }
            {children}
            {helpText && <small className={helpTextClassName}>{helpText}</small>}
            {errorMessage && <span {...(errorMessageId ? {id:errorMessageId} : {})} className={errorMessageClassName}>{errorMessage}</span>}
        </FormGroup>
    );
};

export default FormFieldWrap;



