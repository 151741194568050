import { object, string } from 'yup';

const MicroDepositsState = {
    achExternalRoutingNumber: '',
    achExternalAccountNumber: '',
    achExternalAccountType: '',
    achExternalAccountStatus: '',
    MicroDepositConfirmed: false,
    microDeposit1Amount: '0.',
    microDeposit2Amount: '0.',
    achExternalAccountAccountOwnerName: '',
    achExternalAccountFinancialInstitutionName: '',
    VerificationErrorHolder: ''
};

export const CreateMicroDepositsValidation = (context) => {
    return object().shape({
        achExternalRoutingNumber: string().length(9, () => context.setState({ achExternalRoutingNumberError: 'Must be 9 digits long' })).required(() => context.setState({ achExternalRoutingNumberError: 'Required' })).test("ThisIsTheFunctionNameAndIsNotUsed", () => {
                //this function parameter is only called if the next function parameter return true
                 context.setState({ achExternalRoutingNumberError: 'Cannot be Dupaco routing number' })},
                 function(userEnteredRoutingNumber){
                    return userEnteredRoutingNumber !== "273974581"}
                 ),
    
        achExternalAccountNumber: string().min(4, () => context.setState({ achExternalAccountNumberError: 'Account number must be 4 digits or more' }))
        .test("FunctionToNotAllowAccountNumber1234", () => {
            context.setState({ achExternalAccountNumberError: 'This number is not a valid account number' })},
            function(userEnteredRoutingNumber){
               return userEnteredRoutingNumber.trim() !== "1234"}
            )
        .max(13, () => context.setState({ achExternalAccountNumberError: 'Account number must be less than 14 digits' }))
        .required(() => context.setState({ achExternalAccountNumberError: 'Required' })),
        achExternalAccountType: string().required(() => context.setState({ achExternalAccountTypeError: 'Required' })),
        achExternalAccountAccountOwnerName: string().required(() => context.setState({ achExternalAccountAccountOwnerNameError: 'Required' })),
        achExternalAccountFinancialInstitutionName: string().required(() => context.setState({ achExternalAccountFinancialInstitutionNameError: 'Required' }))
    });
};


export const VerifyMicroDepositsValidation = (setMicroDeposit1Error, setMicroDeposit2Error) => {
    return object().shape({
        microDeposit1Amount: string().test('micro1Pattern', () => setMicroDeposit1Error("Invalid Number"), function regexMicroDepositValidation(value) { var decimalPattern = /^0.(?:\d{1,2})?$/; return decimalPattern.test(value) })
            .required(() => setMicroDeposit1Error("Required"))
            .test('micro1BlankPattern', () => setMicroDeposit1Error("Required"), function (value) { return value.trim() !== '0.' })
,
        microDeposit2Amount: string().test('micro2Pattern', () => setMicroDeposit2Error("Invalid Number"), function regexMicroDepositValidation(value) { var decimalPattern = /^0.(?:\d{1,2})?$/; return decimalPattern.test(value) })
            .required(() => setMicroDeposit2Error("Required"))
            .test('micro1BlankPattern', () => setMicroDeposit2Error("Required"), function (value) { return value.trim() !== '0.' }),

    });
};

export const SetVerificationError = (setVerificationError) => {
    return object().shape({
        VerificationErrorHolder: setVerificationError('Hmmm...something isn’t quite right.'),
    });
};

export default MicroDepositsState;
