import React, { Component } from 'react';
import { Row, Col, Button, Spinner } from 'reactstrap';
import { SaveForLaterUpsert } from '../../utilities/FetchUtilites'
import { LeadText } from '../Shared/LeadText';
import { Footer } from '../Shared/Footer';

export class SaveForLater extends Component {
    static displayName = 'Save for later';

    componentWillMount() {
        SaveForLaterUpsert(this.props.model)
            .then((data) => {
                this.setState(
                    data.value
                );
            }).catch((err) => {
                this.setState({serverError : "Error"})
            });
    }

    state = {
        appId: "",
        email: "",
        ssnLastFour: "",
        serverError: "",
        isLoading : false
    }

    render() {

        return (
            <div>

                {this.state.serverError &&
                    <>
                        <LeadText text="We have encountered an error." subText="Please try again" ShowSaveForLater={false} />
                        <Row>
                    <Col xs={{ size: 12, order: 2 }} sm={{ size: 4, order: 1 }} className="mt-2 mt-sm-0">
                    <Button color="" type='button' block onClick={!this.state.isLoading ? this.handleTryAgainClick : undefined}
                        disabled={this.state.isLoading} 
                        className="shine-green-btn">
                            {this.state.isLoading ?
                                <Spinner size="sm"><span className="sr-only">Loading...</span></Spinner> :
                                'Try Again'
                            }
                            
                        </Button>
                    </Col>
                </Row>
                    </>}


                {this.state.appId &&
                    <>
                        <LeadText text="Let's continue this later" subText="Your application has been saved!" ShowSaveForLater={false} />
                        <div>
<p>We've sent a secure link to your in-progress application to the email address you provided. Here's what you need to know: </p>
                            <ul className="ml-4">
                                <li><strong>Your Application ID number is {this.props.model.leadId}</strong><br />
                            You'll need this number to continue your application. So be sure to save it for future reference.
                                </li>
                                <li><strong>Continuing your application</strong><br />
                            Just click the link we sent you, then enter your Application ID number and identity verification information. Doing so will take you back to where you left off.
                                </li>
                                <li><strong>For your security </strong><br />
                            The link to your in-progress application will expire after 10 days. If you cannot continue or complete it before then, the information you’ve entered will be lost. 
                                </li>
                                </ul>
                            <p>Thanks again for your interest in joining Dupaco — we’re looking forward to welcoming you as our newest member-owner!  </p>
                            {/* <p>Email = {this.state.email} </p>
                            <p>ssnLastFour = {this.state.ssnLastFour} </p> */}
                        </div>
                    </>}

                {(!this.state.appId && !this.state.serverError)&&
                    <Row>
                        <Col xs="12">
                            <LeadText text="Your in-progress application is being saved" subText="We will email you a link to resume." ShowSaveForLater={false} />
                        </Col>
                        <Col xs="12" className="d-flex justify-content-center mb-3">
                            <Spinner size="lg"/>
                        </Col>
                    </Row>
                }
                <Row className="mb-3">
                    <Col xs={{ size: 12, order: 2 }} sm={{ size: 4, order: 1 }} className="mt-2 mt-sm-0">
                        <Button type='button' block onClick={() => { this.props.history.goBack() }} className="shine-orange-outline-btn">Back</Button>
                    </Col>
                </Row>
                <Footer />
            </div>
        );
    }

    resetState = () => {
        this.setState({
            appId: "",
            email: "",
            ssnLastFour: "",
            serverError: "",
            isLoading : false
        });
    }

    handleTryAgainClick = () => {
        this.resetState();
        SaveForLaterUpsert(this.props.model)
            .then((data) => {
                this.setState(
                    data.value
                );
            }).catch((err) => {
                this.setState({serverError : "Error"})
            });
    }

    // Don't worry about this for now.
    // If we want inline validation we can add that later
    onBlur = (e) => {

    }
}