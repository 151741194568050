const occupations = [
    { value: "17-0000", text: 'Architecture/Engineering' },
    { value: "27-0000", text: 'Arts/Design/Entertainment/Sports/Media' },
    { value: "37-0000", text: 'Building/Grounds/Cleaning/Maintenance' },
    { value: "13-0000", text: 'Business/Financial' },
    { value: "21-0000", text: 'Community/Social Service' },
    { value: "15-0000", text: 'Computer/Mathematical' },
    { value: "47-0000", text: 'Construction/Extraction' },
    { value: "25-0000", text: 'Educational Instruction/Library' },
    { value: "11-0000", text: 'Executive/Management' },
    { value: "45-0000", text: 'Farming/Fishing/Forestry' },
    { value: "35-0000", text: 'Food Preparation/Service' },
    { value: "29-0000", text: 'Healthcare Practitioners/Technical' },
    { value: "31-0000", text: 'Healthcare Support' },
    { value: "49-0000", text: 'Installation/Maintenance/Repair' },
    { value: "23-0000", text: 'Legal' },
    { value: "55-0000", text: 'Military' },
    { value: "43-0000", text: 'Office/Administrative Support' },
    { value: "39-0000", text: 'Personal Care/Service' },
    { value: "51-0000", text: 'Production' },
    { value: "33-0000", text: 'Protective Service' },
    { value: "41-0000", text: 'Sales/Related' },
    { value: "53-0000", text: 'Transportation/Material Moving' },
    { value: "00-0000", text: 'No former occupation' }
];

export default occupations;