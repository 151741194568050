import React, { useState } from 'react';
import { UncontrolledCollapse, Row, Col } from 'reactstrap';
import classnames from 'classnames';

const ProductInfo = ({ id, innerToggle }) => {
    const [showToggle, setToggle] = useState(true);

    return (
        <>
            <UncontrolledCollapse toggler={'#' + id}>
                <Row>
                    <Col>
                        <div dangerouslySetInnerHTML={{ __html: innerToggle }} />
                    </Col>
                </Row>
            </UncontrolledCollapse>
             <a href="/#" className="text-decoration-none" style={{textdecoration:'none'}} onClick={() => setToggle(!showToggle)} id={id}>
                 <i className={classnames({
                'mdi': true,
                'mdi-chevron-down': showToggle,
                'mdi-chevron-up': !showToggle,
            })}></i>{showToggle ? 'Show More' : 'Show Less'}</a>
        </>
    );
};

export default ProductInfo;