import React, { useState, useEffect } from 'react';
import { LeadText } from '../../Shared/LeadText';
import NavigationButtons from '../../Shared/NavigationButtons';
import { Row, Col, Button, Spinner, Alert } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import { withRouter } from 'react-router-dom';
import {
    usePlaidLink,
} from 'react-plaid-link';
import PlaidOptions from './Plaid/PlaidOptions';
import { GetPlaidToken, LinkPlaidAccount, PlaidError } from '../../../utilities/FetchUtilites';


const ACH = (props) => {
    const [isLoading, setLoadingState] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [plaidToken, setPlaidToken] = useState('');
    useEffect(() => {
        var returnedToken = GetPlaidToken(props.model)
            .then(res => {
                if (res.value.data !== '') {
                    setLoadingState(false);
                    setPlaidToken(res.value.data);
                } else {
                    props.onChangeHandler(res.value.error);
                    setTimeout(() => { props.history.push('Method') }, 2000);
                    return;
                }
            });
    }, []);

    PlaidOptions.onSuccess = (public_token, metadata) => {
        var res = LinkPlaidAccount(public_token, metadata, props.model)
            .then(res => {
                let history = props.history;
                var screen = res.value.screen;
                props.onFieldName(res.value.accountNumber, "accountNumber");
                props.onFieldName(res.value.institutionName, "institutionName");
                props.onFieldName(res.value.routingNumber, "routingNumber");
                setLoadingState(false);
                history.push(screen);
            });
    };
    PlaidOptions.onExit = (error, metadata) => {
        GetPlaidToken(props.model)
            .then(res => {
                setPlaidToken(res.value.data);
                setLoadingState(false);
            });

        if (error == null) {
            setLoadingState(false);
            return;
        }

        PlaidError(props.model, error, metadata)
            .then(res => {
                setLoadingState(false);
                setErrorMessage(res.message);
            });
    };

    PlaidOptions.token = plaidToken;
    const { open, exit, ready } = usePlaidLink(PlaidOptions);

    const openPlaid = () => {
        if (isLoading) {
            return;
        }
        setLoadingState(true);
        setErrorMessage('');

        // open plaid link
        open();
    }
    return (
        <>


            <LeadText text="Let's make your opening deposit(s)" subText="Here's how funding from an external account works" ShowSaveForLater={false} />
            <Row>
                <Col xs={12} sm={12} className='mb-3'>
                    <p>We use a service called Plaid to verify your external account(s) so you can fund your new Dupaco account(s) in just a few seconds. Don't see your bank listed? You can manually enter your account and routing numbers instead. Hint: Manual connection take 1-2 days to verify.
                        <br/>
                        <br/>
                        But before you get started, here's what else you'll need to know:</p>
                    <ul className='ml-4'>
                        <li className='mb-1'>You can only transfer from checking and savings accounts at financial institutions that allow ACH transactions.</li>
                        <li className='mb-1'>You'll want to have your external account number, online/mobile banking credentials* and the institutions routing number nearby.</li>
                        <li>Any information you provide is safe and secure with us!</li>
                    </ul>
                    <p>We'll bring you back here to finish setting up your account(s) once you're finished. And once your account is verified, you'll be able to fund your new Dupaco account(s). </p>
                    <p style={{ fontSize: '75%' }}>*Dupaco will not save your credentials, but will be allowed to confirm that the external account has enough funds to cover the requested transfer.</p>
                </Col>
            </Row>
            {errorMessage != '' &&
                <Row>
                    <Col xs={12} sm={12} className='mb-3'>
                        <Alert color='danger'>
                            <p>{errorMessage}</p>
                        </Alert>
                    </Col>
                </Row>
            }
            <NavigationButtons setRouteTransition={props.setRouteTransition} ShowSaveForLater={false} showCancel={true} showBack={true} showNext={false} nextButtonText="Let's Continue">
                <Col xs={{ size: 12 }} sm={{ size: 4, order: 2 }} className="mt-2 mt-sm-0">
                    <Button type='button' id='btnOpen' block onClick={openPlaid} className="shine-green-btn">{isLoading ? <Spinner size="sm" /> : <>Let's Continue</>}</Button>
                </Col>
            </NavigationButtons>
        </>
    );
}

export default withRouter(ACH);