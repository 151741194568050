import { object, string } from 'yup';

const PromoCodeState = {
    promoCodeID: "",
    promoCodeName: "",
    promoCodeDetails: "",
    promoCode: "",
    promoCodeStatus: ""
};

export const PromoCodeValidation = (context) => {
    return object().shape({
        promoCode: string().required(() => context.setState({ setPromoCodeError: 'Required' }))
    });
};

export default PromoCodeState;