import React, { useState } from 'react';
import { Row, Col, Input, Card, CardBody, CardFooter, CardTitle, Label, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import ProductInfo from './ProductInfo';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import RadioButtonList from '../../Shared/RadioButtonList';
import NoLeadIdRedirect from '../../../components/Shared/NoLeadIdRedirect';

const checkIfInArray = (arr, value) => {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].productID === value) {
            return i;
        }
    }
    return -1;
};




const Product = ({ product, selectedProductsArray, index, stateHandler, onProductSelection, onBsaSelection, errors, onRegCChange, onMoneyCardChange, model }) => {
    const indexInArray = checkIfInArray(selectedProductsArray, product.productID);
    const defaultValue = selectedProductsArray[indexInArray] === undefined ? false :
        selectedProductsArray[indexInArray].regC === '' ? false :
            true;
    const defaultMoneyCardValue = selectedProductsArray[indexInArray] === undefined ? false :
        selectedProductsArray[indexInArray].moneyCard === '' ? false :
            true;
    const [regEClicked, setRegEClicked] = useState(defaultValue);
    const [showModal, setModal] = useState(false);
    const [moneyCardClicked, setMoneyCardClicked] = useState(defaultMoneyCardValue);
    const [showMoneyCardModal, setMoneyCardModal] = useState(false);
    //======= COE Check, displaying different text if this a COE referral
    var isCOE = false;
    const coeReferralIds = new Set(['100', '101', '102', '103', '104', '500', '501', '502', '503', '504', '505', '506', '507', '508', '509', '510', '511', '512', '513', '514', '515', '533', '106', '535', '541', '542', '543', '107' ,'544', '545']); 
            // hardcoded Source ID's (called Codes in CRMNEXT) NOT Lookup IDs of referrals that should be allowed to skip the eligiblity and funding steps, all others cannot // COE TODO
    if (coeReferralIds.has(model.leadSourceId)) isCOE = true;
    const hasAchieve = model.leadSourceId === '108';

    let showMoreDescription = (!isCOE) ? product.productDescriptionShowMore : product.productDescriptionShowMoreSuperSEG;

    let productDescription = "";
    if (isCOE && product.productID === '0') {
        productDescription = "Your Dupaco membership begins with a Share Savings account.";
    }
    else {
        productDescription = product.productDescription;
    }
    //======================


    if (selectedProductsArray[indexInArray] === undefined && regEClicked) {
        setRegEClicked(false);
    }

    const viewRegEConsentForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setRegEClicked(true);
        setModal(true);
    }

    const regEChange = (e) => {
        if (!regEClicked) {
            setRegEClicked(true);
            setModal(true);
        }
        else {
            onRegCChange(e);
        }
    }

    const viewRequestMoneyCardDetails = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setMoneyCardModal(true);
    }

    const moneyCardChange = (e) => {
        onMoneyCardChange(e);
    }

    const isSelected = product.productID === '0' || product.productID === '8' || indexInArray >= 0;
    if (product.productID === "0") {
        let shareSavingsProduct = selectedProductsArray.find(product => product.productID === "0")
    }

    return (
        <>
            <NoLeadIdRedirect />
            <React.Fragment key={product.productID}>
                <Card>
                    <CardBody>
                        <CardTitle tag="h3">{product.productName}</CardTitle>
                        <p className='d-flex justify-content-between'>
                            {!isCOE &&
                                <>
                                    <span>
                                        {product.productMinimum !== null && product.productMinimum === 0 &&
                                            <i>No minimum to open</i>
                                        }
                                        {product.productMinimum !== null && product.productMinimum !== 0 &&
                                            <i>${new Intl.NumberFormat().format(product.productMinimum)} minimum to open</i>
                                        }
                                    </span>
                                </>
                            }
                            <span className='text-right-lg'>
                                {product.rates !== null &&
                                    <a href={product.rates} rel='noopener noreferrer' target='_blank'>See rates</a>
                                }
                            </span>
                        </p>
                        <p>{productDescription}</p>
                        <ProductInfo id={"product_" + product.productID} innerToggle={showMoreDescription} />
                    </CardBody>
                    <CardFooter className={isSelected ? 'orange-selected' : ''}>
                        <Row>
                            <Col>
                                <div>

                                    {(product.productID === '0' ) &&
                                        <>
                                            <Input type='checkbox' name={product.productID} checked={isSelected} id={product.productID}
                                                className="magic-checkbox" readOnly={true} />
                                            <Label for={product.productID} readOnly className="mb-0"> Required for Membership</Label>
                                        </>
                                    }
                                    {(product.productID !== '0') &&
                                        <>
                                            <Input type='checkbox' name={product.productID} checked={isSelected} id={product.productID}
                                                className="magic-checkbox" onChange={product.productID !== '8' ? onProductSelection : undefined}
                                                readOnly={product.productID === '8'} />
                                            <Label for={product.productID} className="mb-0">
                                                Add {product.productName}
                                            </Label>
                                        </>
                                    }

                                </div>
                            </Col>
                        </Row>
                    </CardFooter>
                    {isSelected && (product.productID === '1' || product.productID === '2') &&
                        <CardFooter>
                            {(product.productID === '1' || product.productID === '2') &&
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <FormFieldWrap
                                            innerLabelTextHyperLink={(<>&nbsp;<a href="#" onClick={viewRequestMoneyCardDetails}>(See Details)</a></>)}
                                            innerLabelText="Do you want a Dupaco MoneyCard for your new checking account? Hint: That's our version of a Debit & ATM Mastercard." className='no-margin-btm'
                                            errorMessage={errors[indexInArray].moneyCardError}>
                                            <RadioButtonList id={'moneyCard' + index}
                                                options={[{
                                                    id: "moneyCardYes" + index,
                                                    checked: selectedProductsArray[indexInArray].moneyCard === 'true',
                                                    labelText: 'Yes, I want a Moneycard',
                                                    handleOnChange: moneyCardChange,
                                                    disabled: !moneyCardClicked,
                                                    value: 'true'
                                                },
                                                {
                                                    id: "moneyCardNo" + index,
                                                    checked: selectedProductsArray[indexInArray].moneyCard === 'false',
                                                    labelText: "No, I don't want a Moneycard",
                                                    handleOnChange: moneyCardChange,
                                                    disabled: !moneyCardClicked,
                                                    value: 'false'
                                                }]} />
                                        </FormFieldWrap>
                                    </Col>
                                </Row>
                            }
                            {(product.productID === '1' || product.productID === '2') &&
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <FormFieldWrap
                                            innerLabelTextHyperLink={(<>&nbsp;<a href="#" onClick={viewRegEConsentForm}>(See Details)</a></>)}
                                            innerLabelText='Do you want us to authorize and pay overdrafts that may occcur as the result of ATM or 
                                        debit card transactions from this account?' className='no-margin-btm'
                                            errorMessage={errors[indexInArray].regCError}>
                                            <RadioButtonList id={'regC' + index}
                                                options={[{
                                                    id: "regCYes" + index,
                                                    checked: selectedProductsArray[indexInArray].regC === 'true',
                                                    labelText: 'Yes',
                                                    handleOnChange: regEChange,
                                                    disabled: !regEClicked,
                                                    value: 'true' //this value is tightly coupled with label below and assocated model
                                                },
                                                {
                                                    id: "regCNo" + index,
                                                    checked: selectedProductsArray[indexInArray].regC === 'false',
                                                    labelText: 'No',
                                                    handleOnChange: regEChange,
                                                    disabled: !regEClicked,
                                                    value: 'false' //this value is tightly coupled with label below and assocated model
                                                }]} />
                                        </FormFieldWrap>
                                    </Col>
                                </Row>
                            }

                        </CardFooter>
                    }
                </Card>
                <Modal isOpen={showModal} toggle={setModal} backdrop={'static'}>
                    <ModalBody>
                        <div class='d-flex justify-content-center align-items-center'>
                            {new Date() >= new Date(2023, 1, 1) ?
                                <img src='https://www3.dupaco.com/dupaco/images/interior/reg-e-consent-form-23.jpg'
                                    className='img-fluid' alt='reg e consent form' />
                                :
                                <img src='https://www3.dupaco.com/dupaco/images/interior/reg-e-consent-form-2021.jpg'
                                    className='img-fluid' alt='reg e consent form' />
                            }

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button type='button' onClick={() => setModal(false)}>I understand</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={showMoneyCardModal} toggle={setMoneyCardModal} backdrop={'static'}>
                    <ModalBody>
                        <div>
                            <p>A Dupaco MoneyCard gives you 24/7 access to the funds in your checking account. Plus, there are no recurring fees, interest payments or monthly bills to manage. Card(s) will be issued for each account holder and arrive within 7-10 business days with instructions to activate. Card details — like card number, CVV, and expiration date — can be accessed through the Dupaco Mobile app in 1-2 days.</p>
                            <p>Not sure if you want a MoneyCard? You can request a physical MoneyCard later by calling us at 800-373-7600, ext. 201 or by sending us a message in Shine.</p>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button type='button' onClick={() => setMoneyCardModal(false)}>Ok</Button>
                    </ModalFooter>
                </Modal>
                <br />
            </React.Fragment>
        </>
    );
}


export default Product;



