import { object, string } from 'yup';
import moment from 'moment';

export const ResumeValidation = (setState) => {
    let mainSchema = object().shape({
        email: string()
            .email(() => setState({ emailError: 'Please provide a valid email' }))
            .required(() => setState({ emailError: 'Required' })),
        ssn: string()
            .length(4, () => setState({ ssnError: 'Required' }))
            .matches(/^[0-9]*$/, () => setState({ ssnError: 'Required' })),
        appNumber: string().required(() => setState({ appNumberError: 'Required' })),
    });

    return mainSchema;
};

export const ForogtAppNumberValidation = (setState) => {
    let mainSchema = object().shape({
        ssn: string().test('ssn', () => setState({ ssnError: "Required" }), function ssnValidation(value) {
            var ssnPattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
            return ssnPattern.test(value);
        }).required(() => setState({ ssnError: 'Required' })),
        dob: string()
            .test('dob', () => setState({ dobError: 'Must enter a valid date' }),
                function dateValidation(value) {
                    let momentDate = new moment(value, 'MM/DD/YYYY', true);
                    let isValidDate = momentDate.isValid();
                    return isValidDate;
                })
            .required(() => setState({ dobError: 'Required' }))
    });

    return mainSchema;
};