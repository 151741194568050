import React from 'react';
import { Footer } from '../Shared/Footer';
import { LeadText } from '../Shared/LeadText';
import NavigationButtons from '../Shared/NavigationButtons';

const Complete = ({ model, setRouteTransition }) => {

    return (
        <div className="transition-group">
            <div className="route-section">

                <>
                    <LeadText subText="Sorry, we're having trouble processing your info." />
                    <p>
                        It looks like one of these things is causing an issue:
                    </p>

                    <ul className="ml-4">
                        <li><strong>You're already a Dupaco member</strong><br />
                        <p>This application is for people who haven't joined Dupaco yet. If you're already a member, you can add new accounts in-branch or via Shine.</p>
                                </li>
                        <li><strong>You entered the wrong name, DOB, or SSN</strong><br />
                            <p>Please click <i>Back</i> and enter them again.</p>                                
                            </li>
                    </ul>

                    <NavigationButtons ShowSaveForLater={false} showDupacoDotCom={false} showNext={false} showCancel={false} showBack={true} showShineLogin={true} setRouteTransition={setRouteTransition} />
                </>
                <Footer />
            </div>
        </div >
    );
}

export default Complete;