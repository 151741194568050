import React from 'react';
import classnames from 'classnames';


const RadioButtonList = ({
    listWrapperDivClassName = '',
    id = 'RadioList',
    name = id,
    options = [],
    inputClassName = "magic-radio smaller",
    verticallyDisplayTwoOptionsArray = true,
    disabled = false
}) => {
    return (
        <div className={classnames({
            'd-flex flex-row': (options.length === 2 && verticallyDisplayTwoOptionsArray === true),
            [`${listWrapperDivClassName}`]: true
        })}>
            {options.map((option, index) => {
                return <div key={index} style={{ marginTop: '8px' }} className={option.checkboxWrapperDivClassName ? option.checkboxWrapperDivClassName : ''}>
                    <input type="radio"
                        id={option.id}
                        name={name}
                        value={option.value ? option.value : option.labelText}
                        onChange={option.handleOnChange}
                        disabled={disabled}
                        checked={option.checked}
                        className={inputClassName} />
                    <label htmlFor={option.id} >&nbsp;{option.labelText}</label>
                </div>;
            })}
        </div>
    );
}

export default RadioButtonList; 