import { object, string } from 'yup';

const IsJointApplicationState = {
    isJointApplication: ''
};

export const IsJointApplicationValidation = (context) => {
    return  object().shape({
        isJointApplication: string().required(() => context.setState({isJointApplicationError: 'Required' }))
    });
};

export default IsJointApplicationState;