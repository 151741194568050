import { object, string } from 'yup';

const LiveState = {
    selectedCounty: ""
};

export const LiveValidation = (context) => {
    return object().shape({
        selectedCounty: string().required(() => context.setState({ selectedCountyError: 'Required' }))
    });
};

export default LiveState;