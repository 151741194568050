const GetSteps = () => {
    return [
        {
            name: 'Eligibility',
            label: 'Eligibility',
            visible: true
        },
        {
            name: 'Products',
            label: 'Select Accounts',
            visible: true
        },
        {
            name: 'Member',
            label: 'About You',
            visible: true
        },

        {
            name: 'Setup',
            label: 'Account Setup',
            visible: true
        },
        {
            name: 'Fund',
            label: 'Fund Accounts',
            visible: true
        },
        {
            name: 'Complete',
            label: 'Complete',
            visible: false
        }
    ]
};

export default GetSteps;