const suffixes = [
    {value: "II", text: 'II'},
    {value: "III", text: 'III'},
    {value: "IV", text:'IV'},
    {value: "V", text:'V'},
    {value: "VI", text:'VI'}, 
    {value: "JR", text: 'Jr.'},
    {value: "SR", text:'Sr.'},
    {value: "CPA", text:'CPA'},
    {value: "DC", text:'D.C.'},
    {value: "DDS", text: 'DDS'},
    {value: "DMD", text:'DMD'},
    {value: "DVM", text:'DVM'},
    {value: "MD", text:'MD'},
    {value: "OD", text:'O.D.'},
    {value: "PHD", text:'Ph.D.'},
    {value: "RM", text:'RM'}
];

export default suffixes;