import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Input, UncontrolledPopover, PopoverBody, Button } from 'reactstrap';
import FormFieldWrap from './FormFieldWrap';
import Dropdown from './DropDown';
import NavigationButtons from './NavigationButtons';
import { PersonValidation } from '../../StateModels/PersonInfoState';
import suffixes from '../../utilities/Suffixes';
import InputMask from 'react-input-mask';
import { CountryRegionData } from 'react-country-region-selector';
import RadioButtonList from './RadioButtonList';
import { LeadText } from './LeadText';
import { isLessThanOrEqualToMaxLength, maxLength } from '../../utilities/StringUtilities'
import { CheckForExistingApplication, CheckForExistingMember, CheckJointSSNForMatch } from '../../utilities/FetchUtilites';
import AlertMessage from '../Shared/Alert';
import moment from 'moment';


class PersonInfo extends Component {

    state = {
        firstNameError: '',
        lastNameError: '',
        miError: '',
        dob: '',
        dobError: '',
        countryError: '',
        ssnError: '',
        driverLicenseError: '',
        emailError: '',
        emailConfirmationError: '',
        primaryPhoneError: '',
        mobilePhoneError: '',
        alternatePhoneError: '',
        extensionError: '',
        backupWithholdingError: '',
        primaryPhoneIsMobileError: '',
        citizenError: '',
        backupWithholdingPopoverOpen: false,
        alternatePhonePopoverOpen: false
    };

    toggleBackupWithholdingPopOver = () => {
        this.setState(prevState => { return { backupWithholdingPopoverOpen: !prevState.backupWithholdingPopoverOpen }; });
    }

    toggleAlternatePhonePopoverOpen = () => {
        this.setState(prevState => { return { alternatePhonePopoverOpen: !prevState.alternatePhonePopoverOpen }; });
    }

    handleKeyDown(e) {
        if (e.keyCode === 8 && document.activeElement.tagName.toLowerCase() !== 'input') {
            e.preventDefault();
            e.stopPropagation();
            e.returnValue = false;
            document.getElementById("ssn").focus();
            return false;
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
        if (!this.props.fromReview) {
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    onBlur = (e) => {

    }

    render() {
        const { model } = this.props;
        return (

            <>
                {this.props.fromReview === false &&
                    <LeadText text={this.props.isPrimaryMember ? "About you (1 of 5)" : "About your joint account owner (1 of 3)"}
                        subText="Personal & Contact" ShowSaveForLater={this.props.isPrimaryMember ? false : true}
                    />
                }
                {this.props.fromReview === true &&
                    <LeadText text={""} subText="Personal & Contact" ShowSaveForLater={false} />
                }
                <fieldset disabled={this.props.readOnly}>
                    <Row form>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap id='dob' innerLabelText='Date of birth' errorMessage={this.state.dobError}>
                                {
                                    <InputMask mask="99/99/9999" maskChar='_'
                                        value={model.dob}
                                        onChange={this.onChangeHandler}
                                        onBlur={this.changeDOB}
                                        name='dob'
                                        id='dob'
                                    >
                                        {(inputProps) => <Input type='tel' {...inputProps} />}
                                    </InputMask>
                                }
                            </FormFieldWrap>
                        </Col>
                        {!this.props.fromReview &&

                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='citizen' innerLabelText='Are you a U.S. citizen?' errorMessage={this.state.citizenError}>
                                    <RadioButtonList id='citizen'
                                        disabled={this.props.fromReview}
                                        options={[{
                                            id: "citizen",
                                            checked: model.citizen === 'true',
                                            labelText: 'Yes',
                                            handleOnChange: this.onChangeHandler,
                                            value: 'true' //this value is tightly coupled with label below and assocated model
                                        },
                                        {
                                            id: "nonCitizen",
                                            checked: model.citizen === 'false',
                                            labelText: 'No',
                                            handleOnChange: this.onChangeHandler,
                                            value: 'false' //this value is tightly coupled with label below and assocated model
                                        }]} />
                                </FormFieldWrap>
                            </Col>
                        }

                        {model.citizen === 'false' &&

                            <Row form>
                                <Col xs={12} sm={12}>
                                    <AlertMessage alertColor=''
                                        alertHeading='Error'
                                        alertMessage={<p>{"To apply for membership online, you must be a U.S. citizen. But we'd still love for you to join. Visit us in person at any Dupaco branch — we'll be glad to help you out! Have questions? Give us a call at 800-373-7600, ext. 206."}</p>}
                                        showButton={false}
                                    />
                                </Col>
                            </Row>

                        }
                        {/* Not needed at this time... */}
                        {/* {model.citizen === 'false' &&
                            <Col xs={12} sm={6} >
                                <FormFieldWrap id='country' innerLabelText='Country of citizenship' errorMessage={this.state.countryError}>
                                    <CountryDropdown value={model.country} onChange={(e) => this.setCountry(e)} name='country' id='country'
                                        classes="form-control" valueType="short" blacklist={["US"]} />
                                </FormFieldWrap>
                            </Col>} */}
                    </Row>
                    <Row form>
                        <Col xs={12} sm={4}>
                            <FormFieldWrap id='firstName' innerLabelText='First name' errorMessage={this.state.firstNameError}>
                                <Input type='text' name='firstName' id='firstName' value={model.firstName}

                                    onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                            </FormFieldWrap>
                        </Col>
                        <Col xs={12} sm={2}>
                            <FormFieldWrap id='mi' innerLabelText='MI' errorMessage={this.state.miError} isRequired={false}>
                                <Input type='text' name='mi' id='mi' value={model.mi.replace(/[^A-Z]/ig, '')} onChange={(e) => { e.target.value = maxLength(e.target.value, 1); this.onChangeHandler(e) }} onBlur={this.onBlur.bind(this)} />
                            </FormFieldWrap>
                        </Col>
                        <Col xs={12} sm={4}>
                            <FormFieldWrap id='lastName' innerLabelText='Last name' errorMessage={this.state.lastNameError}>
                                <Input type='text' name='lastName' id='lastName' value={model.lastName} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                            </FormFieldWrap>
                        </Col>
                        <Col xs={12} sm={2}>
                            <FormFieldWrap id='suffix' innerLabelText='Suffix' errorMessage={this.state.suffixError} isRequired={false}>
                                <Dropdown id='suffix' name='suffix' value={model.suffix} handleOnChange={this.onChangeHandler} handleOnBlur={this.onBlur.bind(this)} options={suffixes} defaultText='' />
                            </FormFieldWrap>
                        </Col>
                    </Row>
                    <Row form>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap id='ssn' innerLabelText='Social Security number' errorMessage={this.state.ssnError}>
                                {model.ssn.replace(/\D/g, '').length !== 9 ?
                                    <InputMask mask="999-99-9999" maskChar=' '
                                        value={model.ssn}
                                        onChange={this.onChangeHandler}
                                        onBlur={this.onBlur.bind(this)}
                                        name='ssn'
                                        id='ssn'
                                        disabled={model.disableSSN || this.props.fromReview}
                                    >
                                        {(inputProps) => <Input type='tel' {...inputProps} />}
                                    </InputMask>
                                    :
                                    <Input type='tel' onChange={this.changeSSN}
                                        onBlur={this.onBlur.bind(this)}
                                        name='ssn'
                                        id='ssn'
                                        value={model.ssn.substring(0, 7).replace(/[0-9]/g, '*') + model.ssn.substring(7, model.ssn.length)}
                                        disabled={model.disableSSN || this.props.fromReview}
                                    />
                                }


                            </FormFieldWrap>
                        </Col>
                        {this.props.isPrimaryMember &&

                            <Col xs={12} sm={6} >
                                <FormFieldWrap id='backupWithholding' innerLabelText='Are you subject to backup withholding?' errorMessage={this.state.backupWithholdingError}><i onClick={this.toggleBackupWithholdingPopOver} id='backupPop' className='mdi mdi-information-outline'><span className='sr-only'>More info</span></i>
                                    <UncontrolledPopover trigger={'click hover focus legacy'} toggle={() => this.toggleBackupWithholdingPopOver()} isOpen={this.state.backupWithholdingPopoverOpen} placement='top' target='backupPop'>
                                        <PopoverBody>
                                            Answer <i>Yes</i> if you’ve been notified by the Internal Revenue Service (IRS) that you’re subject to backup withholding for failure to report all interest or dividends.
                                </PopoverBody>
                                    </UncontrolledPopover>

                                    <RadioButtonList id='backupWithholding'
                                        options={[{
                                            id: "yesBackupWithholding",
                                            checked: model.backupWithholding === 'true',
                                            labelText: 'Yes',
                                            handleOnChange: this.onChangeHandler,
                                            value: 'true' //this value is tightly coupled with label below and assocated model
                                        },
                                        {
                                            id: "noBackupWithholding",
                                            checked: model.backupWithholding === 'false',
                                            labelText: 'No',
                                            handleOnChange: this.onChangeHandler,
                                            value: 'false' //this value is tightly coupled with label below and assocated model
                                        }]} />
                                </FormFieldWrap>
                            </Col>}
                    </Row>
                    <Row className={model.backupWithholding === 'true' ? '' : 'd-none'}>
                        <Col xs={12} sm={12}>
                            <AlertMessage alertColor=''
                                alertHeading='Alert'
                                alertMessage={<p>You’re stating that you’ve been notified by the Internal Revenue Service (IRS) that you’re subject to backup withholding for failure to report all interest or dividends.  If this is incorrect, please select <i>No</i>.</p>}
                                showButton={false}
                            />
                        </Col>
                    </Row>
                    <Row form>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap id='primaryEmail' innerLabelText='Email' errorMessage={this.state.emailError}>
                                <Input type='text' name='primaryEmail' id='primaryEmail' value={model.primaryEmail} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                            </FormFieldWrap>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap id='primaryEmailConfirmation' innerLabelText='Confirm email' errorMessage={this.state.emailConfirmationError}>
                                <Input type='text' name='primaryEmailConfirmation' id='primaryEmailConfirmation' value={model.primaryEmailConfirmation} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                            </FormFieldWrap>
                        </Col>
                    </Row>
                    <Row form>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap id='primaryPhone' innerLabelText='Primary phone' errorMessage={this.state.primaryPhoneError}>
                                <InputMask mask="(999) 999-9999" value={model.primaryPhone} onChange={this.onChangeHandler} maskChar=' ' onBlur={this.onBlur.bind(this)} name='primaryPhone' id='primaryPhone'>
                                    {(inputProps) => <Input type='tel' {...inputProps} />}
                                </InputMask>
                            </FormFieldWrap>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap innerLabelText='Is this a mobile number?' errorMessage={this.state.primaryPhoneIsMobileError}>
                                <RadioButtonList id='primaryPhoneIsMobile'
                                    options={[{
                                        id: "mobilePhone",
                                        checked: model.primaryPhoneIsMobile === 'true',
                                        labelText: 'Yes',
                                        handleOnChange: this.onChangeHandler,
                                        value: 'true' //this value is tightly coupled with label below and assocated model
                                    },
                                    {
                                        id: "nonMobilePhone",
                                        checked: model.primaryPhoneIsMobile === 'false',
                                        labelText: 'No',
                                        handleOnChange: this.onChangeHandler,
                                        value: 'false' //this value is tightly coupled with label below and assocated model
                                    }]} />
                            </FormFieldWrap>
                        </Col>
                    </Row>
                    <Row form>
                        <Col xs={12} sm={6}>

                            <FormFieldWrap id='alternatePhone' innerLabelText='Alternate phone number' errorMessage={this.state.alternatePhoneError} isRequired={false}>
                                &nbsp;<i id='altPhonePop' onClick={this.toggleAlternatePhonePopoverOpen} className='mdi mdi-information-outline'><span className='sr-only'>More info</span></i>
                                <UncontrolledPopover trigger={'click hover focus legacy'} toggle={() => this.toggleAlternatePhonePopoverOpen()} isOpen={this.state.alternatePhonePopoverOpen} placement='top' target='altPhonePop' >
                                    <PopoverBody>
                                        An alternate phone number will be used in the event that Dupaco needs to contact you, but can't reach you at your primary phone number.
                                    </PopoverBody>
                                </UncontrolledPopover>
                                <InputMask mask="(999) 999-9999" value={model.alternatePhone} maskChar=' ' onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} name='alternatePhone' id='alternatePhone'>
                                    {(inputProps) => <Input type='tel' {...inputProps} />}
                                </InputMask>
                            </FormFieldWrap>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap id='extension' innerLabelText='Extension' errorMessage={this.state.extensionError} isRequired={false} >
                                <Input type='number' name='extension' id='extension' value={model.extension} onChange={(e) => { if (isLessThanOrEqualToMaxLength(e.target.value, 6)) { this.onChangeHandler(e) } }} onBlur={this.onBlur.bind(this)} />
                            </FormFieldWrap>
                        </Col>
                    </Row>
                </fieldset>
                <br />

                {!this.props.fromReview && model.citizen !== 'false' &&
                    <NavigationButtons onNext={this.onNext.bind(this)} setRouteTransition={this.props.setRouteTransition} ShowSaveForLater={false} />
                }

                {this.props.fromReview &&
                    <Row>
                        <Col xs={{ size: 12 }} sm={{ size: 4, offset: 8 }}>
                            <Button type='button' block className='shine-green-btn' onClick={this.onNext.bind(this)}>Save</Button>
                        </Col>
                    </Row>
                }
            </>
        );
    }

    // This function is handed an event object as it is called because of an event handler (usually onChange)
    // We then pass the name and value of the target being updated to the stateHandler from App.js
    // THIS IS DONE DIFFERENTLY FOR ANY DATE PICKERS
    // Search for the 'dob' date picker on this page to see what I mean
    onChangeHandler = (e) => {
        this.props.stateHandler(e.target.name, e.target.value);
    }

    changeSSN = (e) => {
        let newSSN = e.target.value.slice(-4);
        let ssn = this.props.model.ssn.slice(0, this.props.model.ssn.length - 4);
        newSSN = (ssn + newSSN).substring(0, 11);
        this.props.stateHandler('ssn', newSSN);
    }

    changeDOB = (e) => {
        const dob = e.target.value;
        const dobDate = new Date(dob);

        if (isNaN(dobDate.getTime())) {
            // Invalid date
            this.setState({ dobError: 'Must enter a valid date' });
        } else {
            let momentDate = new moment(dob, 'MM/DD/YYYY', true);
            if (momentDate > moment().startOf('day').subtract(18, 'years')) {
                this.setState({ dobError: 'You must be at least 18 years old' });
            }
            else {
                this.setState({ dobError: '' });
            }
        }
    }



    // This function is handed the history api push method from the NavigationButtons component,
    // which is used to switch to another page's specific id.
    // THIS FUNCTION IS REQUIRED IF THE PAGE HAS VALIDATION OR WEIRD ROUTING

    onNext = async () => {
        this.resetErrors();

        let results = await PersonValidation(this, this.props.otherApplicantEmail, this.props.otherApplicantSSN).validate(this.props.model, {
            recursive: true,
            abortEarly: false
        }).then(() => {
            return true;
        })
            .catch((validationErrors) => {
                window.scrollTo(0, 0);
                return false;
            });

        if (results && !this.props.isPrimaryMember) {
            results = await CheckJointSSNForMatch({'leadId': this.props.leadId, 'jointSSN': this.props.model.ssn});
            if (!results) {
                this.setState({ ssnError: "Oops! Your social matches the other applicants. They cannot be the same." });
            }
        }

        if (!results) {
            return null;
        }

        //only check if primary applicant is already a member.
        if (this.props.isPrimaryMember && !this.props.fromReview) {
            var existingMemberNextPage = await CheckForExistingMember(this.props.frontEndModel).then(data => {
                return data;
            })
            //console.log(sendToAlreadyMember);
            if (existingMemberNextPage !==  '') {
                return existingMemberNextPage;
            }
        }

        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs

        //*********************************************************************************************************** */
        //TO--DO UNCOMMENT THIS AFTER UAT
        if (!this.props.fromReview && this.props.leadId === 0 && this.props.isPrimaryMember) {

            //See if there is an existing application using the Primary's SSN, if so send them to [Insert Page Name].  If not, carry on as usual.


            var sendToExisting = await CheckForExistingApplication(this.props.model).then(data => {
                if (data !== false) {
                    return true;
                } else {
                    //set something in appdata for the back button
                    return false;
                }

            })
                .catch((err) => {
                    console.log(err);
                });

        }


        if (sendToExisting) {
            return '/ExistingApplication';
        }


        if (this.props.fromReview) {
            this.props.toggleReviewModal();
            this.props.stateHandler("disableCitizenQuestion", true);
        } else {
            this.props.stateHandler("disableSSN", true);
            if (!this.props.isPrimaryMember) {
                return 'JointAddress';
            }
            return 'PrimaryAddress';
        }

    }

    resetErrors = () => {
        this.setState({
            firstNameError: '',
            lastNameError: '',
            dobError: '',
            countryError: '',
            ssnError: '',
            driverLicenseError: '',
            emailError: '',
            emailConfirmationError: '',
            primaryPhoneError: '',
            mobilePhoneError: '',
            alternatePhoneError: '',
            extensionError: '',
            backupWithholdingError: '',
            primaryPhoneIsMobileError: '',
            citizenError: ''
        });
    }

    setCountry = (shortValue) => {
        let index = CountryRegionData.findIndex(c => c[1] === shortValue);
        index = index <= -1 ? '' : index;
        this.props.stateHandler('country', shortValue);
        this.props.stateHandler('countryIndex', index + 1);
    };
}

export default withRouter(PersonInfo);