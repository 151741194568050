import React, { useState, useEffect } from 'react';
import { LeadText } from '../../Shared/LeadText';
import NavigationButtons from '../../Shared/NavigationButtons';
import { Row, Col, Button, Spinner } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import { CreateACHTransferRequest, PlaidError } from '../../../utilities/FetchUtilites';
import { withRouter } from 'react-router-dom';
import {
    usePlaidLink,
} from 'react-plaid-link';
import PlaidOptions from './Plaid/PlaidOptions';
import { GetPlaidToken, LinkPlaidAccount } from '../../../utilities/FetchUtilites';


const Verify = (props) => {
    const [verificationError, setVerificationError] = useState('');
    const [verificationSubTextError, setVerificationSubTextError] = useState('');
    const [isLoading, setLoadingState] = useState(true);
    const [plaidToken, setPlaidToken] = useState('');
    useEffect(() => {
        var returnedToken = GetPlaidToken(props.model)
            .then(res => {
                setPlaidToken(res.value.data);
                setLoadingState(false);
            });
    }, []);

    PlaidOptions.onSuccess = (public_token, metadata) => {
        var res = LinkPlaidAccount(public_token, metadata, props.model)
            .then(res => {
                let history = props.history;
                var screen = res.value.screen;
                props.onFieldName(res.value.accountNumber, "accountNumber");
                props.onFieldName(res.value.institutionName, "institutionName");
                props.onFieldName(res.value.routingNumber, "routingNumber");
                if (screen == '/Complete') {
                    CreateACHTransferRequest(props.model)
                        .then((data) => {
                            if (data.isSuccessful) {
                                return true
                            }
                            setVerificationError("Hmmm...something isn't quite right.");
                            return false;
                        })
                        .then(isSuccessful => {
                            if (isSuccessful) {
                                setLoadingState(false);
                                history.replace(screen);
                            }
                        })
                        .catch((errors) => {
                            setVerificationError("Hmmm...something isn't quite right.");
                            return null;
                        })
                }
                else {
                    setLoadingState(false);
                    history.push(screen);
                }
            });
    };
    PlaidOptions.onExit = (error, metadata) => {
        if (error == null) {
            setLoadingState(false);
            return;
        }

        PlaidError(props.model, error, metadata)
        
            .then(res => {
                setLoadingState(false);
                props.onChangeHandler("message", res.value.message);
                props.history.push('Method');
            });
    };
    PlaidOptions.token = plaidToken;
    const { open, exit, ready } = usePlaidLink(PlaidOptions);

    const openPlaid = () => {
        if (isLoading) {
            return;
        }
        setLoadingState(true);

        // open plaid link
        open();
    }
    return (
        <>
            <LeadText text="Okay, let's verify your external account!" subText="" ShowSaveForLater={false} />
            <Row>
                <Col xs={12} sm={12} className='mb-3'>
                    <p>We're about to redirect you to our trusted partner site, Plaid. Once there, you'll need to enter the 3-letter code associated with the small deposit you received. So please be sure to have that code handy!</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {verificationError !== '' &&
                        <FormFieldWrap id='VerificationErrorHolder' innerLabelText=''>
                            <p>
                                <span className='validation_message'>{verificationError}</span>
                                <br />
                                <span style={{ 'fontSize': '0.813em' }} >{verificationSubTextError}</span>
                            </p>
                        </FormFieldWrap>
                    }
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={{ size: 12, order: 2 }} sm={{ size: 4, order: 1 }} className="mt-2 mt-sm-0">
                    <Button type='button' id='btnOpen' block onClick={openPlaid} className="shine-green-btn">{isLoading ? <Spinner size="sm" /> : <>Get Started</>}</Button>
                </Col>
            </Row>
            <NavigationButtons setRouteTransition={props.setRouteTransition} ShowSaveForLater={false} showCancel={false} showBack={true} showNext={false} nextButtonText="Get Started" />
        </>
    );
};

export default withRouter(Verify);