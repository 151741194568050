import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PrimaryEmployementIncome } from './PrimaryEmployementIncome'
import { PersonIdentification } from '../../Shared/PersonIdentification';
import { IsJointApplication } from './IsJointApplication';
import { PrimaryIntro } from './PrimaryIntro';
import { Footer } from '../../Shared/Footer';
import { AddressForm } from '../../Shared/AddressForm';
import { Beneficiaries } from './Beneficiaries';
import '../../Shared/StepTransition.css';
import PersonInfo from '../../Shared/PersonInfo';
import NoLeadIdRedirect from '../../../components/Shared/NoLeadIdRedirect';

export default class Members extends Component {
    render() {
        const { match, handleState, model, setRouteTransition } = this.props;

        return (
            <>
                <NoLeadIdRedirect />
                <Switch>
                    <Route exact path={match.path}>
                        {({ history, match }) => (
                            <Redirect to={`${match.path}/PrimaryIntro`} />
                        )}
                    </Route>
                    <Route exact path={`${match.path}/PrimaryIntro`}>
                        {({ history, match }) => (
                            <>
                                <PrimaryIntro model={model.primaryIntro} setRouteTransition={setRouteTransition}
                                    stateHandler={(field, value) => handleState('primaryIntro', field, value)} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/PrimaryContact`}>
                        {({ history, match }) => (
                            <>
                                <PersonInfo model={model.primary} isPrimaryMember={true} fromReview={false} readOnly={model.appState.readonly}
                                    otherApplicantEmail={model.jointContact.primaryEmail} otherApplicantSSN={model.jointContact.ssn} primarySSNFilled={model.primary.ssn}
                                    stateHandler={(field, value) => handleState('primary', field, value)} setRouteTransition={setRouteTransition}
                                    leadId={model.leadId} frontEndModel={model} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/PrimaryAddress`}>
                        {({ history, match }) => (
                            <>
                                <AddressForm model={model.primaryAddress} isPrimaryMember={true} fromReview={false}
                                    handlePrimaryAddressIsJointAddressChange={this.props.handlePrimaryAddressIsJointAddressChange}
                                    primaryAddressShouldBeCopied={model.jointAddress.isJointAddrSameAsPrimary}
                                    stateHandler={(field, value) => handleState('primaryAddress', field, value)}
                                    readOnly={model.appState.readonly} setRouteTransition={setRouteTransition}
                                />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/PrimaryEmployment`}>
                        {({ history, match }) => (
                            <>
                                <PrimaryEmployementIncome model={model.primaryEmployment} fromReview={false}
                                    stateHandler={(field, value) => handleState('primaryEmployment', field, value)}
                                    readOnly={model.appState.readonly} setRouteTransition={setRouteTransition} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/PrimaryIdentification`}>
                        {({ history, match }) => (
                            <>
                                <PersonIdentification model={model.primaryIdentification} leadId={model.leadId} isPrimaryMember={true} fromReview={false}
                                    stateHandler={(field, value) => handleState('primaryIdentification', field, value)}
                                    readOnly={model.appState.readonly} setRouteTransition={setRouteTransition} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route path={`${match.path}/Beneficiaries`}>
                        {({ history, match }) => (
                            <>
                                <Beneficiaries model={model.beneficiaries} fromReview={false} readOnly={model.appState.readonly}
                                    stateHandler={(field, value) => handleState('beneficiaries', field, value)} setRouteTransition={setRouteTransition} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route path={`${match.path}/IsJointApplication`}>
                        {({ history, match }) => (
                            <>
                                <IsJointApplication model={model.isJointApplication} isJointApplication={model.isJointApplication.isJointApplication}
                                    stateHandler={(field, value) => handleState('isJointApplication', field, value)} setRouteTransition={setRouteTransition} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route path={`${match.path}/JointContact`}>
                        {({ history, match }) => (
                            <>
                                <PersonInfo model={model.jointContact} isPrimaryMember={false} fromReview={false}
                                    readOnly={model.appState.readonly} otherApplicantEmail={model.primary.primaryEmail}
                                    otherApplicantSSN={model.primary.ssn} jointSSNFilled={model.jointContact.ssn} leadId={model.leadId}
                                    stateHandler={(field, value) => handleState('jointContact', field, value)} setRouteTransition={setRouteTransition} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route path={`${match.path}/JointAddress`}>
                        {({ history, match }) => (
                            <>
                                <AddressForm model={model.jointAddress} isPrimaryMember={false} fromReview={false}
                                    handlePrimaryAddressIsJointAddressChange={this.props.handlePrimaryAddressIsJointAddressChange}
                                    primaryAddressShouldBeCopied={model.jointAddress.isJointAddrSameAsPrimary}
                                    primaryFirstName={model.primary.firstName}
                                    stateHandler={(field, value) => handleState('jointAddress', field, value)}
                                    readOnly={model.appState.readonly} setRouteTransition={setRouteTransition} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route path={`${match.path}/JointIdentification`}>
                        {({ history, match }) => (
                            <>
                                <PersonIdentification model={model.jointIdentification} leadId={model.jointLeadId} isPrimaryMember={false} fromReview={false}
                                    stateHandler={(field, value) => handleState('jointIdentification', field, value)} setRouteTransition={setRouteTransition} readOnly={model.appState.readonly} />
                                <Footer />
                            </>
                        )}
                    </Route>
                </Switch>
            </>
        )
    }

};