import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import '../../Shared/StepTransition.css';
import Amounts from './Amounts';
import { Footer } from '../../Shared/Footer';
import Method from './Method';
import CreditCard from './CreditCard';
import ACH from './ACH';
import MailCheck from './MailCheck';
import InPerson from './InPerson';
import CreateMicroDeposits from './CreateMicroDeposits';
import VerifyMicroDeposits from './VerifyMicroDeposits';
import Buffer from './Buffer';
import Verify from './Verify';
import NoLeadIdRedirect from '../../../components/Shared/NoLeadIdRedirect';

export default class Fund extends Component {
    render() {
        const { match, model, handleProductFundingChange, handleState, setRouteTransition, handleCCResponse } = this.props;

        return (
            <>
                <NoLeadIdRedirect />
                <Switch>
                    <Route exact path={match.path}>
                        {({ match, history }) => (
                            <Redirect to={`${match.path}/Method`} />
                        )}
                    </Route>
                    <Route exact path={`${match.path}/Amounts`}>
                        {({ history, match }) => (
                            <>
                                <Amounts selectedProductsArray={model.products.products}
                                    handleProductFundingChange={handleProductFundingChange}
                                    setRouteTransition={setRouteTransition}
                                    AfterACHVerification={false}
                                    model={model} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/Method`}>
                        {() => (
                            <>
                                <Method setRouteTransition={setRouteTransition}
                                    onChangeHandler={(value) => handleState('fundingMethod', 'method', value)}
                                    model={model}
                                />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/CreditCard`}>
                        {() => (
                            <>
                                <CreditCard setRouteTransition={setRouteTransition} model={model} handleResponse={handleCCResponse} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/ACH`}>
                        {() => (
                            <>
                                <ACH handleProductFundingChange={handleProductFundingChange}
                                    setRouteTransition={setRouteTransition}
                                    onChangeHandler={(value) => handleState('fundingMethod', 'message', value)}
                                    onFieldName={(value, fieldName) => handleState('fundingMethod', fieldName, value)}
                                    model={model} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/Buffer`}>
                        {() => (
                            <>
                                <Buffer setRouteTransition={setRouteTransition} model={model} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/CreateMicroDeposits`}>
                        {() => (
                            <>
                                <CreateMicroDeposits
                                    onChangeHandler={(field, value) => handleState('microDepositInfo', field, value)}
                                    model={model}
                                    setRouteTransition={setRouteTransition}
                                />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/VerifyMicroDeposits`}>
                        {() => (
                            <>
                                <VerifyMicroDeposits
                                    onChangeHandler={(field, value) => handleState('microDepositInfo', field, value)}
                                    model={model}
                                    setRouteTransition={setRouteTransition}
                                />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/Verify`}>
                        {() => (
                            <>
                                <Verify
                                    onChangeHandler={(field, value) => handleState('microDepositInfo', field, value)}
                                    onFieldName={(value, fieldName) => handleState('fundingMethod', fieldName, value)}
                                    model={model}
                                    setRouteTransition={setRouteTransition}
                                />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/ACHAmounts`}>
                        {() => (
                            <>
                                <Amounts selectedProductsArray={model.products.products}
                                    handleProductFundingChange={handleProductFundingChange}
                                    setRouteTransition={setRouteTransition}
                                    AfterACHVerification={true}
                                    model={model} />
                                <Footer />
                            </>
                        )}
                    </Route>

                    <Route exact path={`${match.path}/MailCheck`}>
                        {() => (
                            <>
                                <MailCheck leadId={model.leadId}
                                    setRouteTransition={setRouteTransition}
                                    handleProductFundingChange={handleProductFundingChange} />
                                <Footer />
                            </>
                        )}
                    </Route>
                    <Route exact path={`${match.path}/InPerson`}>
                        {() => (
                            <>
                                <InPerson model={model} setRouteTransition={setRouteTransition} onChangeHandler={(field, value) => handleState('fundInBranch', field, value)} />
                                <Footer />
                            </>
                        )}
                    </Route>
                </Switch>
            </>
        );
    }
}