
const InternalAppState = {
    currentPage : '/Eligibility/Choose',
    backButtonPage : '',
    routeTransition : 'moveRight',
    readonly: false,
    timedOutAppId : '',
    connectivityError : false,
    resumePage: '',
    ccResponse: '',
    primaryCase: '',
    jointCase: '',
    leadSourceId: ''
};

export default InternalAppState;