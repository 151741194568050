import React from 'react';
import { Alert, Row, Col, Button } from 'reactstrap';
import { LeadText } from '../Shared/LeadText';


const AlertMessage = ({
  alertColor = '',
  alertHeading = '',
  alertMessage = '',
  alertHyperlink = '',
  alertButtonVerbiage = '',
  handleOnClick,
  showButton = true
}) => {

    const style = {
        border: 'orange',
        borderStyle: 'solid',
        borderWidth: '2px'
    };

    const alertStyle = {
        marginBottom: '0',
        padding: '.75rem'
    };


    return (
        <div style={style} className="mb-3 mt-3">
            <Alert color={alertColor} style={alertStyle}>
                <LeadText text="" subText={alertHeading} />
                    {alertMessage}
                {showButton &&
                    <Row>
                    <Col xs={{ size: 12 }} sm={{ size: 4, offset: 8 }}>
                        <Button color="" type='button' block onClick={handleOnClick}
                            className="shine-green-btn">
                            {alertButtonVerbiage}
                        </Button>
                    </Col>
                </Row>}
            </Alert>
        </div>
    );
};

export default AlertMessage;

