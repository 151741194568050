import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { LeadText } from '../Shared/LeadText';
import { Footer } from '../Shared/Footer';

export class Abandoned extends Component {
    static displayName = 'Save for later';

    render() {

        return (
            <div>
                    <>
                        <LeadText text="Application Expired" subText="Sorry, your Dupaco membership application expired." ShowSaveForLater={false} />
                        <div>
                        <p>Welcome back! It looks like it's been 30 or more days since you last worked on your application. So, for your security, we had to delete it.</p>
                        <p>
                            We'd still love to have you as our newest member-owner. Click below and let's finish what we started!
                        </p>
                        </div>
                    </>

                <Row className="mb-3">
                    <Col xs={{ size: 12 }} sm={{ size: 4 }}>
                        <Button color="" type='button' block onClick={() => this.redirectToStart()}

                            className="shine-green-btn">
                           Start New Application
                            </Button>
                    </Col>
                </Row>
                <Footer />
            </div>
        );
    }

}

export default Abandoned;