import React, { Component } from 'react';
import { Row, Col, UncontrolledPopover, PopoverBody } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import NavigationButtons from '../../Shared/NavigationButtons';
import { LeadText } from '../../Shared/LeadText';
export class NoneApply extends Component {

    state = {
    }

    render() {
        return (
            <>
                <LeadText text="Let's make sure you're eligible for membership" subText="Sorry, it sounds like you don’t qualify right now." ShowSaveForLater={false} />
                <FormFieldWrap isRequired={false}>
                    <Row>
                        <Col xs="12" sm="12">
                            <p>We appreciate your interest, but you can't join Dupaco without meeting at least one of these eligibility requirements:</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="12">
                            <ul className='ml-4'>
                                <li>Live and/or work in Dupaco’s charter area&nbsp;<i id='workPop' className='mdi mdi-information-outline'><span className='sr-only'>More info</span></i>
                                    <UncontrolledPopover placement='top' target='workPop' trigger='legacy'>
                                        <PopoverBody>
                                            Dupaco's charter area includes any county
                                            in Iowa; Columbia, Crawford, Dane, Dodge, Grant, Green, Iowa, Jefferson, Lafayette, Richland, Rock, Sauk, Walworth, and Waukesha counties in Wisconsin; and
                                            JoDaviess, Stephenson, Carroll, Whiteside,
                                            and Rock Island counties in Illinois.
                                        </PopoverBody>
                                    </UncontrolledPopover></li>
                                <li>Work remotely for an employer headquartered in Dupaco's charter area&nbsp;<i id='livePop' className='mdi mdi-information-outline'><span className='sr-only'>More info</span></i>
                                    <UncontrolledPopover placement='top' target='livePop' trigger='legacy'>
                                        <PopoverBody>
                                            Headquartered is defined as your employer’s principal place of business.
                                            It must be located in Dupaco's charter area, which includes every county in
                                            Iowa; Columbia, Crawford, Dane, Dodge, Grant, Green, Iowa, Jefferson, Lafayette, Richland, Rock, Sauk, Walworth, and Waukesha counties in Wisconsin; and JoDaviess,
                                            Stephenson, Carroll, Whiteside and Rock Island counties in Illinois.
                                        </PopoverBody>
                                    </UncontrolledPopover></li>
                                <li>Be related to a current Dupaco member within the first or second degree&nbsp;<i id='relativePop' className='mdi mdi-information-outline'><span className='sr-only'>More info</span></i>
                                    <UncontrolledPopover placement='top' target='relativePop' trigger='legacy'>
                                        <PopoverBody>
                                            Immediate family includes parents, grandparents, children, grandchildren,
                                            sisters, brothers, in-laws, foster children, adopted children of current a member
                                            and such relatives of a deceased member.
                                        </PopoverBody>
                                    </UncontrolledPopover></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="12">
                            <p>If you already meet one of these requirements, please click <i>Back</i> and choose the appropriate eligibility statement.
                                If you don’t meet one of these requirements now, but do sometime in the future, please keep us in mind — we’d love
                                to welcome you as a new member-owner!</p>
                        </Col>
                    </Row>
                </FormFieldWrap>

                <FormFieldWrap isRequired={false}>
                    <NavigationButtons setRouteTransition={this.props.setRouteTransition} showBack={true} showNext={false} ShowSaveForLater={false} />
                </FormFieldWrap>
            </>
        );
    }

}