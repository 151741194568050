import React, { Component } from 'react';
import { LeadText } from '../Shared/LeadText';
import { Footer } from '../Shared/Footer';
import { Row, Col, Button } from 'reactstrap';

export class TimedOut extends Component {
    static displayName = 'Timed Out';

    state = {
        appId: ""
    }

    redirectToStart = () => {
        window.location.href = '/';
    }

    render() {

        return (
            <>
                <LeadText text={""} subText="Sorry, your session has expired." ShowSaveForLater={false} />
                {this.props.TimedOutAppId !== '' &&
                    <Row form>
                        <Col xs={12} sm={12}>
                            <p>Due to inactivity — and for your security — we had to expire your session. We saved all the information you entered so far and sent a secure link to your in-progress application to the email address you provided. Here’s what you need to know:</p>
                            <ul className="ml-4">
                                <li><strong>Your Application ID number is {this.props.TimedOutAppId}</strong><br />
                                    You'll need this number to continue your application. So be sure to save it for future reference.
                                </li>
                                <li><strong>Continuing your application</strong><br />
                                    Just click the link we sent you, then enter your Application ID number and identity verification information. Doing so will take you back to where you left off.
                                </li>
                                <li><strong>For your security </strong><br />
                                    The link to your in-progress application will expire after 10 days. If you cannot continue or complete it before then, the information you’ve entered will be lost.
                                </li>
                            </ul>
                            <p>Thanks again for your interest in joining Dupaco — we’re looking forward to welcoming you as our newest member-owner!  </p>
                            <Footer />
                        </Col>
                    </Row>
                }

                {this.props.TimedOutAppId === '' &&
                    <>
                    <Row form className="mb-3">
                            <Col xs={12} sm={12}>
                                <p>Due to inactivity — and for your security — we had to expire your session. Want to start a new application now? Click below. Otherwise, you can start one anytime at Dupaco.com. </p>
                                <p>Thanks for your interest in joining Dupaco. We hope to hear from you again soon!  </p>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={{ size: 12 }} sm={{ size: 4, offset: 8 }}>
                                <Button color="" type='button' block onClick={() => this.redirectToStart()}

                                    className="shine-green-btn">
                                    Restart Application
                            </Button>
                            </Col>
                        </Row>
                        <Footer />
                    </>
                }
            </>
        )
    }

}