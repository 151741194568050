import { object, string } from 'yup';

const AddressState = {
    streetAddress1: '',
    streetAddress2: '',
    zipCode: '',
    state: '',
    city: '',
    mailingAddress1: '',
    mailingAddress2: '',
    mailingZipCode: '',
    mailingState: '',
    mailingCity: '',
    isMailingAddress: '',
    isJointAddrSameAsPrimary: '',
    previousChecking: '',
    financialNames: '',
    previousCheckingClosed: '',
    previousCheckingClosedReason: '',
    convictedOfCriminalOffense: ''
};


export const AddressValidation = (context) => {
    let mainSchema = object().shape({
        streetAddress1: string().required(() => context.setState({ streetAddress1Error: 'Required' })).test('ddd', 'vvv',
            function (streetAddress) {
               
                if (streetAddress.toUpperCase().replace(/ /g, '').replace(/[^\w\s]/gi, '').includes("POBOX")) {
                    context.setState({ streetAddress1Error: 'Address cannot be a PO Box' });
                    return false;
                }
                return true;
            }),

        city: string().required(() => context.setState({ cityError: 'Required' })).max(25, () => context.setState({ cityError: 'City must be less than 26 characters' })),
        state: string().required(() => context.setState({ stateError: 'Required' })),
        zipCode: string()
        .test('mustBe5Chars', () => context.setState({zipCodeError:'Invalid Zip Code'}),
        function mustBe5Chars(value){
            var zipPattern = /^[0-9]{5}$/;
            return  zipPattern.test(value);
        })
        .required(() => context.setState({ zipCodeError: 'Required' })),
        isMailingAddress: string().required(() => context.setState({ isMailingAddressError: 'Required' }))
    });

    let isJointAddrSameAsPrimaryPartialSchema = object().shape({
        isJointAddrSameAsPrimary: string().required(() => context.setState({ isJointAddrSameAsPrimaryError: 'Required' }))
    });

    let mailAddressPartialSchema = object().shape({
        mailingAddress1: string().required(() => context.setState({ mailingAddress1Error: 'Required' })),
        mailingCity: string().required(() => context.setState({ mailingCityError: 'Required' })).max(25, () => context.setState({ mailingCityError: 'City must be less than 26 characters' })),
        mailingState: string().required(() => context.setState({ mailingStateError: 'Required' })),
        mailingZipCode: string()
        .test('mustBe5Chars', () => context.setState({mailingZipCodeError:'Invalid Zip Code'}),
        function mustBe5Chars(value){
            var zipPattern = /^[0-9]{5}$/;
            return  zipPattern.test(value);
        })
        .required(() => context.setState({ mailingZipCodeError: 'Required' }))
    });

    let minnesotaSchema = object().shape({
        previousChecking: string().required(() => context.setState({ previousCheckingError: 'Required' })),
        previousCheckingClosed: string().required(() => context.setState({ previousCheckingClosedError: 'Required' })),
        convictedOfCriminalOffense: string().required(() => context.setState({ convictedOfCriminalOffenseError: 'Required' }))
    });

    let previousCheckingSchema = object().shape({
        financialNames: string().required(() => context.setState({ financialNamesError: 'Required' }))
    });

    let previousCheckingClosedSchema = object().shape({
        previousCheckingClosedReason: string().required(() => context.setState({ previousCheckingClosedReasonError: 'Required' }))
    });

    if (context.props.model.previousChecking === 'true') {
        minnesotaSchema = minnesotaSchema.concat(previousCheckingSchema);
    }


    if (context.props.model.previousCheckingClosed === 'true') {
        minnesotaSchema = minnesotaSchema.concat(previousCheckingClosedSchema);
    }


    if (context.props.model.state === 'MN') {
        mainSchema = mainSchema.concat(minnesotaSchema);
    }

    if (context.props.model.isMailingAddress === 'false') {
        mainSchema = mainSchema.concat(mailAddressPartialSchema);
    }

    if (context.props.isPrimaryMember !== true) {
        mainSchema = mainSchema.concat(isJointAddrSameAsPrimaryPartialSchema);
    }


    return mainSchema;
};


export default AddressState;