import { handleErrors } from "./HandleErrors";

//it is similar like here: https://www.w3schools.com/js/js_cookies.asp
const getCookie = (name) => {
    if (!document.cookie) {
        return null;
    }
    const csrfCookies = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='));
    if (csrfCookies.length === 0) {
        return null;
    }
    return decodeURIComponent(csrfCookies[0].split('=')[1]);
}

export const GetProducts = (refer) => {
    const products = sessionStorage.getItem('dupacoProducts');
    if (products !== null) {
        return new Promise((resolve, reject) => {
            return resolve(JSON.parse(products));
        })
    }

    if (refer) {
        return fetch('api/Products?refer=' + refer)
        .then(handleErrors)
        .then(response => response.json());
    }

    return fetch('api/Products')
        .then(handleErrors)
        .then(response => response.json());
};

export const GetGeoCode = (address, zipCode) => {
    return fetch('api/GeoCode?address=' + address + '&zipCode=' + zipCode)
        .then(handleErrors)
        .then(response => response.json());
};


export const GetApplication = (model) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch('api/AppData/Resume', getRequestObject(model))
            .then(handleErrors)
            .then(response => response.json());
    });
};

export const CheckForExistingApplication = (model) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch('api/AppData/CheckForExistingBySSN', getRequestObject(model))
            .then(handleErrors)
            .then(response => response.json());
    });
};


export const GetIDVKey = () => {
    return fetch('api/AppData/IDVKey')
        .then(handleErrors)
        .then(response => response.json());
};


export const CheckForExistingMember = (model) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch('api/Member/CheckForExistingMemberBySSN', getRequestObject(model))
            .then(handleErrors)
            .then(response => response.json());
    });
};

export const CheckJointSSNForMatch = (model) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch('api/AppData/CheckJointSSNForMatch', getRequestObject(model))
            .then(handleErrors)
            .then(response => response.json());
    });
};

export const GetAppNumber = (model) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch('api/AppData/ForgotAppNumber', getRequestObject(model))
            .then(handleErrors)
            .then(response => response.json());
    });
};

export const SaveForLaterUpsert = (MasterState) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/AppData/SendSaveForLaterEmailUpsert`, getRequestObject(MasterState))
            .then(handleErrors)
            .then(response => response.json());
    })
}

export const SendCancelEmailAndUpsert = (MasterState) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/AppData/SendCancelEmailUpsert`, getRequestObject(MasterState))
            .then(handleErrors)
            .then(response => response.json());
    })
}

const getRequestObject = (data) => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            "X-XSRF-TOKEN": getCookie("XSRF-TOKEN")
        },
        credentials: 'include',
        method: 'Post',
        body: JSON.stringify(data)
    }
}

export const Upsert = (MasterState) => {

    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/AppData/UpdateDB`, getRequestObject(MasterState))
            .then(handleErrors)
            .then(response => response.json())
    });
}

export const ReviewUpsert = (MasterState) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/AppData/ReviewUpdateDB`, getRequestObject(MasterState))
            .then(handleErrors)
            .then(response => response.json());
    });
}

export const CheckPromoCode = (MasterState) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch('api/PromoCodes', getRequestObject(MasterState))
            .then(handleErrors)
            .then((response) => response.json());
    })
};

export const CreateAccountAgreements = (MasterState) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/AccountAgreements/Create`, getRequestObject(MasterState))
            .then(handleErrors)
            .then(response => response.json());
    });
}

export const GetLocalSignerStatus = (docusignClientId,) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch('api/AccountAgreements/GetLocalSignerStatus?docusignClientId=' + docusignClientId)
            .then(handleErrors)
            .then(response => response.json());
    })
}

export const CreateMicroDepositsRequest = (MasterState) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/MicroDeposit/Create`, getRequestObject(MasterState))
            .then(handleErrors)
            .then(response => response.json());
    });
}

export const VerifyMicroDepositsRequest = (MasterState) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/MicroDeposit/Verify`, getRequestObject(MasterState))
            .then(handleErrors)
            .then(response => response.json());
    });
}

export const CreateACHTransferRequest = (MasterState) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/MicroDeposit/CreateFullTransfer`, getRequestObject(MasterState))
            .then(handleErrors)
            .then(response => response.json());
    });
}

export const UploadIDImagesToCRMNEXT = (model) => {
    return fetch(`api/AppData/UploadIDImage`, getRequestObject(model))
        .then(handleErrors)
        .then(response => response.json());
}

export const CheckIDV = (MasterState, sessionId) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/IDV/${sessionId}`, getRequestObject(MasterState))
            .then(handleErrors)
            .then(response => response.json());
    })
}

export const CreateMember = (MasterState) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/Member/Create`, getRequestObject(MasterState))
            .then(handleErrors)
            .then(response => response.json());
    })
}

export const FundViaCreditCard = (CreditCardInfoWithFrontEndModel) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch('api/CreditCardPayment/Create', getRequestObject(CreditCardInfoWithFrontEndModel))
            .then(handleErrors)
            .then(response => response.json());
    });
}

export const UnrestrictAccounts = async (requestBody) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/Member/Unrestrict`, getRequestObject(requestBody))
            .then(handleErrors)
            .then(async response => await response.json());
    });
}

const GetAntiforgery = () => {
    return fetch(`api/AppData`)
        .then(handleErrors)
        .then(async response => await response.json());
};

const CheckAntiforgery = () => {
    return fetch(`api/AppData/CheckToken`, getRequestObject(null))
        .then(response => response.json());
};



export const GetAccountAgreementSignerURL = (envelopeId, clientId) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/AccountAgreements/GetAccountAgreementSignerURL`, getRequestObject({ "EnvelopeId": envelopeId, "ClientId": clientId }))
            .then((data) => { return handleErrors(data) })
            .then(response => response.json());
    });
}

export const LinkPlaidAccount = (public_token, metadata, frontEndModel) => {
    return fetch(`api/MicroDeposit/VerifyExternalAccount`, getRequestObject({ "PublicToken": public_token, "accountMetaData": metadata, "model": frontEndModel }))
        .then((data) => { return handleErrors(data) })
        .then(response => response.json());
}

export const PlaidBalanceCheck = (frontEndModel) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/MicroDeposit/PlaidBalanceCheckProccess`, getRequestObject(frontEndModel))
            .then((data) => { return handleErrors(data) })
            .then(response => response.json());
    });
}

export const PlaidError = (frontEndModel, err, metadata) => {
    return fetch(`api/MicroDeposit/LinkError`, getRequestObject({ "error": err, "metaData": metadata, "model": frontEndModel }))
        .then((data) => { return handleErrors(data) })
        .then(response => response.json());
}

export const GetPlaidToken = (MasterState) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/MicroDeposit/GetLinkToken`, getRequestObject(MasterState))
            .then((data) => { return handleErrors(data) })
            .then(response => response.json());
    });
}

export const GetReferedEmailAddressFromGuid = (referGuid) => {
    return fetch('api/AppData/GetEmailByReferralGuid?referGuid=' + referGuid)
        .then(handleErrors)
        .then(response => response.json());
};

export const CompleteProcess = (leadId) => {
    return CheckAntiforgery().then(res => {
        if (res.Message !== undefined) {
            return GetAntiforgery()
                .then(res => {
                    return window.document.cookie = res.tokenName + "=" + res.token;
                })
        }
    }).then(() => {
        return fetch(`api/AppData/CompleteProcess/${leadId}`, getRequestObject({}))
            .then(handleErrors)
            .then(async response => await response.json());
    });
}