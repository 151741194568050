import React, { Component } from 'react';
import { Row, Col, Form, Input, Card, CardBody, Button } from 'reactstrap';
import FormFieldWrap from './FormFieldWrap';
import NavigationButtons from './NavigationButtons';
import { AddressValidation } from '../../StateModels/AddressState';
import { GetGeoCode } from '../../utilities/FetchUtilites';
import { LeadText } from './LeadText';
import RadioButtonList from './RadioButtonList'
import States from './States';
import InputMask from 'react-input-mask';
import { maxLength } from '../../utilities/StringUtilities'

export class AddressForm extends Component {


    state = {
        streetAddress1Error: '',
        streetAddress2Error: '',
        zipCodeError: '',
        stateError: '',
        cityError: '',
        mailingAddress1Error: '',
        mailingAddress2Error: '',
        mailingZipCodeError: '',
        mailingStateError: '',
        mailingCityError: '',
        isMailingAddressError: '',
        isJointAddrSameAsPrimaryError: '',
        previousCheckingError: '',
        financialNamesError: '',
        previousCheckingClosedError: '',
        previousCheckingClosedReasonError: '',
        convictedOfCriminalOffenseError: ''

    }

    componentDidMount() {
        if (!this.props.fromReview) {
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        if (this.props.primaryAddressShouldBeCopied === 'true') {
            this.props.handlePrimaryAddressIsJointAddressChange("true");
        }
    }

    render() {
        const { model } = this.props;
        return (
            <>
                {!this.props.fromReview &&
                    <LeadText text={this.props.isPrimaryMember ? "About you (2 of 5)" : "About your joint account owner (2 of 3)"}
                        subText="Primary Address" ShowSaveForLater={true}
                    />
                }

                {this.props.fromReview &&
                    <LeadText text={""} subText="Primary Address" ShowSaveForLater={false} />
                }

                <fieldset disabled={this.props.readOnly}>
                    {!this.props.isPrimaryMember &&
                        <Row form>
                            <Col xs={12} sm={12}>
                                <FormFieldWrap id='isJointAddrSameAsPrimary' innerLabelText={'Is your primary address the same as ' + this.props.primaryFirstName + '\'s?'} errorMessage={this.state.isJointAddrSameAsPrimaryError}>
                                    < RadioButtonList id='isJointAddrSameAsPrimary'
                                        options={[{
                                            id: "yesJointAddrSameAsPrimary",
                                            checked: model.isJointAddrSameAsPrimary === 'true',
                                            labelText: 'Yes',
                                            handleOnChange: this.handleIsJointAddressSameAsPrimary,
                                            value: 'true' //this value is tightly coupled with label below and assocated model
                                        },
                                        {
                                            id: "noJointAddrSameAsPrimary",
                                            checked: model.isJointAddrSameAsPrimary === 'false',
                                            labelText: 'No',
                                            handleOnChange: this.handleIsJointAddressSameAsPrimary,
                                            value: 'false' //this value is tightly coupled with label below and assocated model
                                        }]} />
                                </FormFieldWrap>
                            </Col>
                        </Row>}

                    {(this.props.isPrimaryMember || model.isJointAddrSameAsPrimary === 'false') && <Form id='primaryAddressForm' >
                        <Row form>
                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='streetAddress1' innerLabelText='Street number & name' errorMessage={this.state.streetAddress1Error}>
                                    <Input type='text' name='streetAddress1' id='streetAddress1' value={this.props.model.streetAddress1} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                                    <span className="subfooter">Note: Primary address cannot be a P.O. box</span><br />
                                </FormFieldWrap>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='streetAddress2' isRequired={false} innerLabelText='Apartment, unit, floor, etc.'>
                                    <Input type='text' name='streetAddress2' id='streetAddress2' value={this.props.model.streetAddress2} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                                </FormFieldWrap>
                            </Col>
                        </Row>
                        <Row form>
                            <Col xs={12} sm={4}>
                                <FormFieldWrap id='zipCode' innerLabelText='Zip Code' errorMessage={this.state.zipCodeError}>
                                    <InputMask mask="99999" maskChar=' '
                                        value={this.props.model.zipCode}
                                        onChange={this.onChangeHandler}
                                        onBlur={this.onBlur.bind(this)}
                                        name='zipCode'
                                        id='zipCode'
                                    >
                                        {(inputProps) => <Input type='tel' {...inputProps} />}
                                    </InputMask>
                                </FormFieldWrap>
                            </Col>
                            <Col xs={12} sm={4}>
                                <FormFieldWrap id='city' innerLabelText='City' errorMessage={this.state.cityError}>
                                    <Input type='text' name='city' id='city' value={this.props.model.city} onChange={(e) => { e.target.value = maxLength(e.target.value, 25); this.onChangeHandler(e) }} onBlur={this.onBlur.bind(this)} />
                                </FormFieldWrap>
                            </Col>
                            <Col xs={12} sm={4}>
                                <FormFieldWrap id='state' innerLabelText='State' errorMessage={this.state.stateError}>
                                    <States id='state'
                                        name='state'
                                        value={this.props.model.state}
                                        handleOnChange={this.onChangeHandler}
                                        handleOnBlur={this.onBlur.bind(this)}
                                        fromReview={this.props.fromReview}
                                        isPrimaryMember={this.props.isPrimaryMember} />
                                </FormFieldWrap>
                            </Col>
                        </Row>
                    </Form>
                    }

                    <Row form id='minnesotaQuestions' className={model.state === 'MN' ? '' : 'd-none'}>
                        <Col xs={12} sm={12}>
                            <Card>
                                <CardBody>
                                    <p className="duorange">It looks like your primary address is located in Minnesota. As such, Minnesota state law requires us to gather information about your checking/share draft account history (including money market account types):</p>
                                    <Row form>
                                        <Col xs={12} sm={12}>
                                            <FormFieldWrap id='previousChecking' innerLabelText='During the last 12 months, have you had a checking/share draft account at a financial institution?' errorMessage={this.state.previousCheckingError}>
                                                <RadioButtonList id='previousChecking'
                                                    options={[{
                                                        id: "yesPreviousChecking",
                                                        checked: model.previousChecking === 'true',
                                                        labelText: 'Yes',
                                                        handleOnChange: this.onChangeHandler,
                                                        value: 'true' //this value is tightly coupled with label below and assocated model
                                                    },
                                                    {
                                                        id: "noPreviousChecking",
                                                        checked: model.previousChecking === 'false',
                                                        labelText: 'No',
                                                        handleOnChange: this.onChangeHandler,
                                                        value: 'false' //this value is tightly coupled with label below and assocated model
                                                    }]} />
                                            </FormFieldWrap>

                                            <FormFieldWrap id='financialNames' innerLabelText='Which financial institution(s)?' errorMessage={this.state.financialNamesError} className={model.previousChecking === 'true' ? '' : 'd-none'}>
                                                <Input type='text' name='financialNames' id='financialNames' value={this.props.model.financialNames} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                                            </FormFieldWrap>

                                        </Col>
                                        <Col xs={12} sm={12}>
                                            <FormFieldWrap id='previousCheckingClosed' innerLabelText='During the last 12 months, has a financial institution closed your checking/ share draft account without your consent?' errorMessage={this.state.previousCheckingClosedError}>
                                                <RadioButtonList id='previousCheckingClosed'
                                                    options={[{
                                                        id: "YesPreviousCheckingClosed",
                                                        checked: model.previousCheckingClosed === 'true',
                                                        labelText: 'Yes',
                                                        handleOnChange: this.onChangeHandler,
                                                        value: 'true' //this value is tightly coupled with label below and assocated model
                                                    },
                                                    {
                                                        id: "NoPreviousCheckingClosed",
                                                        checked: model.previousCheckingClosed === 'false',
                                                        labelText: 'No',
                                                        handleOnChange: this.onChangeHandler,
                                                        value: 'false' //this value is tightly coupled with label below and assocated model
                                                    }]} />
                                            </FormFieldWrap>
                                            <FormFieldWrap id='previousCheckingClosedReason' innerLabelText='What was the reason given?' errorMessage={this.state.previousCheckingClosedReasonError} className={model.previousCheckingClosed === 'true' ? '' : 'd-none'}>
                                                <Input type='text' name='previousCheckingClosedReason' id='previousCheckingClosedReason' value={this.props.model.previousCheckingClosedReason} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                                            </FormFieldWrap>
                                        </Col>
                                        <Col xs={12} sm={12}>
                                            <FormFieldWrap id='convictedOfCriminalOffense' innerLabelText='During the last 24 months, have you been convicted of a criminal offense involving the use of a check/share draft account or similar financial instrument?' errorMessage={this.state.convictedOfCriminalOffenseError}>
                                                <RadioButtonList id='convictedOfCriminalOffense'
                                                    options={[{
                                                        id: "yesConvictedOfCriminalOffense",
                                                        checked: model.convictedOfCriminalOffense === 'true',
                                                        labelText: 'Yes',
                                                        handleOnChange: this.onChangeHandler,
                                                        value: 'true' //this value is tightly coupled with label below and assocated model
                                                    },
                                                    {
                                                        id: "noConvictedOfCriminalOffense",
                                                        checked: model.convictedOfCriminalOffense === 'false',
                                                        labelText: 'No',
                                                        handleOnChange: this.onChangeHandler,
                                                        value: 'false' //this value is tightly coupled with label below and assocated model
                                                    }]} />
                                            </FormFieldWrap>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <br />
                        </Col>
                    </Row>

                    <Row form>
                        <Col xs={12} sm={12}>
                            <FormFieldWrap id='isMailingAddress' innerLabelText='Is your primary address your mailing address, too?' errorMessage={this.state.isMailingAddressError}>
                                <RadioButtonList id='isMailingAddress'
                                    options={[{
                                        id: "yesMailingAddress",
                                        checked: model.isMailingAddress === 'true',
                                        labelText: 'Yes',
                                        handleOnChange: this.onChangeHandler,
                                        value: 'true' //this value is tightly coupled with label below and assocated model
                                    },
                                    {
                                        id: "noMailingAddress",
                                        checked: model.isMailingAddress === 'false',
                                        labelText: 'No',
                                        handleOnChange: this.onChangeHandler,
                                        value: 'false' //this value is tightly coupled with label below and assocated model
                                    }]} />
                            </FormFieldWrap>
                        </Col>
                    </Row>

                    {(model.isMailingAddress === 'false') && <Form id='mailingAddressForm' >
                        {/* <Form id='mailingAddressForm' className={model.isMailingAddress === 'false' ? '' : 'd-none'}> */}
                        <Row form>
                            <Col xs={12} sm={12}>
                                <p><strong>Please provide your mailing address:</strong></p>
                            </Col>
                        </Row>
                        <Row form>
                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='mailingAddress1' innerLabelText='Street number & name/P.O. box' errorMessage={this.state.mailingAddress1Error}>
                                    <Input type='text' name='mailingAddress1' id='mailingAddress1' value={this.props.model.mailingAddress1} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                                </FormFieldWrap>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormFieldWrap id='mailingAddress2' isRequired={false} innerLabelText='Apartment, unit, floor, etc.'>
                                    <Input type='text' name='mailingAddress2' id='mailingAddress2' value={this.props.model.mailingAddress2} onChange={this.onChangeHandler} onBlur={this.onBlur.bind(this)} />
                                </FormFieldWrap>
                            </Col>
                        </Row>
                        <Row form>
                            <Col xs={12} sm={4}>
                                <FormFieldWrap id='mailingZipCode' innerLabelText='Zip Code' errorMessage={this.state.mailingZipCodeError}>
                                    <InputMask mask="99999" maskChar=' '
                                        value={this.props.model.mailingZipCode}
                                        onChange={this.onChangeHandler}
                                        onBlur={this.onBlur.bind(this)}
                                        name='mailingZipCode'
                                        id='mailingZipCode'
                                    >
                                        {(inputProps) => <Input type='tel' {...inputProps} />}
                                    </InputMask>
                                </FormFieldWrap>
                            </Col>
                            <Col xs={12} sm={4}>
                                <FormFieldWrap id='mailingCity' innerLabelText='City' errorMessage={this.state.mailingCityError}>
                                    <Input type='text' name='mailingCity' id='mailingCity' value={this.props.model.mailingCity} onChange={(e) => { e.target.value = maxLength(e.target.value, 25); this.onChangeHandler(e) }} onBlur={this.onBlur.bind(this)} />
                                </FormFieldWrap>
                            </Col>
                            <Col xs={12} sm={4}>
                                <FormFieldWrap id='mailingState' innerLabelText='State' errorMessage={this.state.mailingStateError}>
                                    <States id='mailingState' name='mailingState' value={this.props.model.mailingState} handleOnChange={this.onChangeHandler} handleOnBlur={this.onBlur.bind(this)} />
                                </FormFieldWrap>
                            </Col>
                        </Row>
                    </Form>
                    }
                </fieldset>

                {!this.props.fromReview &&
                    <NavigationButtons setRouteTransition={this.props.setRouteTransition} onNext={this.onNext.bind(this)} />
                }

                {this.props.fromReview &&
                    <Row>
                        <Col xs={{ size: 12 }} sm={{ size: 4, offset: 8 }}>
                            <Button type='button' block className='shine-green-btn' setRouteTransition={this.props.setRouteTransition} onClick={this.onNext.bind(this)}>Save</Button>
                        </Col>
                    </Row>
                }

            </>
        );
    }

    // This function is handed an event object as it is called because of an event handler (usually onChange)
    // We then pass the name and value of the target being updated to the stateHandler from App.js
    // THIS IS DONE DIFFERENTLY FOR ANY DATE PICKERS
    // Search for the 'dob' date picker on this page to see what I mean
    onChangeHandler = (e) => {
        const nameOfField = e.target.name;
        let valueOfField = e.target.value;
        const typeOfField = e.target.type;
        if (typeOfField === 'checkbox') {
            valueOfField = e.target.checked;
        }
        this.props.stateHandler(nameOfField, valueOfField);
    }

    handleIsJointAddressSameAsPrimary = (e) => {
        this.props.handlePrimaryAddressIsJointAddressChange(e.target.value);
    }

    onNext = async (ush) => {
        this.resetErrors();

        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        const results = await AddressValidation(this).validate(this.props.model, {
            recursive: true,
            abortEarly: false
        }).then(() => {
            return true;
        })
            .catch((validationErrors) => {
                window.scrollTo(0, 0);
                return false;
            });

        if (results) {
            if (this.props.fromReview) {
                this.props.toggleReviewModal();
            } else {
                this.props.stateHandler("disableSSN", true);
                if (!this.props.isPrimaryMember) {
                    return 'JointIdentification';
                }
                return 'PrimaryEmployment';
            }
        }

        return null;
    }

    resetErrors = () => {
        this.setState({
            streetAddress1Error: '',
            zipCodeError: '',
            stateError: '',
            cityError: '',
            mailingAddress1Error: '',
            mailingZipCodeError: '',
            mailingStateError: '',
            mailingCityError: '',
            isMailingAddressError: '',
            isJointAddrSameAsPrimaryError: '',
            previousCheckingError: '',
            previousCheckingClosedError: '',
            previousCheckingClosedReasonError: '',
            convictedOfCriminalOffenseError: ''
        });
    }

    // Don't worry about this for now.
    // If we want inline validation we can add that later
    onBlur = (e) => {
        if (e.target.name === 'zipCode' && e.target.value.trim() !== '') {
            GetGeoCode(this.props.model.streetAddress1, e.target.value)
                .then(data => {
                    this.props.stateHandler('city', data.city);
                    this.props.stateHandler('state', data.state);
                })
                .catch((err) => {
                    console.log(err);
                });

        }
        if (e.target.name === 'mailingZipCode' && e.target.value.trim() !== '') {
            GetGeoCode(this.props.model.mailingAddress1, e.target.value)
                .then(data => {
                    this.props.stateHandler('mailingCity', data.city);
                    this.props.stateHandler('mailingState', data.state);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
}