import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { LeadText } from "../../Shared/LeadText";
import { withRouter } from "react-router-dom";
import NavigationButtons from "../../Shared/NavigationButtons";
import queryString from "query-string";

export class Buffer extends Component {
  handleNextClick = () => {
    return "VerifyMicroDeposits";
  };

  render() {
    let params = queryString.parse(this.props.location.search);
    if (params.t && params.t == "auto") {
      return (
        <>
          <LeadText
            text=""
            subText="Verification in progess"
            ShowSaveForLater={false}
          />

          <Row>
            <Col>
              <p>
                Check back soon! This process usually takes 1-2 business days.
                <br />
                <br />
                Once the process is complete, you will see an option to initiate
                a transfer from your external account. In the meantime, we sent
                an email to {this.props.model.primary.primaryEmail} with
                instructions on how to return to your membership application.
                <br />
                <br />
                Hint: If this process takes more than 3 business days, please
                call us at 800-373-7600, ext. 0 so we can investigate.
                <br />
                <br />
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={{ size: 12, order: 1 }} sm={{ size: 4}}>
              <button
                id="ShineLogin"
                name="ShineLogin"
                onClick={() => {
                  window.location.href = "https://www.dupaco.com";
                }}
                className="btn btn-block shine-green-btn"
              >
                Sounds Good
              </button>
            </Col>
          </Row>
        </>
      );
    }
    return (
      <>
        <LeadText
          text=""
          subText="Get ready... Your external account will be ready soon!"
          ShowSaveForLater={false}
        />
        <Row>
          <Col>
            <p>
              Before you can transfer funds from your external account, you'll
              need to verify ownership.
              <br />
              <br />
              Soon you'll receive a small deposit to your external account. The
              transaction should show in your external account within 1-2
              business days. When it does, make note of the 3-letter code within
              the transaction description and head back to your in-progress
              Dupaco membership application to verify it.
              <br />
              <br />
              Hint: We sent an email to {
                this.props.model.primary.primaryEmail
              }{" "}
              with instructions on how to return to your application.
              <br />
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={{ size: 12, order: 1 }} sm={{ size: 4}}>
            <button
              id="ShineLogin"
              name="ShineLogin"
              onClick={() => {
                window.location.href = "https://www.dupaco.com";
              }}
              className="btn btn-block shine-green-btn"
            >
              Sounds Good
            </button>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Buffer);
