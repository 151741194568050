import React from 'react';



const DupacoHeader = () => {

    const areYouSureYouWantToGoToDupacoComConfirmation = (event) => {
        if (!window.confirm("Are you sure you want to go to Dupaco.com?")) {
            event.preventDefault();
        }
    }
    return (
        <div style={{ background: "#fff" }}>
            <div className="container reducedLGContainer desktop-header d-none d-lg-flex pl-0">
                <div className="home_logo">
                    <img src="https://m.dupaco.com/external/Styles/images/dupaco-logo.png" alt="Dupaco logo" className="desktop-logo" />
                </div>
                <div className="top-right-menu">
                    <a href="https://www.dupaco.com" onClick={areYouSureYouWantToGoToDupacoComConfirmation}>Back to Dupaco.com</a>
                </div>
            </div>
            <div className="mobile-header d-flex d-md-none">
                <div className="menu-icon-container">
                </div>
                <div className="logo-container">
                    <img src="https://m.dupaco.com/external/Styles/images/dupaco-logo-mobile.png" alt="Shine Online Banking" className="dupaco-logo" />
                </div>
                <div className="right-menu-icon-container" >
                    <a href="https://www.dupaco.com" onClick={areYouSureYouWantToGoToDupacoComConfirmation}>
                        <span className="bt-stack bt-2xx duorange">
                            <i className="btl bt-circle bt-stack-1x"></i>
                            <i className="btr bt-home bt-stack-sm"></i>
                        </span>
                    </a>
                </div>
            </div>
            <div className="top-bar">
            </div>
            <br />
        </div>
    );
};




export default DupacoHeader;

