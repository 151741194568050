// import React from 'react';
import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Label, Card, CardBody, Modal, ModalBody } from 'reactstrap';
import { LeadText } from '../../Shared/LeadText';
import NavigationButtons from '../../Shared/NavigationButtons';
import '../../Shared/CSSFiles/Review.css';
import PersonInfo from '../../Shared/PersonInfo';
import { AddressForm } from '../../Shared/AddressForm';
import { PrimaryEmployementIncome } from '../Members/PrimaryEmployementIncome';
import { PersonIdentification } from '../../Shared/PersonIdentification';
import { Beneficiaries } from '../Members/Beneficiaries';
import occupations from '../../../utilities/Occupations';
import allStates from '../../../utilities/States.json';
import { ProductList } from '../../../components/Views/Products/ProductList';
import { GetProducts } from '../../../utilities/FetchUtilites';
//import { CountryRegionData } from 'react-country-region-selector';

const Review = (props) => {

    const lockDownFields = async () => {
        props.lockDownFields();
        return 'Processing';
    }

    const [products, setProducts] = useState([]);

    useEffect(() => { GetProducts().then((data) => { setProducts(data.products) }) }, []);

    const toggleReviewModal = () => {
        //if modal is open IE showModal === true then set page to display to empty string
        if (showModal === true) {
            setPageToDisplay('');
        }
        setModal(!showModal);

    };

    const [showModal, setModal] = useState(false);
    const [pageToDisplay, setPageToDisplay] = useState('');

    const renderEmploymentStatusSwitch = (employmentStatus) => {
        switch (employmentStatus) {
            case '1':
                return 'Employed';
            case '2':
                return 'Active Military';
            case '3':
                return 'Retired';
            case '4':
                return 'Self-Employed';
            case '5':
                return 'Unemployed';
            default:
                return 'Employed';
        }
    }

    const renderOccupation = (occupation, occupations) => {
        for (let i = 0; i < occupations.length; i++) {
            if (occupation === occupations[i].value) {
                return occupations[i].text;
            }
        }
    }

    const renderState = (state, allStates) => {
        for (let i = 0; i < allStates.items.length; i++) {
            if (state === allStates.items[i].abbreviation) {
                return allStates.items[i].name;
            }
        }
    }

    const renderMilitaryBranchSwitch = (militaryBranch) => {
        switch (militaryBranch) {
            case '1':
                return 'Air Force & Air Force Reserve';
            case '2':
                return 'Air National Guard';
            case '3':
                return 'Army & Army Reserve';
            case '4':
                return 'Army National Guard';
            case '5':
                return 'Coast Guard & Coast Guard Reserve';

            case '6':
                return 'Marine Corps & Marine Corps Reserve';
            case '7':
                return 'Navy & Navy Reserve';
            default:
                return 'Military';
        }
    }

    const productIdInSelectedProduct = (productID, selectedProducts) => {
        for (let i = 0; i < selectedProducts.length; i++) {
            if (productID === selectedProducts[i].productID) {
                return true;
            }
        }
        return false;
    }

    const renderIdentificationTypeSwitch = (identificationType) => {
        switch (identificationType) {
            case '2':
                return 'U.S. Driver\'s License';
            case '3':
                return 'U.S. State-Issued ID';
            case '4':
                return 'Passport with Photo';
            case '5':
                return 'U.S. Military Photo ID';
            default:
                return 'U.S. Driver\'s License';
        }
    }

    const getIdentifcationNumberLabel = () => {
        if (props.model.primaryIdentification.identificationType === '2') {
            return "LICENSE NUMBER"
        }
        if (props.model.primaryIdentification.identificationType === '3') {
            return "STATE ID NUMBER"
        }
        if (props.model.primaryIdentification.identificationType === '4') {
            return "PASSPORT NUMBER"
        }
        if (props.model.primaryIdentification.identificationType === '5') {
            return "MILITARY ID NUMBER"
        }
        if (props.model.primaryIdentification.identificationType === '') {
            return "NUMBER"
        }
    }

    //comment out to avoid warning
    // // Not needed at this time
    // const getCountry = (value) => {
    //     const countrySelected = CountryRegionData.filter(country => {
    //         return country[1] === value;
    //     })
    //     if (countrySelected.length > 0) {
    //         return countrySelected[0][0];
    //     }
    //     else {
    //         return 'United States';
    //     }
    // }
    
    return (
        <>
            <fieldset disabled={props.readOnly}>
                <LeadText text="Account Setup (3 of 4)" subText="Review & Confirm" ShowSaveForLater={true} />
                <p>Let’s make sure everything is correct. Please take a moment to review the information you’ve entered.
                Be sure to fix anything that doesn’t look quite right.</p>

                <Row className="mb-3">
                    <Col>
                        <Card className="border-2">
                            <CardBody className="pt-3 margin-bottom-neg-15">
                                <Row className="d-flex justify-content-between margin-bottom-neg-15">
                                    <Col className="d-flex justify-content-between mb-2" name="selectedOption" value="Live">
                                        <p className="mb-2 card-title">Application Type</p>
                                    </Col>
                                </Row>

                                <Row className="margin-bottom-neg-15">
                                    <Col xs={12} sm={6}>
                                        <p className="review-font">{props.model.isJointApplication.isJointApplication === 'true' ? "Joint" : "Individual"}</p>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Card className="border-2">
                            <CardBody className="pt-3 margin-bottom-neg-15">
                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-between mb-2">
                                        <p className="mb-0 card-title">Account Selections</p>
                                        <p onClick={() => { setPageToDisplay('accountSelectionsInfo'); setModal(true) }} className="mb-0">
                                            {!props.model.appState.readonly &&
                                                <i className='btr bt-edit bt-lg cursor-pointer dugreen'></i>
                                            }
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        products.map((product, index) => {
                                            return (
                                                <Col xs={12} sm={6} className="mb-2" key={index}>
                                                    <Input type='checkbox' checked={productIdInSelectedProduct(product.productID, props.model.products.products) ? true : false}
                                                        className="magic-checkbox" disabled={true} />
                                                    <Label className="mb-0 review-font"> {product.productName} </Label>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Card className="border-2">
                            <CardBody className="pt-3 margin-bottom-neg-15">
                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-between mb-2">
                                        <p className="mb-0 card-title">Primary Owner Personal & Contact</p>
                                        <p onClick={() => { setPageToDisplay('primaryPersonInfo'); setModal(true) }} className="mb-0">
                                            {!props.model.appState.readonly &&
                                                <i className='btr bt-edit bt-lg cursor-pointer dugreen'></i>
                                            }
                                        </p>
                                    </Col>
                                </Row>

                                <Row className="margin-bottom-neg-5">
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>FULL NAME</b>
                                            <p className="review-font">{props.model.primary.firstName} {props.model.primary.mi} {props.model.primary.lastName}</p>
                                        </label>
                                    </Col>
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>DATE OF BIRTH</b>
                                            <p className="review-font">{props.model.primary.dob}</p>
                                        </label>
                                    </Col>
                                </Row>

                                <Row className="margin-bottom-neg-5">
                                    {/* Not needed at this time. */}
                                    {/* <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>CITIZENSHIP STATUS</b>
                                            <p className="review-font">{getCountry(props.model.primary.country)} citizen</p>
                                        </label>
                                    </Col> */}
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>SOCIAL SECURITY NUMBER</b>
                                            <p className="review-font" >{props.model.primary.ssn.substring(0, 7).replace(/[0-9]/g, '*') + props.model.primary.ssn.substring(7, props.model.primary.ssn.length)}</p>
                                        </label>
                                    </Col>
                                </Row>

                                <Row className="margin-bottom-neg-5">
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>EMAIL</b>
                                            <p className="review-font">{props.model.primary.primaryEmail}</p>
                                        </label>
                                    </Col>
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>PRIMARY PHONE</b>
                                            <p className="review-font">{props.model.primary.primaryPhone}</p>
                                        </label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Card className="border-2">
                            <CardBody className="pt-3 margin-bottom-neg-15">
                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-between mb-2">
                                        <p className="mb-0 card-title">Primary Owner Address Information</p>
                                        <p onClick={() => { setPageToDisplay('primaryAddressInfo'); setModal(true) }} className="mb-0">
                                            {!props.model.appState.readonly &&
                                                <i className='btr bt-edit bt-lg cursor-pointer dugreen'></i>
                                            }
                                        </p>
                                    </Col>
                                </Row>
                                {props.model.primaryAddress.isMailingAddress === 'false' &&
                                    <Row>
                                        <Col xs={12} sm={6} className="margin-bottom-neg-5">
                                            <label style={{ fontsize: '18px' }} ><b>PRIMARY ADDRESS</b>
                                            </label>
                                        </Col>
                                    </Row>
                                }

                                <Row className="margin-bottom-neg-5">
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>STREET</b>
                                            <p className="review-font">{props.model.primaryAddress.streetAddress1} </p>
                                        </label>
                                    </Col>
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>APT, UNIT, FLOOR</b>
                                            <p className="review-font">{props.model.primaryAddress.streetAddress2}</p>
                                        </label>
                                    </Col>
                                </Row>

                                <Row className="margin-bottom-neg-5">
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>ZIP CODE</b>
                                            <p className="review-font">{props.model.primaryAddress.zipCode} </p>
                                        </label>
                                    </Col>
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>CITY, STATE</b>
                                            <p className="review-font">{props.model.primaryAddress.city}, {renderState(props.model.primaryAddress.state, allStates)}</p>
                                        </label>
                                    </Col>
                                </Row>

                                {props.model.primaryAddress.isMailingAddress === 'false' &&
                                    <div>
                                        <br></br>
                                        <Row>
                                            <Col xs={12} sm={6} className="margin-bottom-neg-5">
                                                <label style={{ fontsize: '18px' }} ><b>MAILING ADDRESS</b>
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row className="margin-bottom-neg-5">
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>STREET</b>
                                                    <p className="review-font">{props.model.primaryAddress.mailingAddress1} </p>
                                                </label>
                                            </Col>
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>APT, UNIT, FLOOR</b>
                                                    <p className="review-font">{props.model.primaryAddress.mailingAddress2}</p>
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="margin-bottom-neg-5">
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>ZIP CODE</b>
                                                    <p className="review-font">{props.model.primaryAddress.mailingZipCode} </p>
                                                </label>
                                            </Col>
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>CITY, STATE</b>
                                                    <p className="review-font">{props.model.primaryAddress.mailingCity}, {renderState(props.model.primaryAddress.mailingState, allStates)}</p>
                                                </label>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Card className="border-2">
                            <CardBody className="pt-3 margin-bottom-neg-15">
                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-between mb-2">
                                        <p className="mb-0 card-title">Primary Owner Employment</p>
                                        <p onClick={() => { setPageToDisplay('primaryEmploymentInfo'); setModal(true) }} className="mb-0">
                                            {!props.model.appState.readonly &&
                                                <i className='btr bt-edit bt-lg cursor-pointer dugreen'></i>
                                            }
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="margin-bottom-neg-5">
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>EMPLOYMENT STATUS</b>
                                            <p className="review-font">{renderEmploymentStatusSwitch(props.model.primaryEmployment.employmentStatus)} </p>
                                        </label>
                                    </Col>
                                    {(props.model.primaryEmployment.employmentStatus === '1' || props.model.primaryEmployment.employmentStatus === '3' || props.model.primaryEmployment.employmentStatus === '4' || props.model.primaryEmployment.employmentStatus === '5') &&
                                        <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                            <label className="review-font"><b>{props.model.primaryEmployment.employmentStatus === '3' || props.model.primaryEmployment.employmentStatus === '5' ? 'PREVIOUS OCCUPATION' : 'OCCUPATION'}</b>
                                                <p className="review-font">{renderOccupation(props.model.primaryEmployment.occupation, occupations)}</p>
                                            </label>
                                        </Col>
                                    }

                                    {(props.model.primaryEmployment.employmentStatus === '2') &&
                                        <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                            <label className="review-font"><b>BRANCH</b>
                                                <p className="review-font">{renderMilitaryBranchSwitch(props.model.primaryEmployment.militaryBranch)}</p>
                                            </label>
                                        </Col>
                                    }
                                </Row>

                                {(props.model.primaryEmployment.employmentStatus === '1' || props.model.primaryEmployment.employmentStatus === '4') &&
                                    <Row className="margin-bottom-neg-5">
                                        <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                            <label className="review-font"><b>{props.model.primaryEmployment.employmentStatus === '4' ? "YOUR BUSINESS' NAME" : "EMPLOYER NAME"}</b>
                                                <p className="review-font">{props.model.primaryEmployment.employerName} </p>
                                            </label>
                                        </Col>
                                        <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                            <label className="review-font"><b>{props.model.primaryEmployment.employmentStatus === '4' ? "YOUR BUSINESS' ZIP CODE" : "EMPLOYER'S ZIP CODE"}</b>
                                                <p className="review-font">{props.model.primaryEmployment.employerZipcode}</p>
                                            </label>
                                        </Col>
                                    </Row>
                                }

                                {props.model.primaryEmployment.employmentStatus === '2' &&
                                    <Row className="margin-bottom-neg-5">
                                        <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                            <label className="review-font"><b>BASE/LOCATION</b>
                                                <p className="review-font">{props.model.primaryEmployment.militaryLocation} </p>
                                            </label>
                                        </Col>
                                    </Row>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Card className="border-2">
                            <CardBody className="pt-3 margin-bottom-neg-15">
                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-between mb-2">
                                        <p className="mb-0 card-title">Primary Owner Identification</p>
                                        <p onClick={() => { setPageToDisplay('primaryIdentificationInfo'); setModal(true) }} className="mb-0">
                                            {!props.model.appState.readonly &&
                                                <i className='btr bt-edit bt-lg cursor-pointer dugreen'></i>
                                            }
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="margin-bottom-neg-5">
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>IDENTIFICATION</b>
                                            <p className="review-font">{renderIdentificationTypeSwitch(props.model.primaryIdentification.identificationType)} </p>
                                        </label>
                                    </Col>

                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>{getIdentifcationNumberLabel()}</b>
                                            <p className="review-font">{props.model.primaryIdentification.identificationNumber}</p>
                                        </label>
                                    </Col>
                                </Row>

                                {(props.model.primaryIdentification.identificationType === '2' || props.model.primaryIdentification.identificationType === '3') &&
                                    <Row className="margin-bottom-neg-5">
                                        <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                            <label className="review-font"><b>ISSUE DATE</b>
                                                <p className="review-font">{props.model.primaryIdentification.issueDate}</p>
                                            </label>
                                        </Col>
                                        <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                            <label className="review-font"><b>ISSUE STATE</b>
                                                <p className="review-font">{renderState(props.model.primaryIdentification.issueState, allStates)}</p>
                                            </label>
                                        </Col>
                                    </Row>
                                }

                                <Row className="margin-bottom-neg-5">
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>EXPIRATION DATE</b>
                                            <p className="review-font">{props.model.primaryIdentification.expirationDate}</p>
                                        </label>
                                    </Col>
                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                        <label className="review-font"><b>VERBAL IDENTIFICATION PASSWORD</b>
                                            <p className="review-font">{props.model.primaryIdentification.securityWord}</p>
                                        </label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Card className="border-2">
                            <CardBody className="pt-3 margin-bottom-neg-15">
                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-between mb-2">
                                        <p className="mb-0 card-title">Beneficiaries</p>
                                        <p onClick={() => { setPageToDisplay('beneficiariesInfo'); setModal(true) }} className="mb-0">
                                            {!props.model.appState.readonly &&
                                                <i className='btr bt-edit bt-lg cursor-pointer dugreen'></i>
                                            }
                                        </p>
                                    </Col>
                                </Row>
                                {(props.model.beneficiaries.beneficiaries.length === 0) &&
                                    <Row className="margin-bottom-neg-5">
                                        <Col xs={12} sm={7} className="margin-bottom-neg-15">
                                            <p className="review-font">No beneficiaries have been added at this time. </p>
                                        </Col>
                                    </Row>
                                }

                                {(props.model.beneficiaries.beneficiaries.length !== 0) &&
                                    props.model.beneficiaries.beneficiaries.map((beneficiary, index) => {
                                        return (
                                            <Row className="margin-bottom-neg-5" key={index}>
                                                <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                    <label className="review-font"><b>FULL NAME</b>
                                                        <p className="review-font">{beneficiary.firstName} {beneficiary.mi} {beneficiary.lastName}</p>
                                                    </label>
                                                </Col>
                                                <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                    <label className="review-font"><b>DATE OF BIRTH</b>
                                                        {(beneficiary.dob !== null) &&
                                                            <p className="review-font">{beneficiary.dob}</p>
                                                        }
                                                    </label>
                                                </Col>
                                            </Row>)
                                    })
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                {/* Joint Owner */}
                {(props.model.isJointApplication.isJointApplication === 'true') &&
                    <div>
                        <Row className="mb-3">
                            <Col>
                                <Card className="border-2">
                                    <CardBody className="pt-3 margin-bottom-neg-15">
                                        <Row className="d-flex justify-content-between">
                                            <Col className="d-flex justify-content-between mb-2">
                                                <p className="mb-0 card-title">Joint Owner Personal & Contact</p>
                                                <p onClick={() => { setPageToDisplay('jointPersonInfo'); setModal(true) }} className="mb-0">
                                                    {!props.model.appState.readonly &&
                                                        <i className='btr bt-edit bt-lg cursor-pointer dugreen'></i>
                                                    }
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row className="margin-bottom-neg-5">
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>FULL NAME</b>
                                                    <p className="review-font">{props.model.jointContact.firstName} {props.model.jointContact.mi} {props.model.jointContact.lastName}</p>
                                                </label>
                                            </Col>
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>DATE OF BIRTH</b>
                                                    <p className="review-font">{props.model.jointContact.dob}</p>
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row className="margin-bottom-neg-5">
                                            {/* Not needed at this time. */}
                                            {/* <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>CITIZENSHIP STATUS</b>
                                                    <p className="review-font">{getCountry(props.model.jointContact.country)} citizen</p>
                                                </label>
                                            </Col> */}
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>SOCIAL SECURITY NUMBER</b>
                                                    <p className="review-font">{props.model.jointContact.ssn.substring(0, 7).replace(/[0-9]/g, '*') + props.model.jointContact.ssn.substring(7, props.model.jointContact.ssn.length)}</p>
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row className="margin-bottom-neg-5">
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>EMAIL</b>
                                                    <p className="review-font">{props.model.jointContact.primaryEmail}</p>
                                                </label>
                                            </Col>
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>PRIMARY PHONE</b>
                                                    <p className="review-font">{props.model.jointContact.primaryPhone}</p>
                                                </label>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <Card id="jointAddressCard" className="border-2">
                                    <CardBody className="pt-3 margin-bottom-neg-15">
                                        <Row className="d-flex justify-content-between">
                                            <Col className="d-flex justify-content-between mb-2">
                                                <p className="mb-0 card-title">Joint Owner Address Information</p>
                                                <p onClick={() => { setPageToDisplay('jointAddressInfo'); setModal(true) }} className="mb-0">
                                                    {!props.model.appState.readonly &&
                                                        <i className='btr bt-edit bt-lg cursor-pointer dugreen'></i>
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                        {props.model.primaryAddress.isMailingAddress === 'false' &&
                                            <Row>
                                                <Col xs={12} sm={6} className="margin-bottom-neg-5">
                                                    <label style={{ fontsize: '18px' }} ><b>PRIMARY ADDRESS</b>
                                                    </label>
                                                </Col>
                                            </Row>
                                        }

                                        <Row className="margin-bottom-neg-5">
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>STREET</b>
                                                    <p className="review-font">{props.model.jointAddress.streetAddress1} </p>
                                                </label>
                                            </Col>
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>APT, UNIT, FLOOR</b>
                                                    <p className="review-font">{props.model.jointAddress.streetAddress2}</p>
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row className="margin-bottom-neg-5">
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>ZIP CODE</b>
                                                    <p className="review-font">{props.model.jointAddress.zipCode} </p>
                                                </label>
                                            </Col>
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>CITY, STATE</b>
                                                    <p className="review-font">{props.model.jointAddress.city}, {renderState(props.model.jointAddress.state, allStates)}</p>
                                                </label>
                                            </Col>
                                        </Row>

                                        {props.model.jointAddress.isMailingAddress === 'false' &&
                                            <div>
                                                <br></br>
                                                <Row>
                                                    <Col xs={12} sm={6} className="margin-bottom-neg-5">
                                                        <label style={{ fontsize: '18px' }} ><b>MAILING ADDRESS</b>
                                                        </label>
                                                    </Col>
                                                </Row>

                                                <Row className="margin-bottom-neg-5">
                                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                        <label className="review-font"><b>STREET</b>
                                                            <p className="review-font">{props.model.jointAddress.mailingAddress1} </p>
                                                        </label>
                                                    </Col>
                                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                        <label className="review-font"><b>APT, UNIT, FLOOR</b>
                                                            <p className="review-font">{props.model.jointAddress.mailingAddress2}</p>
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row className="margin-bottom-neg-5">
                                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                        <label className="review-font"><b>ZIP CODE</b>
                                                            <p className="review-font">{props.model.jointAddress.mailingZipCode} </p>
                                                        </label>
                                                    </Col>
                                                    <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                        <label className="review-font"><b>CITY, STATE</b>
                                                            <p className="review-font">{props.model.jointAddress.mailingCity}, {renderState(props.model.jointAddress.mailingState, allStates)}</p>
                                                        </label>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <Card className="border-2">
                                    <CardBody className="pt-3 margin-bottom-neg-15">
                                        <Row className="d-flex justify-content-between">
                                            <Col className="d-flex justify-content-between mb-2">
                                                <p className="mb-0 card-title">Joint Owner Identification</p>
                                                <p onClick={() => { setPageToDisplay('jointIdentificationInfo'); setModal(true) }} className="mb-0">
                                                    {!props.model.appState.readonly &&
                                                        <i className='btr bt-edit bt-lg cursor-pointer dugreen'></i>
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="margin-bottom-neg-5">
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>IDENTIFICATION</b>
                                                    <p className="review-font">{renderIdentificationTypeSwitch(props.model.jointIdentification.identificationType)} </p>
                                                </label>
                                            </Col>

                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>{getIdentifcationNumberLabel()}</b>
                                                    <p className="review-font">{props.model.jointIdentification.identificationNumber}</p>
                                                </label>
                                            </Col>
                                        </Row>

                                        {(props.model.jointIdentification.identificationType === '2' || props.model.jointIdentification.identificationType === '3') &&
                                            <Row className="margin-bottom-neg-5">
                                                <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                    <label className="review-font"><b>ISSUE DATE</b>
                                                        <p className="review-font">{props.model.jointIdentification.issueDate}</p>
                                                    </label>
                                                </Col>
                                                <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                    <label className="review-font"><b>ISSUE STATE</b>
                                                        <p className="review-font">{renderState(props.model.jointIdentification.issueState, allStates)}</p>
                                                    </label>
                                                </Col>
                                            </Row>
                                        }

                                        <Row className="margin-bottom-neg-5">
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>EXPIRATION DATE</b>
                                                    <p className="review-font">{props.model.jointIdentification.expirationDate}</p>
                                                </label>
                                            </Col>
                                            <Col xs={12} sm={6} className="margin-bottom-neg-15">
                                                <label className="review-font"><b>VERBAL IDENTIFICATION PASSWORD</b>
                                                    <p className="review-font">{props.model.jointIdentification.securityWord}</p>
                                                </label>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }

                {(props.model.promocode.promoCode !== "") &&
                    <Row className="mb-3">
                        <Col>
                            <Card className="border-2">
                                <CardBody className="pt-3 margin-bottom-neg-15">
                                    <Row className="d-flex justify-content-between margin-bottom-neg-15">
                                        <Col className="d-flex justify-content-between mb-2" name="selectedOption" value="Live">
                                            <p className="mb-2 card-title">Promo Code</p>
                                        </Col>
                                    </Row>

                                    <Row className="margin-bottom-neg-15">
                                        <Col xs={12} sm={6}>
                                            <p className="review-font">{props.model.promocode.promoCode} -- {props.model.promocode.promoCodeDetails}</p>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }

                {
                    pageToDisplay !== '' &&
                    <Modal isOpen={showModal} toggle={toggleReviewModal} pageToDisplay={pageToDisplay} size="lg" keyboard={false} backdrop="static" >
                        <ModalBody >
                            {pageToDisplay === 'accountSelectionsInfo' &&
                                <ProductList availableProducts={products} selectedProductsArray={props.model.products.products} fromReview={true}
                                    stateHandler={props.handleState} toggleReviewModal={toggleReviewModal} model={props.model} />
                            }

                            {pageToDisplay === 'primaryPersonInfo' &&
                                <PersonInfo model={props.model.primary} isPrimaryMember={true} fromReview={true} readOnly={props.model.appState.readonly}
                                    otherApplicantEmail={props.model.jointContact.primaryEmail} primarySSNFilled={props.model.primary.ssn} otherApplicantSSN={props.model.jointContact.ssn} 
                                    stateHandler={(field, value) => props.handleState('primary', field, value)} toggleReviewModal={toggleReviewModal}
                                    leadId={props.model.leadId} frontEndModel={props.model} />
                            }

                            {pageToDisplay === 'primaryAddressInfo' &&
                                <AddressForm model={props.model.primaryAddress} isPrimaryMember={true} fromReview={true}
                                    handlePrimaryAddressIsJointAddressChange={props.handlePrimaryAddressIsJointAddressChange}
                                    primaryAddressShouldBeCopied={props.model.jointAddress.isJointAddrSameAsPrimary}
                                    primaryFirstName={props.model.primary.firstName}
                                    stateHandler={(field, value) => props.handleState('primaryAddress', field, value)} toggleReviewModal={toggleReviewModal} />
                            }

                            {pageToDisplay === 'primaryEmploymentInfo' &&
                                <PrimaryEmployementIncome model={props.model.primaryEmployment} isPrimaryMember={true} fromReview={true}
                                    stateHandler={(field, value) => props.handleState('primaryEmployment', field, value)} toggleReviewModal={toggleReviewModal} />
                            }

                            {pageToDisplay === 'primaryIdentificationInfo' &&
                                <PersonIdentification model={props.model.primaryIdentification} isPrimaryMember={true} fromReview={true}
                                    stateHandler={(field, value) => props.handleState('primaryIdentification', field, value)} toggleReviewModal={toggleReviewModal} />
                            }

                            {pageToDisplay === 'beneficiariesInfo' &&
                                <Beneficiaries model={props.model.beneficiaries} isPrimaryMember={true} fromReview={true}
                                    stateHandler={(field, value) => props.handleState('beneficiaries', field, value)} toggleReviewModal={toggleReviewModal} />
                            }

                            {pageToDisplay === 'jointPersonInfo' &&
                                <PersonInfo model={props.model.jointContact} isPrimaryMember={false} fromReview={true}  leadId={props.model.leadId}
                                    readOnly={props.model.appState.readonly} otherApplicantEmail={props.model.primary.primaryEmail} jointSSNFilled={props.model.jointContact.ssn}
                                    stateHandler={(field, value) => props.handleState('jointContact', field, value)} toggleReviewModal={toggleReviewModal}
                                    otherApplicantSSN={props.model.primary.ssn} />
                            }

                            {pageToDisplay === 'jointAddressInfo' &&
                                <AddressForm model={props.model.jointAddress} isPrimaryMember={false} fromReview={true}
                                    handlePrimaryAddressIsJointAddressChange={props.handlePrimaryAddressIsJointAddressChange}
                                    primaryAddressShouldBeCopied={props.model.jointAddress.isJointAddrSameAsPrimary}
                                    primaryFirstName={props.model.primary.firstName}
                                    stateHandler={(field, value) => props.handleState('jointAddress', field, value)} toggleReviewModal={toggleReviewModal} />
                            }

                            {pageToDisplay === 'jointIdentificationInfo' &&
                                <PersonIdentification model={props.model.jointIdentification} isPrimaryMember={false} fromReview={true}
                                    stateHandler={(field, value) => props.handleState('jointIdentification', field, value)} toggleReviewModal={toggleReviewModal} />
                            }
                        </ModalBody>
                    </Modal>
                }

            </fieldset>
            <NavigationButtons onNext={lockDownFields} setRouteTransition={props.setRouteTransition} ShowSaveForLater={true} />
        </>
    )
}

export default Review;
