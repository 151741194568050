import React, { useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import MasterStateContext from '../../Context/masterStateContext';
const NoLeadIdRedirect = (props) => {

    const { masterState } = useContext(MasterStateContext);

    const [redirect, setRedirect] = useState(false);
    const [redirectPage, setRedirectPage] = useState(false);


    useEffect(() => {
        var pathName = window.location.pathname.toLowerCase();
        pathName = pathName.replace("newmemberuat", "");
        var selectedOption = '';
        if (masterState.choose !== null) {
            selectedOption = masterState.choose.selectedOption;
        }

        var liveSelectedCounty = '';
        if (masterState.live !== null) {
            liveSelectedCounty = masterState.live.selectedCounty;
        }

        var workSelectedCounty = '';
        if (masterState.work !== null) {
            workSelectedCounty = masterState.work.selectedCounty;
        }

        var relativeFirstName = '';
        if (masterState.relative !== null) {
            relativeFirstName = masterState.relative.firstName;
        }

        var bsaQuestion = masterState.products.products[0].bsaQuestion;
        var primaryFirstName = masterState.primary.firstName;
        var primaryStreetAddress1 = masterState.primaryAddress.streetAddress1;
        var employmentStatus = masterState.primaryEmployment.employmentStatus;
        var primaryIdentificationType = masterState.primaryIdentification.identificationType;
        var isJointApplication = masterState.isJointApplication.isJointApplication;
        var jointFirstName = masterState.jointContact.firstName;
        var jointStreetAddress1 = masterState.jointAddress.streetAddress1;
        var jointIdentificationType = masterState.jointIdentification.identificationType;

        if (masterState.id === '' || masterState.leadId === 0) {
            if (!pathName.includes('choose') && !pathName.includes('live') && !pathName.includes('member') && !pathName.includes('noneapply') && !pathName.includes('relation') && !pathName.includes('work')
                && !pathName.includes('products') && !pathName.includes('primaryintro') && !pathName.includes('primarycontact')) {
                setRedirect(true);
                setRedirectPage(`/Resume`);
            }
            else if ((pathName.includes('live') || pathName.includes('member') || pathName.includes('noneapply') || pathName.includes('relation') || pathName.includes('work')) && selectedOption === '') {
                setRedirect(true);
                setRedirectPage(`/Eligibility/Choose`);
            }
            else if (pathName.includes('products')) {
                if (selectedOption === '') {
                    setRedirect(true);
                    setRedirectPage(`/Eligibility/Choose`);
                }
                else if (selectedOption === 'Member') {
                    setRedirect(true);
                    setRedirectPage(`/Eligibility/Member`);
                }
                else if (selectedOption === 'Live' && liveSelectedCounty === '') {
                    setRedirect(true);
                    setRedirectPage(`/Eligibility/Live`);
                }
                else if (selectedOption === 'Work' && workSelectedCounty === '') {
                    setRedirect(true);
                    setRedirectPage(`/Eligibility/Work`);
                }
                else if (selectedOption === 'Relation' && relativeFirstName === '') {
                    setRedirect(true);
                    setRedirectPage(`/Eligibility/Relation`);
                }
                else if (selectedOption === 'NoneApply') {
                    setRedirect(true);
                    setRedirectPage(`/Eligibility/NoneApply`);
                }
            }
        }

        if (pathName.includes('primaryintro') && bsaQuestion === '') {
            setRedirect(true);
            setRedirectPage(`/Products`);
        }
        else if (pathName.includes('primarycontact') && bsaQuestion === '') {
            setRedirect(true);
            setRedirectPage(`/Products`);
        }

        else if (pathName.includes('primaryaddress') && (primaryFirstName === '' || masterState.id === '' || masterState.leadId === 0)) {
            setRedirect(true);
            setRedirectPage(`/Member/PrimaryContact`);
        }

        else if (pathName.includes('primaryemployment') && primaryStreetAddress1 === '') {
            setRedirect(true);
            setRedirectPage(`/Member/PrimaryAddress`);
        }

        else if (pathName.includes('primaryidentification') && employmentStatus === '') {
            setRedirect(true);
            setRedirectPage(`/Member/PrimaryEmployment`);
        }

        else if (pathName.includes('beneficiaries') && primaryIdentificationType === '') {
            setRedirect(true);
            setRedirectPage(`/Member/PrimaryIdentification`);
        }

        else if (pathName.includes('isjointapplication') && primaryIdentificationType === '') {
            setRedirect(true);
            setRedirectPage(`/Member/PrimaryIdentification`);
        }

        else if (pathName.includes('promocode') || pathName.includes('review')) {
            if (isJointApplication === '') {
                setRedirect(true);
                setRedirectPage(`/Member/IsJointApplication`);
            }
            else if (isJointApplication === 'true') {
                if (jointIdentificationType === '') {
                    setRedirect(true);
                    setRedirectPage(`/Member/JointIdentification`);
                }

                if (jointStreetAddress1 === '') {
                    setRedirect(true);
                    setRedirectPage(`/Member/JointAddress`);
                }

                if (jointFirstName === '') {
                    setRedirect(true);
                    setRedirectPage(`/Member/JointContact`);
                }
            }
            else if (isJointApplication === 'false') {
                if (primaryIdentificationType === '') {
                    setRedirect(true);
                    setRedirectPage(`/Member/PrimaryIdentification`);
                }
            }
        }
        else if (pathName.includes('jointcontact')) {
            if (isJointApplication === '' || isJointApplication === 'false') {
                setRedirect(true);
                setRedirectPage(`/Member/IsJointApplication`);
            }
        }
        else if (pathName.includes('jointaddress') && jointFirstName === '') {
            setRedirect(true);
            setRedirectPage(`/Member/JointContact`);
        }
        else if (pathName.includes('jointidentification') && jointStreetAddress1 === '') {
            setRedirect(true);
            setRedirectPage(`/Member/JointAddress`);
        }
        else if (!pathName.includes('choose') && !pathName.includes('live') && !pathName.includes('member') && !pathName.includes('noneapply') && !pathName.includes('relation') && !pathName.includes('work')
            && !pathName.includes('products') && !pathName.includes('primaryintro') && !pathName.includes('primarycontact') && !pathName.includes('processing') && !pathName.includes('accountagreements')
            && !pathName.includes('method') && !pathName.includes('amounts') && !pathName.includes('creditcard') && !pathName.includes('ach') && !pathName.includes('buffer') && !pathName.includes('createmicrodeposits')
            && !pathName.includes('verifymicrodeposits') && !pathName.includes('/verify') && !pathName.includes('achamounts') && !pathName.includes('mailcheck') && !pathName.includes('inperson')) {
            setRedirect(true);
            setRedirectPage(`/Resume`);
        }

    }, []);

    return (

        <>
            {redirect &&
                <Redirect to={redirectPage} push={false} />
            }
        </>
    )
}



export default NoLeadIdRedirect;



