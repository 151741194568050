import React, { useState } from 'react';
import { LeadText } from '../../Shared/LeadText';
import NavigationButtons from '../../Shared/NavigationButtons';
import { Row, Col } from 'reactstrap';
import CardButton from '../../Shared/CardButton'
import FormFieldWrap from '../../Shared/FormFieldWrap';

const Method = ({ setRouteTransition, onChangeHandler, model }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedFundingMethodId, setSelectedFundingMethodId] = useState(model.fundingMethod.method || undefined);
    const [achErrorMessage, setACHErrorMessage] = useState(model.fundingMethod.message || model.microDepositInfo.message || '');

    const handleNextClick = (push) => {
        setErrorMessage('');
        if (selectedFundingMethodId === undefined || selectedFundingMethodId.trim() === '') {
            setErrorMessage('Required');
            return null;
        }

        //id is route to next page

        if (selectedFundingMethodId === 'ACH') {
            return 'ACH';
        }

        return 'Amounts';
        // }
        // else {
        //     return selectedFundingMethodId
    }

    const handleFundingMethodClick = (e) => {
        setSelectedFundingMethodId(e.target.id)
        onChangeHandler(e.target.id);
    }

    const commonCssClass = "border-2";
    return (
        <>
            <LeadText text="Let’s make your opening deposit(s)" subText="How would you like to make your opening deposit(s)?" ShowSaveForLater={false} />
            <FormFieldWrap errorMessage={errorMessage} ></FormFieldWrap>
            <Row>
                <Col className='mb-3' xs={12}>
                    <CardButton cardCss={selectedFundingMethodId === "CreditCard" ? commonCssClass + " selected" : commonCssClass} cardBodyCss="d-flex justify-content-between" id="CreditCard" handleOnClick={handleFundingMethodClick}
                        value="Member">
                        <div>
                            I'd like to use my credit/debit card.<br />
                            <span className="product-sub-text">$650 limit & your account will be funded today</span>
                        </div>
                        <i className={selectedFundingMethodId === "CreditCard" ? "mt-2 mdi h4 mdi-check-circle dugreen" : ""}></i>
                    </CardButton>
                </Col>
                <Col className='mb-3' xs={12}>
                    <CardButton id="ACH" handleOnClick={handleFundingMethodClick} cardCss={selectedFundingMethodId === "ACH" ? commonCssClass + " selected" : commonCssClass} cardBodyCss="d-flex justify-content-between" name="selectedOption" value="Member">
                        <div>
                            I'd like to transfer funds from another financial institution. <br />
                            <span className="product-sub-text">$2,500 limit & your account(s) will be funded in 3-5 business days, subject to account verification</span>
                            <FormFieldWrap errorMessage={achErrorMessage} ></FormFieldWrap>
                        </div>
                        <i className={selectedFundingMethodId === "ACH" ? "mt-2 mdi h4 mdi-check-circle dugreen" : ""}></i>
                    </CardButton>
                </Col>
                <Col className='mb-3' xs={12}>
                    <CardButton id="MailCheck" handleOnClick={handleFundingMethodClick} cardCss={selectedFundingMethodId === "MailCheck" ? commonCssClass + " selected" : commonCssClass} cardBodyCss="d-flex justify-content-between" name="selectedOption" value="Member">
                        <div>
                            I’d like to mail a deposit check to Dupaco.<br />
                            <span className="product-sub-text">No limit & your account(s) will be funded when your check clears</span>
                        </div>
                        <i className={selectedFundingMethodId === "MailCheck" ? "mt-2 mdi h4 mdi-check-circle dugreen" : ""}></i>
                    </CardButton>
                </Col>
                <Col className='mb-3' xs={12}>
                    <CardButton id="InPerson" handleOnClick={handleFundingMethodClick} cardCss={selectedFundingMethodId === "InPerson" ? commonCssClass + " selected" : commonCssClass} cardBodyCss="d-flex justify-content-between" name="selectedOption" value="Member">
                        <div>
                            I’d like to make my deposit in person at a Dupaco branch.<br />
                            <span className="product-sub-text">No limit & your account will be funded that day</span>
                        </div>
                        <i className={selectedFundingMethodId === "InPerson" ? "mt-2 mdi h4 mdi-check-circle dugreen" : ""}></i>
                    </CardButton>
                </Col>
            </Row>

            <NavigationButtons onNext={handleNextClick} setRouteTransition={setRouteTransition} ShowSaveForLater={false} showCancel={true} showBack={false} />
        </>
    );
}

export default Method;