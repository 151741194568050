import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import { LiveValidation } from '../../../StateModels/LiveState';
import NavigationButtons from '../../Shared/NavigationButtons';
import { LeadText } from '../../Shared/LeadText';


export class Live extends Component {
    static displayName = Live.name;

    state = {
        selectedCountyError: ''
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>  <LeadText text="Let's make sure you're eligible for membership" subText="Which county do you live and/or work in?" ShowSaveForLater={false} />
                <fieldset disabled={this.props.readOnly}>
                    <FormFieldWrap isRequired={false} errorMessage={this.state.selectedCountyError} errorMessageId={"eligibilityLiveRequiredId"}>
                        <Row form>
                            <Col xs="12" sm="6">
                                <Input type="select" name="selectedCounty" id="selectedCounty" value={this.props.model.selectedCounty} onChange={this.onChangeHandler}>
                                    <option value="">--Select--</option>
                                    <option value="1">Adair County, IA</option>
                                    <option value="88">Adams County, IA</option>
                                    <option value="2">Allamakee County, IA</option>
                                    <option value="89">Appanoose County,IA</option>
                                    <option value="3">Audubon County, IA</option>
                                    <option value="4">Benton County, IA</option>
                                    <option value="5">Black Hawk County, IA</option>
                                    <option value="6">Boone County, IA</option>
                                    <option value="7">Bremer County, IA</option>
                                    <option value="8">Buchanan County, IA</option>
                                    <option value="9">Buena Vista County, IA</option>
                                    <option value="10">Butler County, IA</option>
                                    <option value="11">Calhoun County, IA</option>
                                    <option value="12">Carroll County, IA</option>
                                    <option value="83">Carroll County, IL</option>
                                    <option value="13">Cass County, IA</option>
                                    <option value="14">Cedar County, IA</option>
                                    <option value="15">Cerro Gordo County, IA</option>
                                    <option value="16">Cherokee County, IA</option>
                                    <option value="17">Chickasaw County, IA</option>
                                    <option value="18">Clay County, IA</option>
                                    <option value="19">Clayton County, IA</option>
                                    <option value="20">Clinton County, IA</option>
                                    <option value="110">Columbia County, WI</option>
                                    <option value="21">Crawford County, IA</option>
                                    <option value="79">Crawford County, WI</option>
                                    <option value="22">Dallas County, IA</option>
                                    <option value="107">Dane, WI</option>
                                    <option value="90">Davis County,IA </option>
                                    <option value="91">Decatur County,IA</option>
                                    <option value="23">Delaware County, IA</option>
                                    <option value="92">Des Moines County,IA</option>
                                    <option value="24">Dickinson County, IA</option>
                                    <option value="111">Dodge County, WI</option>
                                    <option value="25">Dubuque County, IA</option>
                                    <option value="26">Emmet County, IA</option>
                                    <option value="27">Fayette County, IA</option>
                                    <option value="28">Floyd County, IA</option>
                                    <option value="29">Franklin County, IA</option>
                                    <option value="93">Fremont County,IA </option>
                                    <option value="80">Grant County, WI</option>
                                    <option value="116">Green County, WI</option>
                                    <option value="30">Greene County, IA</option>
                                    <option value="31">Grundy County, IA</option>
                                    <option value="32">Guthrie County, IA</option>
                                    <option value="33">Hamilton County, IA</option>
                                    <option value="34">Hancock County, IA</option>
                                    <option value="35">Hardin County, IA</option>
                                    <option value="36">Harrison County, IA</option>
                                    <option value="94">Henry County,IA</option>
                                    <option value="37">Howard County, IA</option>
                                    <option value="38">Humboldt County, IA</option>
                                    <option value="39">Ida County, IA</option>
                                    <option value="40">Iowa County, IA</option>
                                    <option value="81">Iowa County, WI</option>
                                    <option value="41">Jackson County, IA</option>
                                    <option value="42">Jasper County, IA</option>
                                    <option value="112">Jefferson County, WI</option>
                                    <option value="84">Jo Daviess County, IL</option>
                                    <option value="43">Johnson County, IA</option>
                                    <option value="44">Jones County, IA</option>
                                    <option value="45">Keokuk County, IA</option>
                                    <option value="46">Kossuth County, IA</option>
                                    <option value="82">Lafayette County WI</option>
                                    <option value="95">Lee County,IA</option>
                                    <option value="47">Linn County, IA</option>
                                    <option value="48">Louisa County, IA</option>
                                    <option value="96">Lucas County,IA</option>
                                    <option value="49">Lyon County, IA</option>
                                    <option value="50">Madison County, IA</option>
                                    <option value="51">Mahaska County, IA</option>
                                    <option value="52">Marion County, IA</option>
                                    <option value="53">Marshall County, IA</option>
                                    <option value="97">Mills County,IA</option>
                                    <option value="54">Mitchell County, IA</option>
                                    <option value="55">Monona County, IA</option>
                                    <option value="98">Monroe County,IA </option>
                                    <option value="99">Montgomery County,IA</option>
                                    <option value="56">Muscatine County, IA</option>
                                    <option value="57">O'Brien County, IA</option>
                                    <option value="58">Osceola County, IA</option>
                                    <option value="100">Page County,IA</option>
                                    <option value="59">Palo Alto County, IA</option>
                                    <option value="60">Plymouth County, IA</option>
                                    <option value="61">Pocahontas County, IA</option>
                                    <option value="62">Polk County, IA</option>
                                    <option value="63">Pottawattamie County, IA</option>
                                    <option value="64">Poweshiek County, IA</option>
                                    <option value="108">Richland, WI</option>
                                    <option value="101">Ringgold County,IA</option>
                                    <option value="113">Rock County, WI</option>
                                    <option value="85">Rock Island County, IL</option>
                                    <option value="65">Sac County, IA</option>
                                    <option value="109">Sauk, WI</option>
                                    <option value="66">Scott County, IA</option>
                                    <option value="67">Shelby County, IA</option>
                                    <option value="68">Sioux County, IA</option>
                                    <option value="86">Stephenson County, IL</option>
                                    <option value="69">Story County, IA</option>
                                    <option value="70">Tama County, IA</option>
                                    <option value="102">Taylor County,IA</option>
                                    <option value="103">Union County,IA</option>
                                    <option value="104">Van Buren,IA</option>
                                    <option value="114">Walworth County, WI</option>
                                    <option value="105">Wapello County,IA</option>
                                    <option value="71">Warren County, IA</option>
                                    <option value="72">Washington County, IA</option>
                                    <option value="115">Waukesha County, WI</option>
                                    <option value="106">Wayne County,IA</option>
                                    <option value="73">Webster County, IA</option>
                                    <option value="87">Whiteside County, IL</option>
                                    <option value="74">Winnebago County, IA</option>
                                    <option value="75">Winneshiek County, IA</option>
                                    <option value="76">Woodbury County, IA</option>
                                    <option value="77">Worth County, IA</option>
                                    <option value="78">Wright County, IA</option>
                                </Input>

                            </Col>

                        </Row>
                    </FormFieldWrap>
                </fieldset>
                <Row>
                    <Col xs={12} sm={12}>
                        <p>County not listed? That means you're not eligible based on where you live and/or work.
                            Please click <i>Back</i> and see if a different eligibility statement applies to you.</p>
                    </Col>
                </Row>
                <FormFieldWrap isRequired={false}>
                    <NavigationButtons setRouteTransition={this.props.setRouteTransition} onNext={this.onNext.bind(this)} ShowSaveForLater={false} />
                </FormFieldWrap>
            </>
        );
    }

    onChangeHandler = (e) => {
        this.props.stateHandler(e.target.name, e.target.value);
    }

    onNext = async () => {
        this.resetErrors();

        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        const results = await LiveValidation(this).validate(this.props.model, {
            recursive: true,
            abortEarly: false
        }).then(() => {
            // this.props.setRouteTransition("moveLeft");
            return true;
        })
            .catch((validationErrors) => {
                window.scrollTo(0, 0);
                return false
            });

        if (results) {
            return '/Products';
        }
        return null;
    }

    resetErrors = () => {
        this.setState({
            selectedCountyError: ''
        });
    }
}
