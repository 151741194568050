import React, { useState, useReducer, useContext } from "react";
import { Row, Col, Input, Button, Spinner, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { SubHeader } from "../Shared/SubHeader";
import { Header } from "../Shared/Header";
import FormFieldWrap from "../Shared/FormFieldWrap";
import { ResumeValidation } from "../../StateModels/ResumeValidation";
import { GetApplication } from "../../utilities/FetchUtilites";
import { Footer } from "../Shared/Footer";
import MasterStateContext from "../../Context/masterStateContext";
import AlertMessage from "../Shared/Alert";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";


const ResumeForm = ({ setMasterState, history, setRouteTransition, location }) => {
  const { setConnectivityError, handleCurrentPageChange } = useContext(MasterStateContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [appFound, setAppFound] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isMaintenanceMode = false;
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [showNoApp, setShowNoApp] = useState(false);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: "",
      ssn: "",
      appNumber: "",
    }
  );

  const [userErrors, setUserErrors] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      emailError: "",
      ssnError: "",
      appNumberError: "",
    }
  );

  const handleChange = (e) => {
    let { name, value, type } = e.target;
    if (type === "checkbox") {
      value = e.target.checked;
    }

    setUserInput({ [name]: value });
  };

  const handleClick = async (e) => {
    setUserErrors({
      emailError: "",
      ssnError: "",
      appNumberError: "",
    });

    var isValid = await ResumeValidation(setUserErrors)
      .validate(userInput)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });

    if (isValid) {
      setIsLoading(true);
      const body = {
        recaptcha: recaptchaToken,
        ...userInput,
      };
      GetApplication(body)
        .then((data) => {
          if (data === null) {
            throw new Error("No data matching request parameters");
          }
          setAppFound(null);
          setAppFound(true);
          setTimeout(() => {
            setMasterState(data);
            handleCurrentPageChange(data.appState.currentPage, location);
            history.push(data.appState.currentPage);
            setIsLoading(false);
          }, 2000);
        })
        .catch((err) => {
          executeRecaptcha().then(setRecaptchaToken);
          if (err.message.includes("No data matching request parameters")) {
            setAppFound(false);
          } else if (
            err.message.toUpperCase().includes("IP Banned".toUpperCase())
          ) {
            setAppFound(false);
            history.push("/Restricted");
          } else {
            setConnectivityError(true);
          }
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {isMaintenanceMode ? (
        <>
          <Header>Sorry, we're temporarily unavailable.</Header>
        </>
      ) : (
        <>
          <SubHeader>Let's finish opening your account(s)</SubHeader>
          {appFound !== false && (
            <Header>Ready to continue your application?</Header>
          )}
        </>
      )}
      {appFound === false && (
        <Row form>
          <Col xs={12} sm={12}>
            <AlertMessage
              alertColor=""
              alertHeading="Hmmm...something isn’t quite right."
              alertMessage={
                <p>
                  {
                    "The information you entered doesn’t match our files. Please double-check and try entering again.  If the problem persists, give us a call — we’re always happy to help!"
                  }
                </p>
              }
              showButton={false}
            />
          </Col>
        </Row>
      )}
      {appFound && (
        <Row>
          <Col xs={12} sm={12}>
            <AlertMessage
              alertColor=""
              alertHeading="Application found!"
              alertMessage={
                <p>{"One moment as we take you back to where you left off."}</p>
              }
              showButton={false}
            />
          </Col>
        </Row>
      )}
      {isMaintenanceMode ? (
        <Row>
          <Col>
            <p>
              It's not you, it's us. We're doing a little routine maintenance,
              so our online membership application is temporarily unavailable.
            </p>

            <p>
              We should be up and running again shortly. Can we talk you into
              stopping back? If you don't want to wait, please give us a call at
              800-373-7600, ext. 0. A Dupaco representative will help you fill
              out an application over the phone.
            </p>

            <p>
              Sorry again for the inconvenience. We hope to hear from you soon!
            </p>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <p>
                Just enter your information and we'll take you right back to
                where you left off.
              </p>
            </Col>
          </Row>
          <Row form>
            <Col xs={12} sm={6}>
              <FormFieldWrap
                id="email"
                innerLabelText="Email"
                errorMessage={userErrors.emailError}
              >
                <Input
                  name="email"
                  id="email"
                  value={userInput.email}
                  onChange={handleChange}
                />
              </FormFieldWrap>
            </Col>
            <Col xs={12} sm={6}>
              <FormFieldWrap
                id="ssn"
                innerLabelText="Last four digits of SSN"
                errorMessage={userErrors.ssnError}
              >
                <Input
                  type="text"
                  maxLength="4"
                  name="ssn"
                  id="ssn"
                  value={userInput.ssn}
                  onChange={handleChange}
                />
              </FormFieldWrap>
            </Col>
          </Row>
          <Row form>
            <Col xs={12} sm={6}>
              <FormFieldWrap
                id="appNumber"
                innerLabelText="Application ID number"
                errorMessage={userErrors.appNumberError}
                helpText={
                  <Link
                    onClick={() => setRouteTransition("moveLeft")}
                    to="/ForgotAppNumber"
                  >
                    Lost or forgot your application ID number?
                  </Link>
                }
                helpTextClassName="form-text"
              >
                <Input
                  name="appNumber"
                  id="appNumber"
                  value={userInput.appNumber}
                  onChange={handleChange}
                />
              </FormFieldWrap>
            </Col>
          </Row>
          <br />
          <Row className="mb-3">
            <Col xs={{ size: 12 }} sm={{ size: 4, offset: 8 }}>
              <Button
                color=""
                type="button"
                block
                onClick={!isLoading ? handleClick : undefined}
                disabled={isLoading}
                className="shine-green-btn"
              >
                {isLoading ? (
                  <Spinner size="sm">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Continue"
                )}
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Button
                color="link"
                onClick={() => setShowNoApp(!showNoApp)}
                style={{ paddingLeft: "0rem", paddingRight: "0rem" }}
              >
                Haven't started your Dupaco membership yet?
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Collapse isOpen={showNoApp}>
                <Row>
                  <Col>
                    <p>
                      Well, we'd love to have you as Dupaco's newest
                      member-owner! Please click below to get started.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ size: 12 }} sm={{ size: 4, offset: 8 }}>
                    <Link
                      to="/"
                      onClick={() => setRouteTransition("moveLeft")}
                      className="btn shine-green-btn pull-right"
                    >
                      Start my Application
                    </Link>
                  </Col>
                </Row>
              </Collapse>
            </Col>
          </Row>
        </>
      )}
      <Footer />
    </>
  );
};

const Resume = ({
  setMasterState,
  history,
  showTimedOutBanner,
  setRouteTransition,
  location
}) => {
  //   CheckIfBanned();

  return (
    <div>
      <GoogleReCaptchaProvider reCaptchaKey="6Lcs9ugUAAAAAIZlSZ-3fA2_hC4lBKdWr2UlPGN0">
        <ResumeForm
          setMasterState={setMasterState}
          history={history}
          setRouteTransition={setRouteTransition}
          location={location}
        />
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default withRouter(Resume);
