import { object, string, reach } from 'yup';

const PrimaryEmployementState = {
    employmentStatus: "",
    employerName: '',
    employerZipcode: '',
    occupation: '',
    militaryBranch: '',
    militaryLocation: '',
    referredByEmployer: ''
};

export const PrimaryEmployementValidation = (context) => {
    let mainSchema = object().shape({
        employmentStatus: string().required(() => context.setState({ employmentStatusError: 'Required' })),
    });

    let employedPartialSchema = object().shape({
        occupation: string().required(() => context.setState({ occupationError: 'Required' })),
        employerName: string().required(() => context.setState({ employerNameError: 'Required' })),
        employerZipcode: string().min(5, () => context.setState({ employerZipcodeError: 'Required' })).required(() => context.setState({ employerZipcodeError: 'Required' })),
        referredByEmployer: string().required(() => context.setState({ referredByEmployerError: 'Required' })),
    })

    let militaryPartialSchema = object().shape({
        militaryBranch: string().required(() => context.setState({ militaryBranchError: 'Required' })),
        militaryLocation: string().required(() => context.setState({ militaryLocationError: 'Required' })),
    })

    let selfEmployedPartialSchema = object().shape({
        occupation: string().required(() => context.setState({ occupationError: 'Required' })),
        employerName: string().required(() => context.setState({ employerNameError: 'Required' })),//field label get changed based on employment status
        employerZipcode: string().min(5, () => context.setState({ employerZipcodeError: 'Required' })).required(() => context.setState({ employerZipcodeError: 'Required' })),//field label get changed based on employment status
    })

    let retiredUnemployedPartialSchema = object().shape({
        occupation: string().required(() => context.setState({ occupationError: 'Required' }))

    })


    if (context.props.model.employmentStatus === '1') {
        mainSchema = mainSchema.concat(employedPartialSchema);
    }

    if (context.props.model.employmentStatus === '2') {
        mainSchema = mainSchema.concat(militaryPartialSchema);
    }

    if (context.props.model.employmentStatus === '4') {
        mainSchema = mainSchema.concat(selfEmployedPartialSchema);
    }

    if (context.props.model.employmentStatus === '3' || context.props.model.employmentStatus === '5') {
        mainSchema = mainSchema.concat(retiredUnemployedPartialSchema);
    }



    return mainSchema;
};


export const PrimaryEmployementSingleValidation = (context, nameOfField) => {
    const validationObject = PrimaryEmployementValidation(context);
    return reach(validationObject, nameOfField);
};

export default PrimaryEmployementState;