import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import Layout from "./components/Shared/Layout";
import BuildMasterState, {
  GetEmptyMasterState,
} from "./StateModels/MasterState";
import Eligibility from "./components/Views/Eligibility/Eligibility";
import Members from "./components/Views/Members/Members";
import {
  Upsert,
  GetIDVKey,
  GetReferedEmailAddressFromGuid,
} from "./utilities/FetchUtilites";
import Products from "./components/Views/Products/Products";
import Resume from "./components/Views/Resume";
import ForgotAppNumber from "./components/Views/ForgotAppNumber";
import { SaveForLater } from "./components/Views/SaveForLater";
import Complete from "./components/Views/Complete";
import Withdrawn from "./components/Views/Withdrawn";
import Abandoned from "./components/Views/Abandoned";
import Denied from "./components/Views/Denied";
import Pending from "./components/Views/Pending";
import AccountSetup from "./components/Views/AccountSetup/AccountSetup";
import Cancel from "./components/Views/Cancel";
import InPerson from "./components/Views/Fund/InPerson";
import AddressState from "./StateModels/AddressState";
import { TimedOut } from "./components/Views/TimedOut";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import dynamicChildFactory from "./utilities/ChildFactory";
import "./components/Shared/StepTransition.css";
import Fund from "./components/Views/Fund/Fund";
import MasterStateContext from "../js/Context/masterStateContext";
import ExistingApplication from "./components/Views/ExistingApplication";
import ExistingMember from "./components/Views/ExistingMember";
import FormerMemberBadStatus from "./components/Views/FormerMemberBadStatus";
import Expired from "./components/Views/Expired";
import Restricted from "./components/Views/Restricted";
import GettingStarted from "./components/Views/GettingStarted";
import ScrollToTop from "./components/Shared/ScrollToTop";
import { getEmptyProduct } from "./StateModels/ProductState";
import { getEmptyBeneficiariesState } from "./StateModels/BeneficiariesState";
import queryString from "query-string";

class App extends Component {
  state = BuildMasterState();

  writeMasterStateToSession = (e) => {
    sessionStorage.setItem("newMember", JSON.stringify(this.state));
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.writeMasterStateToSession);
    //page hide is compatible with ios safari and before unload is not
    window.addEventListener("pagehide", this.writeMasterStateToSession);

    let params = queryString.parse(this.props.location.search);
    this.setState((previousState) => {
      if (params.refer) {
        const coeReferralIds = new Set([
          "100",
          "101",
          "102",
          "103",
          "104",
          "500",
          "501",
          "502",
          "503",
          "504",
          "505",
          "506",
          "507",
          "508",
          "509",
          "510",
          "511",
          "512",
          "513",
          "514",
          "515",
          "533",
          "106",
          "535",
          "541",
          "542",
          "543",
          "107",
          "544",
          "545"
        ]); // hardcoded Source ID's (called Codes in CRMNEXT) NOT Lookup IDs of referrals that should be allowed to skip the eligiblity and funding steps, all others cannot // COE TODO
        const isCOE = coeReferralIds.has(params.refer);
        if (isCOE) {
          previousState.choose.selectedOption = "Work";
          previousState.work.selectedCounty = "25";
          previousState.primaryEmployment.referredByEmployer = "true";
          previousState.fundInBranch.branch = "16";
          previousState.fundingMethod.method = "InPerson";
        }
        previousState.leadSourceId = params.refer;
      }
      return previousState;
    });

    this.props.history.listen((location) => {
      window.gtag("config", "UA-7795976-11", {
        page_location: window.location.href,
        page_path: location.pathname,
      });
    });

    GetIDVKey().then((idv) => {
      this.setState((previousState) => {
        previousState["idvKey"] = idv.value;

        return previousState;
      });
    });
    if (params.referGuid !== undefined) {
      GetReferedEmailAddressFromGuid(params.referGuid).then((email) => {
        this.setState((previousState) => {
          previousState.primary.primaryEmail = email.value;
          previousState.primary.primaryEmailConfirmation = email.value;
          return previousState;
        });
      });
    }
  }

  setConnectivityError = (errorOccurred) => {
    this.setState((previousState) => {
      previousState.appState.connectivityError = errorOccurred;
      return previousState;
    });
  };

  render() {
    const { steps } = this.state;
    const { routeTransition } = this.state.appState;
    const { location } = this.props;

    return (
      <MasterStateContext.Provider
        value={{
          masterState: this.state,
          handleIDChange: this.handleIDChange,
          handleCurrentPageChange: this.updateCurrentPage,
          setConnectivityError: this.setConnectivityError,
          handleRimUpdate: this.setRims,
          handleIDVUpdate: this.setIDV,
          handleMasterState: this.handleMasterState,
          writeMasterStateToSession: this.writeMasterStateToSession,
          history: this.props.history,
        }}
      >
        <ScrollToTop />
        <Layout
          resetState={this.resetState}
          steps={steps}
          model={this.state}
          push={this.props.history.push}
          handleIDChange={this.handleIDChange}
          connectivityError={this.state.appState.connectivityError}
          handleCurrentPageChange={this.updateCurrentPage}
          handleState={this.handleState}
          setRouteTransition={this.setRouteTransition}
          setConnectivityError={this.setConnectivityError}
        >
          <TransitionGroup childFactory={dynamicChildFactory(routeTransition)}>
            <CSSTransition key={location.key} timeout={1200}>
              <Switch location={location}>
                <Route
                  path="/Complete"
                  render={({ match, history }) => (
                    <Complete
                      model={this.state}
                      handleState={this.handleState}
                      match={match}
                      history={history}
                      setRouteTransition={this.setRouteTransition}
                    />
                  )}
                />
                <Route
                  path="/Pending"
                  render={({ match, history }) => (
                    <Pending
                      setRouteTransition={this.setRouteTransition}
                      model={this.state}
                    />
                  )}
                />
                <Route
                  path="/Restricted"
                  render={({ match, history }) => (
                    <Restricted
                      setRouteTransition={this.setRouteTransition}
                      model={this.state}
                    />
                  )}
                />
                <Route
                  path="/Denied"
                  render={({ match, history }) => (
                    <Denied
                      model={this.state}
                      resetState={this.resetState}
                      history={history}
                      setMasterState={this.handleMasterState}
                      setRouteTransition={this.setRouteTransition}
                    />
                  )}
                />
                <Route
                  path="/Withdrawn"
                  render={({ match, history }) => (
                    <Withdrawn
                      setRouteTransition={this.setRouteTransition}
                      model={this.state}
                    />
                  )}
                />
                <Route
                  path="/Abandoned"
                  render={({ match, history }) => (
                    <Abandoned
                      setRouteTransition={this.setRouteTransition}
                      model={this.state}
                    />
                  )}
                />
                <Route
                  path="/Cancel"
                  render={({ history }) => (
                    <Cancel
                      model={this.state}
                      resetState={this.resetState}
                      history={history}
                      setMasterState={this.handleMasterState}
                      setRouteTransition={this.setRouteTransition}
                    />
                  )}
                />
                <Route
                  path="/ExistingApplication"
                  render={({ history }) => (
                    <ExistingApplication
                      model={this.state}
                      resetState={this.resetState}
                      history={history}
                      setMasterState={this.handleMasterState}
                      setRouteTransition={this.setRouteTransition}
                    />
                  )}
                />
                <Route
                  path="/ExistingMember"
                  render={({ history }) => (
                    <ExistingMember
                      model={this.state}
                      resetState={this.resetState}
                      history={history}
                      setMasterState={this.handleMasterState}
                      setRouteTransition={this.setRouteTransition}
                    />
                  )}
                />
                <Route
                  path="/FormerMemberBadStatus"
                  render={({ history }) => (
                    <FormerMemberBadStatus
                      model={this.state}
                      resetState={this.resetState}
                      history={history}
                      setMasterState={this.handleMasterState}
                      setRouteTransition={this.setRouteTransition}
                    />
                  )}
                />
                <Route
                  path="/Expired"
                  render={({ history }) => (
                    <Expired
                      model={this.state}
                      resetState={this.resetState}
                      history={history}
                      setMasterState={this.handleMasterState}
                      setRouteTransition={this.setRouteTransition}
                    />
                  )}
                />
                <Route
                  path="/InPerson"
                  render={({ history }) => (
                    <InPerson
                      model={this.state}
                      resetState={this.resetState}
                      history={history}
                      setMasterState={this.handleMasterState}
                      setRouteTransition={this.setRouteTransition}
                    />
                  )}
                />
                <Route
                  path="/Resume"
                  render={({ history }) => (
                    <Resume
                      history={history}
                      setMasterState={this.handleMasterState}
                      showTimedOutBanner={
                        this.state.appState.showTimedOutBanner
                      }
                      setRouteTransition={this.setRouteTransition}
                    />
                  )}
                />
                <Route
                  path="/ForgotAppNumber"
                  render={({ history }) => (
                    <ForgotAppNumber history={history} />
                  )}
                />
                <Route
                  path="/TimedOut"
                  render={({ match, history }) => (
                    <TimedOut
                      model={this.state}
                      TimedOutAppId={this.state.appState.timedOutAppId}
                      handleState={this.handleState}
                      match={match}
                      history={history}
                      setRouteTransition={this.setRouteTransition}
                    />
                  )}
                />
                <Route
                  path="/SaveForLater"
                  render={({ match, history }) => (
                    <SaveForLater
                      model={this.state}
                      handleState={this.handleState}
                      match={match}
                      history={history}
                      setRouteTransition={this.setRouteTransition}
                    />
                  )}
                />
                <Route>
                  {({ match, history }) => (
                    <div>
                      <Route
                        exact
                        path="/"
                        render={({ match, history }) => (
                          <GettingStarted
                            model={this.state}
                            handleState={this.handleState}
                            match={match}
                            history={history}
                            setRouteTransition={this.setRouteTransition}
                          />
                        )}
                      />

                      <Route
                        path="/Eligibility"
                        render={({ match, history }) => (
                          <Eligibility
                            model={this.state}
                            handleState={this.handleState}
                            match={match}
                            history={history}
                            setRouteTransition={this.setRouteTransition}
                          />
                        )}
                      />
                      <Route
                        path="/Products"
                        render={({ match, history }) => (
                          <Products
                            model={this.state}
                            handleState={this.handleState}
                            match={match}
                            history={history}
                            setRouteTransition={this.setRouteTransition}
                          />
                        )}
                      />
                      <Route
                        path="/Member"
                        render={({ match, history }) => (
                          <Members
                            model={this.state}
                            handleState={this.handleState}
                            match={match}
                            history={history}
                            setRouteTransition={this.setRouteTransition}
                            handlePrimaryAddressIsJointAddressChange={
                              this.handlePrimaryAddressIsJointAddressChange
                            }
                          />
                        )}
                      />
                      <Route
                        path="/Setup"
                        render={({ match, history, location }) => (
                          <AccountSetup
                            model={this.state}
                            handleState={this.handleState}
                            statusChange={this.statusChange}
                            match={match}
                            history={history}
                            lockDownFields={this.lockFields}
                            setRouteTransition={this.setRouteTransition}
                            handlePrimaryAddressIsJointAddressChange={
                              this.handlePrimaryAddressIsJointAddressChange
                            }
                            location={location}
                            setConnectivityError={this.setConnectivityError}
                          />
                        )}
                      />
                      <Route
                        path="/Fund"
                        render={({ match, history }) => (
                          <Fund
                            model={this.state}
                            handleState={this.handleState}
                            match={match}
                            history={history}
                            setRouteTransition={this.setRouteTransition}
                            handleProductFundingChange={
                              this.handleProductFundingChange
                            }
                            handleCCResponse={this.handleCreditCardResponse}
                          />
                        )}
                      />
                    </div>
                  )}
                </Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </Layout>
      </MasterStateContext.Provider>
    );
  }

  //this.props.handleState("appState", 'showTimedOutBanner', true);
  // this is the master event handler function for updating the state
  // parameters:
  //    page: This is the name of the route you are currently within.
  //          If you have any questions about what it should be, check out the MasterState.js file
  //          and look on the left hand side of the property names.
  //    field: This is the field within the page being updated.
  //    value: This is the value of the field to replace the current state.
  handleState = (page, field, value) => {
    // fields are locked down and cannot be edited
    if (
      this.state.appState.readonly === true &&
      page !== "disclosures" &&
      page !== "accountAgreements" &&
      page !== "microDepositInfo" &&
      page !== "fundingMethod" &&
      page !== "fundInBranch"
    ) {
      return;
    }

    this.setState((previousState) => {
      let pageToChange = previousState[page];
      let fieldToChange = pageToChange[field];
      fieldToChange = value;
      pageToChange[field] = fieldToChange;
      previousState[page] = pageToChange;
      return previousState;
    });
  };

  handleIDChange = (leadId, jointId, appId) => {
    // fields are locked down and cannot be edited
    if (this.state.appState.readonly === true) {
      return;
    }

    this.setState((previousState) => {
      previousState.leadId = leadId;
      previousState.jointLeadId = jointId;
      previousState.id = appId;
      return previousState;
    });
  };

  handleCreditCardResponse = (responseNumber) => {
    this.setState((previousState) => {
      previousState.appState.ccResponse = responseNumber;
      return previousState;
    });
  };

  handlePrimaryAddressIsJointAddressChange = (bool) => {
    if (bool === "true") {
      this.setState((prevState) => {
        //prevState.jointAddress = prevState.primaryAddress;
        var MNQuestions = {
          previousChecking: prevState.jointAddress.previousChecking,
          financialNames: prevState.jointAddress.financialNames,
          previousCheckingClosed: prevState.jointAddress.previousCheckingClosed,
          previousCheckingClosedReason:
            prevState.jointAddress.previousCheckingClosedReason,
          convictedOfCriminalOffense:
            prevState.jointAddress.convictedOfCriminalOffense,
        };

        var jointMailingAddress = {
          isMailingAddress: prevState.jointAddress.isMailingAddress,
          mailingAddress1: prevState.jointAddress.mailingAddress1,
          mailingAddress2: prevState.jointAddress.mailingAddress2,
          mailingZipCode: prevState.jointAddress.mailingZipCode,
          mailingState: prevState.jointAddress.mailingState,
          mailingCity: prevState.jointAddress.mailingCity,
        };
        prevState.jointAddress = {
          ...prevState.primaryAddress,
          ...MNQuestions,
          ...jointMailingAddress,
        };
        prevState.jointAddress.isJointAddrSameAsPrimary = bool;
        prevState.primaryAddress.isJointAddrSameAsPrimary = bool;
        return prevState;
      });
    } else {
      this.setState((prevState) => {
        prevState.jointAddress = { ...AddressState };
        prevState.jointAddress.isJointAddrSameAsPrimary = bool;
        return prevState;
      });
    }
  };

  updateDB = () => {
    if (this.state.appState.timedOutAppId === "") {
      Upsert(this.state);
    }
  };

  setRims = (primaryRim, jointRim, accounts, errors) => {
    accounts = accounts || [];
    primaryRim = primaryRim || null;
    jointRim = jointRim || null;
    this.setState((previousState) => {
      let products = previousState.products.products;
      for (let i = 0; i < products.length; i++) {
        let product = products[i];
        let accountMatching = accounts.find((acc) => {
          return (
            acc.classCode.toString() === product.productClassCode &&
            acc.type === product.productType
          );
        });
        if (accountMatching === undefined) {
          continue;
        }
        product.accountNumber = accountMatching.accountNumber;
        products[i] = product;
      }

      previousState.primary.rimNumber = primaryRim;
      previousState.jointContact.rimNumber = jointRim;

      return {
        products: {
          products: products,
        },
        rimErrors: errors,
        ...previousState,
      };
    });
  };

  setIDV = (
    primaryCaseId,
    primaryResult,
    primaryComments,
    jointCaseId,
    jointResult,
    jointComments
  ) => {
    this.setState((previousState) => {
      previousState.appState = {
        ...previousState.appState,
        primaryCase: primaryCaseId,
        primaryResult: primaryResult,
        primaryComments: primaryComments,
        jointCase: jointCaseId,
        jointResult: jointResult,
        jointComments: jointComments,
      };
      return previousState;
    });
  };

  lockFields = () => {
    this.setState((prevState) => {
      prevState.appState.readonly = true;
      return prevState;
    });
  };

  handleProductSelection = (index, productID, isChecked) => {
    this.setState((preivousState) => {
      const products = preivousState.products.products;
      let selectedProduct = products[index];
      // don't allow change of the Share Savings
      if (productID === "0") {
        return preivousState;
      }
      if (selectedProduct.productID !== productID) {
        for (let i = 0; i < products.length; i++) {
          if (productID === products[i].productID) {
            products[i].selected = isChecked;
          }
        }
      } else {
        selectedProduct.selected = isChecked;
        products[index] = selectedProduct;
      }
      preivousState.products.products = products;

      return preivousState;
    });
  };

  handleMasterState = (data) => {
    this.setState(data);
  };

  statusChange = (status) => {
    this.setState({
      status: status,
    });
  };

  updateCurrentPage = (updatedCurrentPage, location) => {
    this.setState((prevState) => {
      prevState.appState.backButtonPage = location.pathname;
      prevState.appState.currentPage = updatedCurrentPage;
      if (!prevState.history) {
        prevState.history = [];
      }
      prevState.history.push(updatedCurrentPage);
      return prevState;
    });
  };

  setRouteTransition = (transition) => {
    this.setState((prevState) => {
      prevState.appState.routeTransition = transition;
      return prevState;
    });
  };

  handleProductFundingChange = (productIndex, changedFundingAmount) => {
    this.setState((prevState) => {
      prevState.products.products[productIndex].fundingAmount =
        changedFundingAmount;
      return prevState;
    });
  };

  resetState = () => {
    let newMasterState = GetEmptyMasterState();
    this.setState(() => {
      newMasterState.products = { ...getEmptyProduct() };
      newMasterState.beneficiaries = { ...getEmptyBeneficiariesState() };
      newMasterState.jointLeadId = 0;
      newMasterState.leadId = 0;
      newMasterState.id = "0";

      return newMasterState;
    });
    this.writeMasterStateToSession();
  };
}

export default withRouter(App);
