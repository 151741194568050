import React from 'react';
import { Row, Col } from 'reactstrap';
import SaveForLaterLink from '../Shared/SaveForLaterLink';


export const LeadText = (props) => {
    return (
        <>
            <Row>
                <Col sm="8" >
                    <p className="mb-0">{props.text}</p>
                </Col>
                {props.ShowSaveForLater && 
                <Col sm="4" className="d-none d-sm-block" >
                    <SaveForLaterLink />
                </Col>
                }
            </Row>
            <Row>
                <Col>
                    <h1 className="header1">
                        {props.subText}
                    </h1>
                </Col>
            </Row>
        </>
    );
};