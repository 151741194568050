import React, { useState, useEffect } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { CompleteProcess } from '../../utilities/FetchUtilites';
import { Footer } from '../Shared/Footer';
import { LeadText } from '../Shared/LeadText';
import { Row, Col, Button } from 'reactstrap';

const Complete = ({ model }) => {

    const [screenSize] = useState(useWindowSize());
    const [showConfetti, setConfetti] = useState(false);
    const [hasATMCardOrdered] = useState(() => model.products.products.some(function (product) { return product.moneyCard === "yes" }))
    const [primaryShareAccountNumber] = useState(() => model.products.products.find(account => account.productClassCode === "100" && account.productType === "SAV").accountNumber)

    const handleSignUpForShineClick = () => {
        // padding the url to change to go directly to new Shine, just in case we push back a couple days.
        // note: the month field is 0 based, so 10 is november
        if (new Date() > new Date(2023, 10, 10)) {
            window.location =
                `https://shine.dupaco.com/signup?rimNo=${model.primary.rimNumber}&OMAFundingType=${model.fundingMethod.method}&primaryShareAccountNumber=${primaryShareAccountNumber}`;
            return;
        }

        window.location =
            `https://shine.dupaco.com/User/AccessSignup/Start?rimNo=${model.primary.rimNumber}&OMAFundingType=${model.fundingMethod.method}&primaryShareAccountNumber=${primaryShareAccountNumber}`;
    }

    useEffect(() => {
        const requestBody = model.leadId;
        //UnrestrictAccounts(requestBody); -- Removed on 8/30/22 by Kevin C. at the request of Kathryn K. in Data Arch.
        CompleteProcess(requestBody);
        setTimeout(() => setConfetti(true), 1150);
    }, [model.leadId]);

    return (
        <div className="transition-group">
            <div className="route-section">
                {showConfetti &&
                    <Confetti
                        width={screenSize.width}
                        height={screenSize.height}
                        numberOfPieces={800}
                        style={{ position: 'fixed' }}
                        wind={0.03}
                        gravity={0.18}
                        recycle={false}
                    />
                }
                <LeadText subText='Congratulations, you’re Dupaco’s newest member-owner!' />
                <Row>
                    <Col xs='12'>
                        <div>
                            <p className='mb-0'>{model.primary.firstName} {model.primary.mi.trim() !== '' && (' ' + model.primary.mi)}{' ' + model.primary.lastName}</p>
                            <p className='mb-0'><strong>Member Number:</strong> {model.primary.rimNumber}</p>
                            <p className='mb-0'><strong>Primary Savings Account Number:</strong> {primaryShareAccountNumber}</p>
                            <p className='mb-0'><strong>Routing Number:</strong> 273974581</p>
                            <p style={{ fontSize: 14 }}><i>Please save these numbers for future reference</i></p>
                        </div>
                    </Col>
                    <Col xs='12'>
                        <p>We know you have choices when it comes to choosing a financial home. Thanks for choosing Dupaco to be yours!</p>
                        <p>Now that you’ve officially joined the cooperative, here’s what you’ll want to do next:</p>
                    </Col>
                </Row>
                {model.leadSourceId === "4113" &&
                    <Row>
                        <Col xs='12'>
                            <h5 class="duorange mt-2">What are the next steps for my Achieve Money Market account?</h5>
                            <p>We'll call you soon to confirm details, discuss paperwork and set up funding for your Achieve Money Market account. Please keep your phone nearby and watch for a call from 800-373-7600.
                            </p>
                        </Col>
                    </Row>
                }
                {hasATMCardOrdered &&
                    <Row>
                        <Col xs='12'>
                            <h5 class="duorange mt-2">When will my MoneyCard arrive?</h5>
                            <p>You can expect your physical MoneyCard in your mailbox in about 7-10 business days. But you can access your card details — card number, CVV, and expiration date — through the Dupaco Mobile app in 1-2 days.
                                <br />
                                <br />
                                Hint: We'll notify you within the app when your card details are available to view.
                            </p>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs='12'>
                        <h5 class="duorange mt-2">How do I sign up for online banking?</h5>
                        <p>Dupaco's free Shine Online & Mobile Banking is a secure way to access and manage your account(s) anytime, anywhere. Download the Shine Mobile Banking app from your Apple or Android device. Or get started from your browser by clicking the link below.</p>

                    </Col>
                </Row>
                <Row>
                    <Col xs='12'>
                        <h5 class="duorange mt-2">What about my membership info and account disclosures?</h5>
                        <p>We'll email you these essential items soon. Please be sure to review and save them for your records.</p>

                    </Col>
                    <Col xs='12' sm={{ size: 6, offset: 3 }} md={{ size: 6, offset: 3 }}>
                        {/* {<p>link text here = {model.fundingMethod.method}</p>} */}
                        <Button className='shine-green-btn btn-secondary btn-block' onClick={handleSignUpForShineClick}>Sign up for online banking</Button>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col xs='12'>
                        <p>Thanks again for choosing Dupaco, {model.primary.firstName}. If you ever have questions about your membership or account(s), please contact us anytime. We’re always happy to help! </p>
                    </Col>
                </Row>
                <Footer />
            </div>
        </div >
    );
}

export default Complete;