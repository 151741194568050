import React from 'react';
import { Row, Col } from 'reactstrap';

const DupacoFooter = (props) => {
    return (
        <>
            <Row id="footer" tag="footer" className="site-footer">
                <Col xs={12} lg="auto">
                    <p className="text-center text-lg-left">
                        <a href="https://www.dupaco.com/about/disclosures/">Disclosures</a>
                        {' '}|{' '}
                        <a href="https://www.dupaco.com/about/disclosures/privacy-policy/">Privacy Policy</a>
                    </p>
                </Col>
                <Col xs={12} lg={{ size: true }}>
                    <div className="pb-0 text-center text-lg-right">
                        <p><a href='tel:800-373-7600'>800-373-7600</a> | 3999 Pennsylvania Ave., Dubuque, IA | Routing Number: 273974581</p>
                    </div>
                </Col>
            </Row>
            <div id="footer-bottom-inner">
                <Row className='d-flex'>
                    <Col xs={12} className='d-flex align-items-center flex-column '>
                        Equal Housing Opportunity | Federally Insured by NCUA <span id="logo-ncua">NCUA Logo</span>
                    </Col>
                    <Col xs={12} className='d-flex align-items-center flex-column'>
                        {'Dupaco Community Credit Union © ' + new Date().getFullYear()}
                        <ul id="social-links">
                            <li id="facebook"><a href="http://www.facebook.com/dupaco" target="_blank" rel="noopener noreferrer">facebook</a></li>
                            <li id="twitter"><a href="https://twitter.com/dupaco" target="_blank" rel="noopener noreferrer">twitter</a></li>
                            <li id="youtube"><a href="https://www.youtube.com/flatdollar" target="_blank" rel="noopener noreferrer">youtube</a></li>
                            <li id="instagram"><a href="http://instagram.com/dupaco" target="_blank" rel="noopener noreferrer">instagram</a></li>
                            <li id="pinterest"><a href="http://pinterest.com/dupaco" target="_blank" rel="noopener noreferrer">pinterest</a></li>
                            <li id="linkedin"><a href="https://www.linkedin.com/company/dupaco-community-credit-union" target="_blank" rel="noopener noreferrer">linkedin</a></li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default DupacoFooter;
