import React, { Component } from 'react';
import { Row, Col, UncontrolledPopover, PopoverBody } from 'reactstrap';
import CardButton from "../../Shared/CardButton";
import FormFieldWrap from '../../Shared/FormFieldWrap';
import classnames from 'classnames';
import NavigationButtons from '../../Shared/NavigationButtons';
import { ChooseValidation } from '../../../StateModels/ChooseState';
import { LeadText } from '../../Shared/LeadText';

export class Choose extends Component {
    static displayName = Choose.name;

    state = {
        selectedOptionError: ''
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>

                <LeadText text="Let's make sure you're eligible for membership" subText="Which of these statements describes you best?" ShowSaveForLater={false} />
                <FormFieldWrap errorMessageId={"eligibilityStatementRequiredId"} isRequired={false} errorMessage={this.state.selectedOptionError}></FormFieldWrap>
                <fieldset disabled={this.props.readOnly}>
                    <FormFieldWrap isRequired={false}>
                        <Row>
                            <Col>
                                <CardButton text="I'm already a Dupaco member." cardCss={classnames({
                                    selected: this.props.model.selectedOption === 'Member',
                                    'border-2': true
                                })} id="member" handleOnClick={this.onClickHandler} cardBodyCss="d-flex justify-content-between" name="selectedOption" value="Member">
                                    <p className="mb-0">I'm already a Dupaco member.
                                    </p>
                                    <p className="mb-0"><i className={classnames({
                                        'mdi': true,
                                        'h4': true,
                                        'mdi-account': this.props.model.selectedOption !== 'Member',
                                        'mdi-account-check': this.props.model.selectedOption === 'Member',
                                        'dugreen': this.props.model.selectedOption === 'Member',
                                    })}></i></p>
                                </CardButton>
                            </Col>
                        </Row>
                    </FormFieldWrap>
                    <FormFieldWrap isRequired={false}>
                        <Row>
                            <Col>
                                <CardButton text="I live and/or work in Dupaco's charter area." cardCss={classnames({
                                    selected: this.props.model.selectedOption === 'Live',
                                    'border-2': true
                                })} id="live" handleOnClick={this.onClickHandler} cardBodyCss="d-flex justify-content-between" name="selectedOption" value="Live">
                                    <p className="mb-0">I live and/or work in Dupaco's charter area.
                                        <i id='livePop' className='mdi mdi-information-outline'><span className='sr-only'>More info</span></i>
                                        <UncontrolledPopover placement='top' target='livePop' trigger='legacy'>
                                            <PopoverBody>
                                                Dupaco's charter area includes every county in Iowa; Columbia, Crawford, Dane, Dodge, Grant, Green, Iowa, Jefferson, Lafayette, Richland, Rock, Sauk, Walworth, and Waukesha counties in Wisconsin; and JoDaviess, Stephenson, Carroll, Whiteside, and Rock Island counties in Illinois.
                                            </PopoverBody>
                                        </UncontrolledPopover></p>
                                    <p className="mb-0"><i className={classnames({
                                        'mdi': true,
                                        'h4': true,
                                        'mdi-account': this.props.model.selectedOption !== 'Live',
                                        'mdi-account-check': this.props.model.selectedOption === 'Live',
                                        'dugreen': this.props.model.selectedOption === 'Live',
                                    })}></i></p>
                                </CardButton>
                            </Col>
                        </Row>
                    </FormFieldWrap>
                    <FormFieldWrap isRequired={false}>
                        <Row>
                            <Col>
                                <CardButton cardCss={classnames({
                                    selected: this.props.model.selectedOption === 'Work',
                                    'border-2': true
                                })} id="work" handleOnClick={this.onClickHandler} cardBodyCss="d-flex justify-content-between" name="selectedOption" value="Work">
                                    <p className="mb-0">I'm affiliated with an employer or association headquartered in Dupaco's charter area.
                                        <i id='workPop' className='mdi mdi-information-outline'><span className='sr-only'>More info</span></i>
                                        <UncontrolledPopover placement='top' target='workPop' trigger='legacy'>
                                            <PopoverBody>
                                                Headquartered refers to your employer's principle place of business. It must be located in Dupaco's charter area, which includes every county in Iowa; Columbia, Crawford, Dane, Dodge, Grant, Green, Iowa, Jefferson, Lafayette, Richland, Rock, Sauk, Walworth, and Waukesha counties in Wisconsin; and JoDaviess, Stephenson, Carroll, Whiteside, and Rock Island counties in Illinois.
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </p>
                                    <p className="mb-0"><i className={classnames({
                                        'mdi': true,
                                        'h4': true,
                                        'mdi-account': this.props.model.selectedOption !== 'Work',
                                        'mdi-account-check': this.props.model.selectedOption === 'Work',
                                        'dugreen': this.props.model.selectedOption === 'Work',
                                    })}></i></p>
                                </CardButton>
                            </Col>
                        </Row>
                    </FormFieldWrap>

                    <FormFieldWrap isRequired={false}>
                        <Row>
                            <Col>
                                <CardButton cardCss={classnames({
                                    selected: this.props.model.selectedOption === 'Relation',
                                    'border-2': true
                                })} id="relation" handleOnClick={this.onClickHandler} cardBodyCss="d-flex justify-content-between" name="selectedOption" value="Relation">
                                    <p className="mb-0">A member of my immediate family is a current Dupaco member.
                                        <i id='relationPop' className='mdi mdi-information-outline'><span className='sr-only'>More info</span></i>
                                        <UncontrolledPopover placement='top' target='relationPop' trigger='legacy'>
                                            <PopoverBody>
                                                Immediate family includes parents, grandparents, children, grandchildren, sisters, brothers, in-laws, foster children, adopted children of current a member and such relatives of a deceased member.
                                            </PopoverBody>
                                        </UncontrolledPopover></p>
                                    <p className="mb-0"><i className={classnames({
                                        'mdi': true,
                                        'h4': true,
                                        'mdi-account': this.props.model.selectedOption !== 'Relation',
                                        'mdi-account-check': this.props.model.selectedOption === 'Relation',
                                        'dugreen': this.props.model.selectedOption === 'Relation',
                                    })}></i></p>
                                </CardButton>
                            </Col>
                        </Row>
                    </FormFieldWrap>
                    <FormFieldWrap isRequired={false}>
                        <Row>
                            <Col>
                                <CardButton cardCss={classnames({
                                    selected: this.props.model.selectedOption === 'NoneApply',
                                    'border-2': true
                                })} id="none" handleOnClick={this.onClickHandler} cardBodyCss="d-flex justify-content-between" name="selectedOption" value="NoneApply">
                                    <p className="mb-0">None of these statements apply to me.</p>
                                    <p className="mb-0"><i className={classnames({
                                        'mdi': true,
                                        'h4': true,
                                        'mdi-account': this.props.model.selectedOption !== 'NoneApply',
                                        'mdi-account-check': this.props.model.selectedOption === 'NoneApply',
                                        'dugreen': this.props.model.selectedOption === 'NoneApply',
                                    })}></i></p>
                                </CardButton>
                            </Col>
                        </Row>
                    </FormFieldWrap>
                </fieldset>
                <FormFieldWrap isRequired={false}>
                    <NavigationButtons onNext={this.onNext.bind(this)} setRouteTransition={this.props.setRouteTransition} showBack={false} ShowSaveForLater={false} />
                </FormFieldWrap>
            </>
        );
    }

    // chooseOption = (e) => {
    //     const option = e.currentTarget.id;
    //     this.setState({
    //         selectedOption: option
    //     });
    // };

    onClickHandler = (e) => {
        this.props.stateHandler(e.target.name, e.target.value);
    }

    onNext = async () => {
        this.resetErrors();

        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        const results = await ChooseValidation(this).validate(this.props.model, {
            recursive: true,
            abortEarly: false
        }).then(() => {
            return true;
        })
            .catch((validationErrors) => {
                window.scrollTo(0, 0);
                return false;
            });
        if (results) {
            return this.props.model.selectedOption;
        }

        return null;
    }

    resetErrors = () => {
        this.setState({
            selectedOptionError: ''
        });
    }
}