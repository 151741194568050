import React, { Component } from 'react';
import { Container} from 'reactstrap';
import WizardBar from './WizardBar';
import DupacoHeader from './DupacoHeader';
import { withRouter } from 'react-router-dom';
import TimerStuff from './TimerStuff';
import AlertMessage from './Alert';
import { SaveForLaterUpsert } from '../../utilities/FetchUtilites';

class Layout extends Component {

  componentDidMount() {
    (this.props.connectivityError && this.props.model.history.includes('/Member/PrimaryAddress')) &&
    SaveForLaterUpsert(this.props.model)
}

  render() {
    const { steps, appState, leadId } = this.props.model;
    const { currentPage } = appState;

    return (
      <>
        <DupacoHeader />
        <Container className="reducedLGContainer">
          {
            (!this.props.location.pathname.substring(1).split("/")[0].match(/SaveForLater|Resume|ForgotAppNumber|TimedOut|Withdrawn|Denied/)) &&
            <WizardBar steps={steps} currentStep={currentPage} />
          }

          <TimerStuff model={this.props.model} resetState={this.props.resetState} push={this.props.push} handleState={this.props.handleState} />

          {(this.props.connectivityError && !this.props.model.history.includes('/Member/PrimaryAddress')) &&
            // You can render any custom fallback UI
            <AlertMessage alertColor=''
              alertHeading='Sorry, we’re having a technical issue.'
              alertMessage={<p>It’s not you, it’s us. We’re having trouble with our application system right now, but we’d love for you to come back after we get it fixed. Please try again in about 24 hours or contact Dupaco for assistance.<br></br><br></br>Sorry again for the inconvenience — we hope to hear from you soon!</p>}
              showButton={false}
            />
          }

          {(this.props.connectivityError && this.props.model.history.includes('/Member/PrimaryAddress')) &&
            <AlertMessage alertColor=''
              alertHeading='Sorry, we’re having a technical issue.'
              alertMessage={<div><p>But we'd love for you to come back after we get it fixed. So we saved all the information you entered and sent a secure link
                 to your in-progress application to the email address you provided. Here's what you need to know:</p>
                <ul style={{ marginLeft: 20 }}>
                  <li><strong>Your Application ID number is {leadId}</strong><br></br>You'll need this number to continue your application. Please save it for future reference.</li>
                  <li><strong>Try continuing your application in 24 hours</strong><br></br>Just click the link we sent you, then enter your Application ID number and identity verification information. Doing so will take you back to where you left off.</li>
                  <li><strong>Your in-progress application expires in 10 days </strong><br></br>We do this for your security. If you can't continue or complete your application before then, the information you've entered will be lost.</li>
                </ul>
                <br></br>
                Sorry again for the inconvenience. We hope you'll complete your application soon so we can welcome you as our newest member-owner!
                 </div>}
              showButton={false}
            />
          }

          {this.props.children}
          {/* <Footer /> */}
        </Container>
      </>
    );
  }
}

export default withRouter(Layout);