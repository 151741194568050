import React, { Component } from 'react';
import { Row, Col, UncontrolledPopover, PopoverBody, Button } from 'reactstrap';
import NavigationButtons from '../../Shared/NavigationButtons';
import { BeneficiariesValidation } from '../../../StateModels/BeneficiariesState';
import { LeadText } from '../../Shared/LeadText';
import { Beneficiary } from './Beneficiary';
import Media from 'react-media';

export class Beneficiaries extends Component {

    state = {
        errors: this.getErrorArray(this.props.model.beneficiaries.length),
        popoverOpen: false
    };


    togglePopOver = () => {
        this.setState(prevState => { return { popoverOpen: !prevState.popoverOpen }; });
    }

    getErrorArray(numOfBeneficiaries) {
        let errorArray = []
        for (let i = 0; i < numOfBeneficiaries; i++) {
            errorArray.push({
                firstNameError: '',
                lastNameError: '',
                dobError: ''
            })
        }

        return errorArray;
    }

    handleRemoveBeneficiary = (indexToRemove) => {
        let beneficiaries = this.props.model.beneficiaries;

        if (this.props.readOnly) {
            return;
        }

        beneficiaries = beneficiaries.filter((bene, filterIndex) => {
            return indexToRemove !== filterIndex;
        });

        // remove error state from object
        this.setState(prevState => {
            prevState.errors = prevState.errors.filter((beneErrors, filterIndex) => {
                return indexToRemove !== filterIndex;
            });
            return prevState;
        })
        this.props.stateHandler('beneficiaries', beneficiaries);
    }

    handleChangeBeneficiary = (e, indexToChange, elementName) => {

        let beneficiaryToChange = this.props.model.beneficiaries[indexToChange];

        beneficiaryToChange[e.target.name] = e.target.value;
        this.props.stateHandler('beneficiaries', this.props.model.beneficiaries);
    }

    componentDidMount() {
        if (!this.props.fromReview) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        const { model } = this.props;

        return (
            <>
                <LeadText text={!this.props.fromReview ? "About you (5 of 5)" : ""} subText="Beneficiaries" ShowSaveForLater={!this.props.fromReview ? true : false} />
                <Row>
                    <Col xs={12} sm={12}>
                        <p><strong>Who do you want as your account beneficiary?</strong> <i onClick={this.togglePopOver} id='infoPop' className='mdi mdi-information-outline'><span className='sr-only'></span></i></p>
                        <p>Add up to six account beneficiaries. There's no age requirement, and you can edit or replace them anytime. If you don't want to name any beneficiaries right now, just click <i>Next</i>.</p>
                    </Col>
                    <UncontrolledPopover trigger={'click hover focus legacy'} toggle={() => this.togglePopOver()} isOpen={this.state.popoverOpen} placement='top' target='infoPop' >
                        <PopoverBody>
                            Designate one or more beneficiaries if you’d like your account(s) to be payable on death. This allows your funds to pass directly to named beneficiaries in the event of your death. Please note: Joint account owners retain ownership of account funds in the event of your death and during their lifetime.
                                </PopoverBody>
                    </UncontrolledPopover>
                </Row>
                <fieldset disabled={this.props.readOnly}>
                    {
                        model.beneficiaries.map((beneficiary, index) => {
                            return <Media key={index} query={{ maxWidth: 575 }}>
                                {matches =>
                                    matches ? (
                                        <div key={index} style={{ paddingRight: '10px', paddingLeft: '10px', marginBottom: '20px', paddingTop: '10px', border: '2px solid #e17509' }}>
                                            <Beneficiary key={index} error={this.state.errors[index]} onRemoveBeneficiary={this.handleRemoveBeneficiary}
                                                onChangeBeneficiary={this.handleChangeBeneficiary} model={beneficiary} index={index} fromReview={this.props.fromReview} />
                                        </div>
                                    ) :
                                        (
                                            <Beneficiary key={index} error={this.state.errors[index]} onRemoveBeneficiary={this.handleRemoveBeneficiary}
                                                onChangeBeneficiary={this.handleChangeBeneficiary} model={beneficiary} index={index} fromReview={this.props.fromReview} />
                                        )
                                }
                            </Media>
                        })
                    }
                </fieldset>
                <Row form>
                    <Col xs={12} sm={4} className={this.props.model.beneficiaries.length < 6 ? '' : 'd-none'}>
                        <button className='btn btn-link dupacoBlue' onClick={this.addBeneficiary}><i className="mdi mdi-plus" style={{ marginLeft: '-15px' }}></i> Add beneficiary</button>
                    </Col>
                </Row>
                <br />
                {!this.props.fromReview &&
                    <NavigationButtons onNext={this.onNext.bind(this)} setRouteTransition={this.props.setRouteTransition} ShowSaveForLater={false} />
                }

                {this.props.fromReview &&
                    <Row>
                        <Col xs={{ size: 12 }} sm={{ size: 4, offset: 8 }}>
                            <Button type='button' setRouteTransition={this.props.setRouteTransition} block className='shine-green-btn' onClick={this.onNext.bind(this)}>Save</Button>
                        </Col>
                    </Row>
                }
            </>
        );
    }

    // This function is handed an event object as it is called because of an event handler (usually onChange)
    // We then pass the name and value of the target being updated to the stateHandler from App.js
    // THIS IS DONE DIFFERENTLY FOR ANY DATE PICKERS
    // Search for the 'dob' date picker on this page to see what I mean
    onChangeHandler = (e) => {
        this.props.stateHandler(e.target.name, e.target.value);
    }

    addBeneficiary = () => {
        let beneficiaries = this.props.model.beneficiaries;

        if (this.props.readOnly) {
            return;
        }
        // if (beneficiaries.length > 5) {
        //     return;
        // }

        beneficiaries.push({
            firstName: '',
            mi: '',
            lastName: '',
            dob: ''
        });
        // add error state to object
        this.setState(prevState => {
            prevState.errors.push({
                firstNameError: '',
                lastNameError: '',
                dobError: ''
            });
            return prevState;
        });
        this.props.stateHandler('beneficiaries', beneficiaries);
    }


    // Don't worry about this for now.
    // If we want inline validation we can add that later
    onBlur = (e) => {
        //const fieldName = e.target.name;
        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        // RelativeSingleValidation(this, fieldName)
        //     .validate(e.target.value)
        //     .then(() => {
        //         this.setState(previousState => {
        //             previousState[fieldName + 'Error'] = '';
        //             return previousState;
        //         })
        //     }).catch((validationErrors) => {

        //     });
    }

    // This function is handed the history api push method from the NavigationButtons component,
    // which is used to switch to another page's specific id.
    // THIS FUNCTION IS REQUIRED IF THE PAGE HAS VALIDATION OR WEIRD ROUTING
    onNext = async () => {
        this.resetErrors();

        // validate, then update the server, then redirect
        // catch the validation at the bottom if an error occurs
        const results = await BeneficiariesValidation(this).validate(this.props.model, {
            recursive: true,
            abortEarly: false
        }).then(() => {
            return true;
        })
            .catch((validationErrors) => {
                window.scrollTo(0, 0);
                return false;
            });

        if (results) {
            if (this.props.fromReview) {
                this.props.toggleReviewModal();
            } else {
                return 'IsJointApplication';
            }
        }
        return null;
    }

    resetErrors = () => {

        let errors = this.state.errors;

        for (let i = 0; i < errors.length; i++) {
            errors[i] = {
                firstNameError: '',
                lastNameError: '',
                dobError: ''
            }
        }

        this.setState({
            errors: errors
        });
    }
}
