import 'react-app-polyfill/ie11';
import 'airbnb-browser-shims/';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './js/App';
//import registerServiceWorker from './js/registerServiceWorker';
import 'core-js';
import './scss/custom.scss';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

// registerServiceWorker();
