import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import '../../Shared/StepTransition.css';
import { Footer } from '../../Shared/Footer';
import Review from './Review';
import PromoCode from './PromoCode';
import AccountAgreements from './AccountAgreements';
import Processing from './Processing';
import NoLeadIdRedirect from '../../../components/Shared/NoLeadIdRedirect';

const AccountSetup = ({ history, match, handleState, model, lockDownFields, 
    statusChange, handlePrimaryAddressIsJointAddressChange, 
    setRouteTransition, location, setConnectivityError}) => {
    return (
        <>
        <NoLeadIdRedirect  />
        <Switch>
            <Route exact path={`${match.path}`}>
                {({ history, match }) => (
                    <Redirect to={`${match.path}/PromoCode`} />
                )}
            </Route>
            <Route exact path={`${match.path}/PromoCode`}>
                {({ history, match }) => (
                    <>
                        <PromoCode model={model.promocode} masterState={model} lockDownFields={lockDownFields} readOnly={model.appState.readonly} setRouteTransition={setRouteTransition}
                            stateHandler={(field, value) => handleState('promocode', field, value)} setConnectivityError = {setConnectivityError}
                        />
                        <Footer />
                    </>
                )}
            </Route>
            <Route exact path={`${match.path}/Review`}>
                {({ history, match }) => (
                    <>
                        <Review model={model} lockDownFields={lockDownFields}
                            handleState={handleState} handlePrimaryAddressIsJointAddressChange={handlePrimaryAddressIsJointAddressChange} setRouteTransition={setRouteTransition} />
                        <Footer />
                    </>
                )}
            </Route>
            <Route exact path={`${match.path}/Processing`}>
                {({ history, match }) => (
                    <>
                        <Processing model={model} lockDownFields={lockDownFields} setRouteTransition={setRouteTransition} 
                            handleState={handleState} handlePrimaryAddressIsJointAddressChange={handlePrimaryAddressIsJointAddressChange} history={history} />
                        <Footer />
                    </>
                )}
            </Route>
            <Route exact path={`${match.path}/AccountAgreements`}>
                {({ history, match }) => (
                    <>
                        <AccountAgreements model={model}
                             agreementsCompleted={model.accountAgreements.accountAgreementsCompleted} 
                             setRouteTransition={setRouteTransition} 
                             accountAgreementModel = {model.accountAgreements}
                             stateHandler={(field, value) => handleState('accountAgreements', field, value)}
                             location={location}
                            />
                        <Footer />
                    </>
                )}
            </Route>
        </Switch>
        </>
    );
}

export default AccountSetup;