import React from 'react';
import { Footer } from '../Shared/Footer';
import { LeadText } from '../Shared/LeadText';


const Pending = ({ model }) => {

    // Removed per Brad (Kevin C. 3/24/20)
    //useEffect(() => SaveForLaterUpsert(model), []);

    return (


        <div>
            <LeadText subText='Your application is pending.' />
            <p>
                We need to do a little review and processing work on our end. Once we finish, we'll email you instructions for completing your application and funding your account(s).  
            </p>
            <p>
               Please keep an eye on your inbox. We'll be in touch within 1-2 business days. 
            </p>
            <p>
                <strong> Your application number is: {model.leadId} </strong><br />
                <i>Please save this for future reference</i>
            </p>
            <p>
                Thanks again for your interest in joining Dupaco — we’re looking forward to welcoming you as a new member-owner!
            </p>
            <Footer />
        </div>
    )
}

export default Pending;
