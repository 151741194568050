import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';
import FormFieldWrap from '../../Shared/FormFieldWrap';
import NavigationButtons from '../../Shared/NavigationButtons';
import { LeadText } from '../../Shared/LeadText';
export class Member extends Component {

    state = {
        shineUserName: ''
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <LeadText text="Let's make sure you're eligible for membership" subText="You're good to go!" ShowSaveForLater={false} />
                <FormFieldWrap isRequired={false}>
                    <Row>
                        <Col xs="12" sm="12">
                            <p>Since you're already a Dupaco member, you don't need to fill out an application. You can easily add accounts and services via Shine Online or Mobile banking. Get started by signing in now! </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <FormFieldWrap>
                                <button id='UsernameField' name='UsernameField' onClick={() => { window.location.href = "https://shine.dupaco.com/User/AccessSignin/Set?promo=ana"; }} className="btn btn-block shine-green-btn">Sign into Shine</button>
                            </FormFieldWrap>
                        </Col>
                    </Row>
                </FormFieldWrap>

                <FormFieldWrap isRequired={false}>
                    <NavigationButtons setRouteTransition={this.props.setRouteTransition} showBack={true} showNext={false} ShowSaveForLater={false} />
                </FormFieldWrap>
            </>
        );
    }

}