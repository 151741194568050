import React, { Component } from 'react';
import { LeadText } from '../Shared/LeadText';
import { Footer } from '../Shared/Footer';
import NavigationButtons from '../Shared/NavigationButtons';

export class Expired extends Component {
    static displayName = 'Save for later';

    render() {

        return (
            <div>
                <>
                    <LeadText text="" subText="Sorry, your Dupaco membership application expired. " ShowSaveForLater={false} />
                    <div>
                        <p>
                            Welcome back! It looks like it's been 30 or more days since you last worked on your application. 
                            So, for your security, we had to delete it.<br/><br/>
                            We'd still love to have you as our newest member-owner. Click below and let's finish what we started!
                        </p>
                    </div>

                    <NavigationButtons ShowSaveForLater={false} showNext={false} showCancel={false} showBack={false} showStartApplication={true} />
                </>
                <Footer />
            </div>
        );
    }

}

export default Expired;