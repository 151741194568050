import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import NavigationButtons from '../../Shared/NavigationButtons';
import { LeadText } from '../../Shared/LeadText';

export class PrimaryIntro extends Component {
    static displayName = "Intro";

    state = {
        
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        return (
            <>
                <LeadText text="Let's get a little personal" subText="Will you please tell us about you?" ShowSaveForLater={false} />
                    <Row>
                    <Col xs={12} sm={12}>
                    Federal law requires all financial institutions to obtain, verify and record information that identifies each person who opens an account. By continuing with this application you authorize Dupaco to verify any information provided. 
                        </Col>
                    </Row>
                    <br />
                    <NavigationButtons onNext={this.onNext.bind(this)} setRouteTransition={this.props.setRouteTransition} ShowSaveForLater={false} />
            </>
        );
    }

    // This function is handed an event object as it is called because of an event handler (usually onChange)
    // We then pass the name and value of the target being updated to the stateHandler from App.js
    // THIS IS DONE DIFFERENTLY FOR ANY DATE PICKERS
    // Search for the 'dob' date picker on this page to see what I mean
    onChangeHandler = (e) => {

        const nameOfField = e.target.name;
        let valueOfField = e.target.value;
        const typeOfField = e.target.type;
        if (typeOfField === 'checkbox') {
            valueOfField = e.target.checked;
        }
        this.props.stateHandler(nameOfField, valueOfField);
    }

    onNext = async () => {
        return 'PrimaryContact';
    }

    resetErrors = () => {
        this.setState({
            
        });
    }

    // Don't worry about this for now.
    // If we want inline validation we can add that later
    onBlur = (e) => {

    }
}