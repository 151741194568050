import React, { Component } from 'react';
import { LeadText } from '../Shared/LeadText';
import { Footer } from '../Shared/Footer';

export class Denied extends Component {
    static displayName = 'Save for later';

    render() {

        return (
            <div>
                <>
                    <LeadText text="" subText="Sorry, your Dupaco membership application was denied. " ShowSaveForLater={false} />
                    <div>
                        <p>
                            Please keep an eye on your inbox. We'll send you an email that explains why we denied it to the email address you gave us.<br /><br />
                            Sorry things didn't work out, {this.props.model.primary.firstName}  -- thanks again for your interest in Dupaco!
                        </p>
                    </div>
                </>
                <Footer />
            </div>
        );
    }

}

export default Denied;